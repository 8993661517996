// 'use client';
import React, { useEffect, useState } from 'react';
import { ChevronDownIcon } from '../../../assets/SitePage/icons';
import { cn } from '../../../_theme/utils';
import { GenericButton } from '../../../components/SitePage/button';
import { useDisclosure } from '../../../hooks/use-disclosure';
import { SectionLayout } from '../../../layouts/section';

export const AboutSection = ({ spot }) => {
  const { isOpen: isReadingMore, toggle } = useDisclosure();

  const [aboutInfo, setAboutInfo] = useState('');
  const [name, setName] = useState('');
  useEffect(() => {
    if(spot.name){
      setName(spot.name);
    }

    if(spot.about) {
      setAboutInfo(spot.about);
    }

  }, [spot]);

  return (
    <SectionLayout
      className="pb-14 space-y-2 md:space-y-3.5 lg:space-y-3.5 px-7 md:px-7"
      relativeId="about"
    >
      <div className="">
        <h2 className="uppercase text-xl md:text-2xl font-bold">
          About {name}
        </h2>
      </div>
      <div className="">
        <p
          className={cn(
            'text-brand-md font-normal mb-3 line-clamp-4 md:line-clamp-3',
            {
              'line-clamp-none md:line-clamp-none': isReadingMore,
            }
          )}
        >
          {isReadingMore && false
            ? aboutInfo
            : aboutInfo}
        </p>
        <div className="max-w-xl">
          <GenericButton
            onClick={toggle}
            variant="secondary"
            className={cn('justify-between gap-8 shadow-brand-300')}
            wide
            small
            rightIcon={
              <ChevronDownIcon
                className={cn('w-4 h-2 stroke-brand-primary transition-all', {
                  'rotate-180': isReadingMore,
                })}
              />
            }
          >
            Read more
          </GenericButton>
        </div>
      </div>
    </SectionLayout>
  );
};
