import React, { useEffect, useState } from "react";
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import md from "./modal.module.scss";
import Switch from '@mui/material/Switch';
import { FaCalendarAlt  } from "react-icons/fa";
import { FaCheck, FaTimes } from 'react-icons/fa';
import axios from "axios";
import { endpoints } from "../../../Api/Api";
import dayjs from "dayjs";
import moment from "moment";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '15px',
  boxShadow: 24,
  border: 'none',
  p: 2,
};

export default function UserModal({ open, handleClose, id, userDetails }) {
  const [checked, setChecked] = React.useState(false);
  const [calenValue, setCalenValue] = React.useState();//moment().format("YYYY-MM-DD")
  const [customerOverview, setCustomerOverview] = React.useState(null);
  const [needToRerender, setNeedToRerender] = React.useState(false) 

  // useEffect(() => {
  //   if(userDetails?.freeaccessend && Number(userDetails?.freeaccessend) > Number(moment().format("YYYYMMDDHHmmss"))){
  //     setChecked(true)
  //     setCalenValue(dayjs(userDetails?.freeaccessend).format("YYYY-MM-DD"))
  //   }else{
  //     setChecked(false)
  //     setCalenValue(moment().format("YYYY-MM-DD"))
  //   }
  // }, [userDetails?.freeaccessend, id])

  const handleChange = (event) => {
    // ?if user having suscription the api not hit
    if(!customerOverview?.subsstatus){
      deleteFreeAccessHandler()
      setChecked(event.target.checked);
    } 
  };
  const [sessionoverview, setSessionOverview] = React.useState([])
  useEffect(() => {
    if(open){
      const token = localStorage.getItem("token");
      const noCache = Date.now();
      axios
        .get(`${endpoints.GETSESSIONOVERVIEW}?nocache=${noCache}&id=${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if(res.data.status === "True"){
            setCustomerOverview(res.data.user)
            setSessionOverview(res.data.data)
            const userDetails = res.data.user
            if(userDetails?.freeaccessend && Number(userDetails?.freeaccessend) > Number(moment().format("YYYYMMDDHHmmss"))){
              setChecked(true)
              setCalenValue(dayjs(userDetails?.freeaccessend).format("YYYY-MM-DD"))
            }else{
              setChecked(false)
              setCalenValue(calenValue ? '': '')//moment().format("YYYY-MM-DD")
            }
          }

        })
        .catch((err) => console.log(err));
    }
  },[open, id, needToRerender])

  function formatDateAndTime(wavedate, wavetime) {
    if(wavedate && wavetime){
      // Split the date and time strings
      const dateParts = wavedate.split("-");
      const timeParts = wavetime.split(":");

      // Format date in desired format (dd/mm/yyyy)
      const formattedDate = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;

      // Format time in desired format (hh:mm)
      const formattedTime = `${timeParts[0]}:${timeParts[1]}`;

      return `${formattedDate} ${formattedTime}`;
    }else{
      return '--'
    }

  }

  function formatDate(date){
    if(date){
      // Split the date and time strings
      const dateParts = date.split("-");
      // Format date in desired format (dd/mm/yyyy)
      const formattedDate = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
      return `${formattedDate}`;
    }else{
      return undefined
    }
  }

  const dateFormatter = (date) => {
    if(date){
      const dateFormat = moment(date).format('DD/MM/YYYY')
      return dateFormat
    }else{
      return null;
    }
  }

  const handleCalender = (e) => {
    const dateInputValue = e.target.value
    setCalenValue(dateInputValue)
    const endDate = dateInputValue.split('-').join('')
    if(checked){
      insertFreeAccessHandler(endDate)
    }
  }

  const insertFreeAccessHandler = (expireyDate) => {
    const token = localStorage.getItem("token");
    const noCache = Date.now();
    axios
      .post(`${endpoints.INSERT_FREE_ACCESS}?nocache=${noCache}&id=${id}`,{enddate:expireyDate}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if(res.data.status === "True"){
          // console.log(res.data.status)
          // need to re-render latest details
          setNeedToRerender(!needToRerender)
        }
        
      })
      .catch((err) => console.log(err));
  }

  const deleteFreeAccessHandler = () => {
    const token = localStorage.getItem("token");
    const noCache = Date.now();
    axios
      .post(`${endpoints.REMOVE_FREE_ACCESS}?nocache=${noCache}&id=${id}`,{}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if(res.data.status === "True"){
          // console.log(res.data.status)
          setNeedToRerender(!needToRerender)
          setCalenValue('')
        }
        
      })
      .catch((err) => console.log(err));
  }



  return (
    <div className={`${md.modalOpenStart}`}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        BackdropComponent={Backdrop}
        BackdropProps={{ onClick: null }}
      >
        <Fade in={open}>
          <Box sx={style} className={`${md.UsermodalOpenStart}`}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              {customerOverview?.email}
              <button onClick={handleClose}>X</button>
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              <div className={`${md.subscriptionSection}`}>
                  <div className={`${md.textName}`}>
                    <span>{customerOverview?.first_name}</span>
                    <span>{customerOverview?.last_name}</span>
                    <span>{customerOverview?.id}</span>
                  </div>

                  <ul>
                    <li>
                      <p></p>
                      <p>
                        <h5>SUBSCRIPTION</h5>
                      </p>
                    </li>
                    <li>
                      <p>STATUS</p>
                      <p>
                        {/* {customerOverview?.subsstatus === "active" ? <span className={`${md.activeStatus}`}>ACTIVE</span>:(customerOverview?.subsstatus === "past_due" ? <span className={`${md.pastStatus}`}>PAST DUE</span>:(customerOverview?.subsstatus === "cancelled" ? <span className={`${md.cancelledStatus}`}>CANCELLED</span>: <span className={`${md.inactiveStatus}`}>INACTIVE</span>))} */}
                        {customerOverview?.subsstatus === true ? <span className={`${md.activeStatus}`}>ACTIVE</span>: <span className={`${md.inactiveStatus}`}>INACTIVE</span>}
                        {/* <span className={`${md.pastStatus}`}>PAST DUE</span> */}
                        {/* <span className={`${md.cancelledStatus}`}>CANCELLED</span> */}
                        {/* <span className={`${md.inactiveStatus}`}>INACTIVE</span> */}
                        {/* <span className={`${md.activeStatus}`}>ACTIVE</span> */}
                      </p>
                    </li>
                    <li>
                      <p>SUB. ID</p>
                      <p>
                        <span>{customerOverview?.subscriptionId || '--'}</span>
                      </p>
                    </li>
                    <li>
                      <p>CREATED AT</p>
                      <p>
                        <span>{customerOverview?.subsstart ? dayjs(customerOverview?.subsstart).format("DD/MM/YYYY") : '--'}</span>
                      </p>
                    </li>
                    <li>
                      <p>RENEWS ON</p>
                      <p>
                        <span>{customerOverview?.subsexpireon ? dayjs(customerOverview?.subsexpireon).format("DD/MM/YYYY") : '--'}</span>
                      </p>
                    </li>
                  </ul>
              </div>

              <div className={`${md.freeAccess}`}>
                <ul>
                  <li>
                    <p></p>
                    <p>
                      <h5>FREE ACCESS</h5>
                    </p>
                  </li>
                  <li>
                    <p>STATUS</p>
                    <p>
                      <Switch
                        checked={customerOverview?.subsstatus == true ? false : checked}
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                        icon={<FaTimes />} // Customize the icon when the switch is unchecked
                        checkedIcon={<FaCheck />} // Show the check icon when the switch is checked
                        classes={{ checked: 'Mui-checked' }} // Use Mui-checked class to style the checked state
                      />
                    </p>
                  </li>
                  <li>
                    <p>CREATED AT</p>
                    <p> 
                      <h5>{checked ? (customerOverview?.freeaccessstart ? (dayjs(customerOverview?.freeaccessstart).add(8, 'days').format("DD-MM-YYYY")):moment().format("DD/MM/YYYY")): moment().format("DD/MM/YYYY") }</h5>
                    </p>
                  </li>
                  <li>
                    <p>EXPIRES ON</p>
                    <p>
                      <input className={`from-control`} type="date" disabled={checked === false} value={calenValue} onChange={handleCalender} min={dayjs().format("YYYY-MM-DD")}/>
                      <FaCalendarAlt className={`${md.calIcon}`}/>
                    </p>
                  </li>
                </ul>
              </div>

              {sessionoverview.length ? 
              <div className={`${md.tableResponsive}`}>
                <h2>SESSIONS OVERVIEW</h2>
                <table>
                  <thead>
                    <tr>
                      <th>CREATED AD</th>
                      <th>WAVE COUNT</th>
                      <th>LOCATION</th>
                      <th>1ST WAVE dt</th>
                    </tr>
                  </thead>

                  <tbody>
                  {sessionoverview.length && sessionoverview.map((session, index) => {
                    return (
                      <tr key={index}>
                        <td>{dateFormatter(session?.createdat) || formatDate(session?.firstwavedate) || '--'}</td>
                        <td>{session?.count}</td>
                        <td>{session?.location}</td>
                        <td>{formatDateAndTime(session?.firstwavedate, session?.firstwavetime)}</td>
                      </tr>
                    )
                  })} 
                    {/* <tr>
                      <td>13/2/2024</td>
                      <td>12</td>
                      <td>TARQUINIO</td>
                      <td>13/2/2024  8:11</td>
                    </tr> */}

                  </tbody>
                </table>
              </div>
              : <>No sessions</>}
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
