import React from "react";
import { UserIcon } from "../../../assets/SitePage/icons";

export const navbarLocations = [
  {
    id: "peniche-bar-do-bruno",
    href: "/beachcam/bar-do-bruno",
    label: "Bar do Bruno - Baleal",
  },
  {
    id: "cascais-carcavelos-tunnel",
    href: "/beachcam/carcavelos-tunnel",
    label: "Tunnel - Carcavelos",
  },
  {
    id: "costa-da-caprica-tarquinio",
    href: "/beachcam/caparica-tarquinio",
    label: "Tarquínio - Caparica",
  },
  {
    id: "costa-da-caprica-nova-praia",
    href: "/beachcam/nova-praia", 
    label: "Nova Praia - Caparica",
  },
  {
    id: "costa-da-caprica-fonte-de-telha",
    href: "/beachcam/caparica-fonte-da-telha",
    label: "Swara - Fonte da Telha",
  },
  {
    id: "aljezur-arrifana",
    href: "/beachcam/aljezur-arrifana",
    label: "Arrifana - Aljezur",
  },
];

export const navbarRoutes = [
  // {
  //   id: "how-it-works",
  //   href: "/?section=howitworks",
  //   label: "How it works",
  // },
  // {
  //   id: "highlight",
  //   href: "/?section=highlight",
  //   label: "Highlights",
  // },
  {
    id: "locations",
    label: "Locations",
    children: navbarLocations,
  },
  // {
  //   id: "price",
  //   href: "/?section=price",
  //   label: "Pricing",
  // },
  {
    id: "profile",
    label: <UserIcon />,
    children: [
      {
        id: "login",
        href: "/?section=login",
        label: "Login",
      },
      {
        id: "register",
        href: "/?section=register",
        label: "Register",
      },
    ],
  },
];
