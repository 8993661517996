import React from "react";
import { FaInstagram, FaTiktok, FaYoutube } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./Footer.styles.scss";
import FooterLogo from '../../assets/livro_reclamacoes-logo.png'

const gitCommit = process.env.REACT_APP_GIT_COMMIT;
const gitBranch = process.env.REACT_APP_GIT_BRANCH;

const Footer = () => {
  return (
    <div className="footer">
      <div className="container footer-container">
        <div className="footer__contents">
          <div className="footer__logo__ft footerSize">
            <a href="https://www.livroreclamacoes.pt/Inicio/" target="_blank"><img src={FooterLogo} alt="" /></a>
          </div>
          <div className="footer__contents__contact footerSize order-1">
            <div className="footer__contents__contact-heading">
              Contact us :
            </div>
            <div className="footer__contents__contact-item">
              <a className="mobileNumber" href="https://wa.me/351934673643" target="_blank">
              +351 934673643
              </a>
            </div>
            <div className="footer__contents__contact-item">
              info@surfeye.video
            </div>
          </div>
          <div className="footer__contents__social footerSize order1">
            <div className="footer__contents__social-heading">Follow us</div>
            <div className="footer__contents__social-links">
              {" "}
              <div className="footer__contents__social-links-link">
                <a
                  href="https://www.instagram.com/surfeye.video/"
                  target="blank"
                >
                  <FaInstagram />
                </a>
              </div>{" "}
              <div className="footer__contents__social-links-link">
                <a
                  href="https://www.youtube.com/channel/UC0O_3FymeiaTthft-7j8qVA"
                  target="blank"
                >
                  <FaYoutube />
                </a>
              </div>
              <div className="footer__contents__social-links-link">
                <a href="https://vm.tiktok.com/ZMFFJEYGw/" target="blank">
                  {" "}
                  <FaTiktok />
                </a>
              </div>
            </div>
          </div>
          <div className="footer__contents__companyinfo footerSize">
            <div className="footer__contents__companyinfo-heading">
              Surf Eye Lda.
            </div>
            <div className="footer__contents__companyinfo-item">
              NIF: 517387573
            </div>
            <div className="footer__contents__companyinfo-item">
              Costa do Castelo 45
            </div>
            <div className="footer__contents__companyinfo-item">
              Lisboa 1100-179 Portugal
            </div>
          </div>
        </div>
      </div>
      <div className="bottomFooter">
        <div className="container">
          <div className="footer-links">
            <div className="footer-links__link">
              <a href="https://www.surfeye.video/termsandconditions" target="_blank" rel="noopener noreferrer">
                Terms and conditions
              </a>
            </div>
            <div className="footer-links__link">
              <a href="https://www.surfeye.video/privacypolicy" target="_blank" rel="noopener noreferrer">
                Privacy Policy
              </a>
            </div>
            <div className="footer-links__link">
              <a href="https://www.iubenda.com/privacy-policy/56494368/cookie-policy" target="_blank" rel="noopener noreferrer">
                Cookie Policy
              </a>
            </div>
            <div className="footer-links__link">ⓒ 2024 Surf Eye</div>
            
          </div>
          { 
            gitCommit ? (
              <>
                <div style={{ display: "none" }}>
                  Git commit: {gitCommit.substring(0, 8)}, branch: {gitBranch}
                </div>
              </>
            ) : null
          } 
        </div>
      </div>
    </div>
  );
};

export default Footer;
