import React,{ useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";


const ProtectedRoute = ({children}) => {
    const getToken = localStorage.getItem("token");
    const getUserInfo = localStorage.getItem("userInfo");
    if (getToken !== null) {
        if (JSON?.parse(getUserInfo)?.role === "superadmin") {
            return children
        } else {
            window.location.href = ("/session")
        }
    }else{
        window.location.href = ("/")

    }
};

export default ProtectedRoute;