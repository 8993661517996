import React, { ReactNode, useContext, useEffect, useState } from "react";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { FaPlay } from "react-icons/fa";
import { useWaveContext } from "../../../context/CartContext";
import PopupButton from "../../PopOver/PopupButton";
import styles from "./Checkout.module.css";
import { useActor, useMachine } from "@xstate/react";
import { CheckCoupons } from "./CheckCoupons";
import { Button } from "@mui/material";
import StripeCheckout from "react-stripe-checkout";
import logostripe from "../../../assets/logostripe.png";
import { display } from "@mui/system";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";

import cardMart from "../../../assets/cardMart.svg";
import faqIcon from "../../../assets/faqIcon.svg";
import unImg from "../../../assets/unImg.png";
import stripeImg from "../../../assets/stripe.png";
import cartIcon from "../../../assets/cartIcon.svg";

import cardM from "../../../assets/cardIconLoan.svg";
import { FAQ } from "../../Modal/faq";
import axios from "axios";

import {loadStripe} from '@stripe/stripe-js';
import { endpoints } from "../../../Api/Api";
import dayjs from "dayjs";
import {PaymentRequestButtonElement, useStripe, useElements, ExpressCheckoutElement} from '@stripe/react-stripe-js';


const VideoModal = ({ video, setVideo }) => {
  return (
    <div className={styles.videomodal} onClick={() => setVideo(false)}>
      <div className={styles.videomodal_video} onClick={(e) => e.stopPropagation()}>
        <video
          src="https://surfeye-web.s3.eu-central-1.amazonaws.com/spots/nova-praia/highlights/session-preview/SESSION+VIDEO+PREVIEW.mp4"
          preload="auto"
          width="100%"
          height="100%"
          muted
          loop
          autoPlay
          controls
        ></video>
      </div>
    </div>
  );
};


interface PayItemProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  name?: string,
  footer?: ReactNode,
  onClick: () => void,
  selected: boolean
}

// "Day Pass"
const PayItem = ({ name, footer, children, onClick, selected, ...rest }: PayItemProps) => {
  const [isHovered, setHovered] = useState(selected);
  useEffect(() => setHovered(selected), [selected]);
  return (
    <div {...rest} className={styles.wrapper}>
      <div className={`${styles.BorderPx} ${isHovered && styles["payItemFooter-select"]}`}>
        <div>
          <div className={`${styles.payItemTop}`}>{children}</div>
          <div className={`${styles.payItemFooter}`}>{footer}</div>
        </div>
        <div className={styles.shim}
          onClick={() => {
            if (onClick != undefined) {
              onClick();
            }
          }}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false || selected)}

        >
        </div>

      </div>
      <div className={`${styles.borderNone}`}>
        {name == "per-day" && selected ? <p >MAKE SURE TO SELECT ALL THE WAVES YOU WANT TO DOWNLOAD BEFORE BUYING. YOU WILL NOT BE ABLE TO SELECT MORE LATER.</p> : <></>}

      </div>
    </div>
  )
}


const PopupData = () => {
  return (
    <div className={styles.checkoutpop}>
      <div className={styles.checkoutpop_text}>
        Surf Eye coaching is a premium service that costs €35 per session (VAT
        included). <br></br> A coaching session allows you to get feedback from
        a professional coach and includes
      </div>
      <ul className={styles.checkoutpop_list}>
        <li className="checkoutpop-list-item">
          {" "}
          <li className="checkoutpop-list-item">
            30 minutes video call with professional coach
          </li>
          <li className="checkoutpop-list-item">
            Wave by wave feedback and corrections
          </li>
          <li className="checkoutpop-list-item">
            Tips both for inland and at sea exercises to improve your surfing
          </li>
        </li>
      </ul>
      <div className={styles.checkoutpop_text}>
        After payment is completed, the videos of your session are automatically
        shared with <br></br> one of our coaches, who will contact you within 2
        business days to schedule a video call.
      </div>
    </div>
  );
};

const QuestionMark = () => {
  return <div className={styles["checkout-popup-question"]}>?</div>;
};


const AllPlans = {
  wavePass: "wave",
  dayPass: "day",
  monthPass:  "month"
}


const AvailableDatesFormat = () => {
  const dates: any = []
  const eodToday = dayjs.utc();
  dates.push({
    startTime: dayjs(eodToday).format("YYYYMMDD"),
  })
  for (let i = 1; i < 8; i++) {
    const tmDate = eodToday.subtract(i, 'day');
    dates.push({
      startTime: tmDate.format("YYYYMMDD"),
    })
  }
  return dates
}


export const Checkout2 = ({...props}) => {
  // const location = useLocation();
  // const [current, send] = useMachine(watchAndBuySM);
  const [video, setVideo] = useState(false);
  const { waveService } = useWaveContext();
  const [current] = useActor(waveService);
  const navigate = useNavigate();
  const [faqHandler, setFaqHandler] = useState(false)

  const [planBuy, setPlan] = useState(AllPlans.wavePass)
  const [isLoadingCheckout, setIsLoadingCheckout] = useState(false);


  useEffect(() => {
    waveService.send({
      type: 'reset',
    })
    const wavesFromLocal = JSON.parse(localStorage.getItem("waves"))
    for (let key in wavesFromLocal) {
        const wave = wavesFromLocal[key];
        waveService.send({
          type: 'updateWaves',
          data: wave
        })
    }
    const packageSelected = localStorage.getItem('packageSelected')
    setTimeout(() => {
      if(packageSelected === 'month'){
        waveService.send({
          type: 'selectProduct',
          product: current.context.products[2]
        })
        setPlan(AllPlans.monthPass)
        localStorage.removeItem('packageSelected')
      }
    }, 1)
  }, [])

  useEffect(() => {
    if (current.matches('end')) {
      // Swal.fire(current.context.message, "", "success");
      // set hint for popup
      if(planBuy === "day"){
        const waveDate = current?.context?.cart?.waves[0].createdAt
        const waveDayFormat =  dayjs(waveDate).format("YYYYMMDD")
        const availDates = AvailableDatesFormat()
        const lastAvaildayForWave = availDates[7]?.startTime
        if(waveDayFormat === lastAvaildayForWave){
          localStorage.setItem("passType", "lastday")
        }else{
          localStorage.setItem("passType", "day")
        }
        
      }else{
        // Swal.fire(current.context.message, "", "success")
      }
      // when waves successfully buyed then reset cart as well as localstorage waves data
      waveService.send({
        type: 'reset',
      })
      setIsLoadingCheckout(false);
      localStorage.removeItem("waves")
      localStorage.removeItem("location")
      localStorage.removeItem("date")
      navigate("/mysession");
    }
  }, [current]);

  // useEffect(() => {
  //   if (current.matches('waves')) {
  //     navigate("/session");
  //   }
  // }, []);

  // const history = useHistory();
  // const { waves, updateWaves, coaching, setCoaching } = useCart();
  
  const checkHandlerBeforeCheckout = () => {
    const data = current.context.cart.waves
    // const data = localStorage.getItem("waves")
    setIsLoadingCheckout(true);
    if(!data || data.length <= 0){
      setIsLoadingCheckout(false);
      return
    }
    const customertoken = localStorage.getItem("token");
      axios
        .post(
          endpoints.CREATE_SESION_FOR_ACTIVE_SUBSCRIPTION,
          {
            data: data,
          },
          {
            headers: {
              Authorization: `Bearer ${customertoken}`,
            },
          }
        )
        .then((response) => {
          if (response.data.status === "True") {
            setIsLoadingCheckout(false);
            // Swal.fire(response.data.message, "", "success");
            waveService.send({
              type: 'reset'
            });
            // pay with wallet for subscription
            localStorage.setItem("passType", "month")
            var date = new Date(); // Now
            const ddd = date.setDate(date.getDate() + 30); // Set now + 30 days as the new date
            const cnvtD = dayjs(ddd).format("YYYYMMDD")
            localStorage.setItem("subsDate", cnvtD)

            localStorage.removeItem("date")
            localStorage.removeItem("waves")
            localStorage.removeItem("location")
            
            // Swal.fire(response.data.message, "", "success");
            navigate("/mysession")
          } else {
            setIsLoadingCheckout(false);
            // Swal.fire(response.data.message, "", "error");
          }
        })
        .catch((error) => {
          setIsLoadingCheckout(false);
          console.log(error)
        });
  }
  
  useEffect(() => {
    checkHandlerBeforeCheckout()
  }, [])

  const handleCreateSesssion = () => {
    const data = current.context.cart.waves
    setIsLoadingCheckout(true);
    if(data.length <= 0){
      setIsLoadingCheckout(false);
      return
    }
    const customertoken = localStorage.getItem("token");
      axios
        .post(
          endpoints.CREATE_SESION_FOR_ACTIVE_SUBSCRIPTION,
          {
            data: data,
          },
          {
            headers: {
              Authorization: `Bearer ${customertoken}`,
            },
          }
        )
        .then((response) => {
          if (response.data.status === "True") {
            setIsLoadingCheckout(false);
            // Swal.fire(response.data.message, "", "success");
            waveService.send({
              type: 'reset'
            });
            if(planBuy === "month"){
              localStorage.setItem("passType", "month")
              var date = new Date(); // Now
              const ddd = date.setDate(date.getDate() + 30); // Set now + 30 days as the new date
              const cnvtD = dayjs(ddd).format("YYYYMMDD")
              localStorage.setItem("subsDate", cnvtD)
            }
            localStorage.removeItem("date")
            localStorage.removeItem("waves")
            localStorage.removeItem("location")
            
            // Swal.fire(response.data.message, "", "success");
            navigate("/mysession")
          } else {
            setIsLoadingCheckout(false);
            Swal.fire(response.data.message, "", "error");
          }
        })
        .catch((error) => {
          setIsLoadingCheckout(false);
          console.log(error)
        });
}

  const handleForPayout = (token:any) => {
    setIsLoadingCheckout(true);
    const plan = current.context?.selectedProduct
    const customertoken = localStorage.getItem("token");
    if(planBuy === "month"){
        axios.post(endpoints.SUBSCRIPTION_CREATE,{
          // payid: token.id,
          // day: `${day}-${moment.utc(new Date()).format("YYYYMMDDHHmmss")}-${moment.utc(new Date(new Date().getTime() + 60 * 60 * 24 * 1000)).format("YYYYMMDDHHmmss")}`,
          plan: planBuy,
        },
        {
          headers: {
            Authorization: `Bearer ${customertoken}`,
          },
        }
      ).then((response) => {
        if(response.data.status == "True"){
          const afPayment = async () => {
            const stripe = await loadStripe(process.env.REACT_APP_STRIPE_CLIENT_ID);
            stripe?.confirmCardPayment(response.data.clientSecret, {
              payment_method: {
                card: {
                  token: token.id,
                },
              },
            })
            .then(function(result) {
              // Handle result.error or result.paymentIntent
              if(result.error){
                Swal.fire("Payment Unsuccessfull", "", "error");
              }
              else{
                // api call for save subscription id {UPDATESUBSCRIPTION}
                axios.post(endpoints.INSERT_SUBSCRIPTION,{
                  subsId:response.data.sub,
                  wave: current?.context?.cart?.waves[0]
                },
                {
                  headers: {
                    Authorization: `Bearer ${customertoken}`,
                  },
                }
              ).then((response) => {
                handleCreateSesssion()
              }).catch((err) => {
                setIsLoadingCheckout(false);
                console.log(err, "payment not updated")
              })
              }
            }).catch((err) => {
              setIsLoadingCheckout(false);
              console.log(err, "payment error")
            })
          }
          afPayment()
          // navigate.push("/my-ride")
        } else{
          setIsLoadingCheckout(false);
          Swal.fire(response.data.message, "", "error");
        }
      });
    }else{
      // create charge by previous flow (using context api)
      waveService.send({
        type: 'buy',
        payId: token.id
      })
    }
  }

  const handleClick = (bool: boolean) => {
    setFaqHandler(bool)
  }

  const handleForNoCostSession = () => {
    setIsLoadingCheckout(true);
    waveService.send({
      type: 'buy',
      payId: "NOCOST"
    })
    // const data = current.context.cart.waves
    // setIsLoadingCheckout(true);
    // if(data.length <= 0){
    //   setIsLoadingCheckout(false);
    //   return
    // }
    // const customertoken = localStorage.getItem("token");
    //   axios
    //     .post(
    //       endpoints.NOCOST_CREATE_SESSION,
    //       {
    //         data: data,
    //       },
    //       {
    //         headers: {
    //           Authorization: `Bearer ${customertoken}`,
    //         },
    //       }
    //     )
    //     .then((response) => {
    //       if (response.data.status === "True") {
    //         setIsLoadingCheckout(false);
    //         // Swal.fire(response.data.message, "", "success");
    //         waveService.send({
    //           type: 'reset'
    //         });
    //         localStorage.removeItem("date")
    //         localStorage.removeItem("waves")
    //         localStorage.removeItem("location")
            
    //         // Swal.fire(response.data.message, "", "success");
    //         navigate("/mysession")
    //       } else {
    //         setIsLoadingCheckout(false);
    //         Swal.fire(response.data.message, "", "error");
    //       }
    //     })
    //     .catch((error) => {
    //       setIsLoadingCheckout(false);
    //       console.log(error)
    //     });
  }

  const stripe = useStripe();
  const elements:any = useElements();
  const onConfirm = async (event:any) => {
    const customertoken = localStorage.getItem("token");
    const dateLC = localStorage.getItem("date");
    const plan = planBuy

    if (!stripe) {
      // Stripe.js hasn't loaded yet.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const {error: backendError, clientSecret} = await fetch(
      `${endpoints.PAYMENT_INTENT}`,
      {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer '+customertoken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          // paymentMethodType: 'card',
          currency: 'eur',
          amount: `${current.context?.totalPrice * 100}`
        }),
      }
    ).then((r) => r.json());

    // Confirm the PaymentIntent using the details collected by the Express Checkout Element
    const res = await stripe.confirmPayment({
      // `elements` instance used to create the Express Checkout Element
      elements,
      // `clientSecret` from the created PaymentIntent
      clientSecret,
      confirmParams: {
        // editable
        return_url: `http://localhost:3000/mysession`,
      },
      redirect: 'if_required',
    });
    if (res.error) {
      console.log("error", res.error)
      // This point is only reached if there's an immediate error when
      // confirming the payment. Show the error to your customer (for example, payment details incomplete)
      // setErrorMessage(error.message);
    } else {
      // create session logic
      setIsLoadingCheckout(true);
      waveService.send({
        type: 'buy',
        payId: "BYWALLET"
      })
    }
  };

  const onClick = ({resolve}:any) => {
    const options = {
      emailRequired: true,
      wallet:'always'
    };
    resolve(options);
  };

  useEffect(()=> {
    props.handleForPriceAmount(Number(current.context?.totalPrice?.toFixed(2)) * 100 || 9.99*100)
  }, [current.context.totalPrice])

  const handleForCreateSubscription = () => {
    const customertoken = localStorage.getItem("token");
    if(planBuy === "month"){
      axios.post(endpoints.LINK_FOR_PAY,{},
      {
        headers: {
          Authorization: `Bearer ${customertoken}`,
        },
      }
    ).then((response) => {
      if(response.data.status == "True"){
        const payLink = response.data.url
        window.location.href = payLink
      }
    })
    .catch((err)=> {
      console.log(err.message)
    })
  }
}

  return (
    <>
      <div className={styles.checkout}>
        {video && (
          <VideoModal
            video={
              "https://surfeye-web.s3.eu-central-1.amazonaws.com/src/landing-page/LANDING+PAGE+-+SECTION+2.mp4"
            }
            setVideo={setVideo}
          />
        )}
        <div className={styles.topCheckout}>
          <div
            onClick={() => navigate(-1)}
            className={`${styles.checkout__heading_back} pointer`}
          >
            <MdOutlineArrowBackIosNew />
            <div className="" >Back</div>
          </div>
          <div className={styles.checkout__heading}>CHECKOUT</div>
          <div className={styles.faqsButton}>
            <button onClick={() => setFaqHandler(true)}>
              FAQ
              <img src={faqIcon} alt="" />
            </button>
          </div>
        </div>
        
        {/* <div className={styles.heightFix}>
        <div className={styles.productsContainer} >
          {current.context.products.map(p =>
            <PayItem
              name={p?.name}
              selected={p.name == current.context.selectedProduct?.name}
              style={{
                flex: '1 1 10',
                maxWidth: '300px'
              }}
              onClick={() => {
                waveService.send({
                  type: 'selectProduct',
                  product: p
                })
              }}
              footer={
                <div className={styles.payItemTopContentLine}>€{p.totalWavePrice?.toFixed(2)}</div>
              }><div className={styles.payItemTopContent}>
                <div className={styles.payItemTopContentLine}>{p.line1}</div>
                <div className={`${styles.payItemTopContentLine} ${styles.payItemTopContentLine2}`}>
                  {p.promotionalPricePerItem != p.pricePerItem && <div className={styles.strikethrough} style={{ paddingRight: '0.3rem' }}>€{p.pricePerItem}</div>}
                  €{p.promotionalPricePerItem} {p.line2}</div>
                <div className={`${styles.payItemTopContentLine} ${styles.payItemTopContentLine2}`}>
                  {p.line3}</div>
              </div>
            </PayItem>
          )}
        </div>
        
        <div className={styles.bottomButton}>
            <div className={styles.wavesSeclect}>
              <button className={styles.btn} onClick={() => navigate("/cart")}>
                <img src={cardMart} alt="" />
                <span>{current.context.cart.waves.length}</span>
                WAVES <b> SELECTED</b>
              </button>
            </div>
            <div className={`${styles.rightSideSize}`}>
              <div className={`${styles.masterCard}`}>
                <img src={cardM} alt="" />
              </div>
              {current.context.selectedProduct && <div className={`${styles.halfCentered} ${styles.disconudLeft}`}><CheckCoupons className={styles.halfBox} /></div>
              }
              <div className={`${styles.halfCentered}`}>
                <div
                  className={`${styles.payBox} ${styles.halfBox}`}
                >
                  <div className={styles.amount}>€{current.context.totalPrice.toFixed(2)}</div>
                  <div style={{ justifyContent: "flex-end" }}>
                    {current.context.totalPrice < 0.01 ?
                      <div className={styles.payButton} onClick={() => {
                        waveService.send({
                          type: 'buy'
                        })
                      }}>BUY NOW</div>
                      :

                  <div className={styles.payButtonAdd}>
                    <button>BUY NOW</button>
                    <div className={styles.payButton}>
                      <StripeCheckout
                        label="BUY NOW"
                        name="Surf Eye Purchase"
                        email={JSON.parse(localStorage.getItem("userInfo")).email}
                        panelLabel="Pay"
                        ComponentClass="div"
                        image={logostripe}
                        amount={current.context.totalPrice * 100}
                        currency="EUR"
                        token={(token) => {
                          waveService.send({
                            type: 'buy',
                            payId: token.id
                          })
                        }}
                        stripeKey={process.env.REACT_APP_STRIPE_CLIENT_ID}

                          />
                        </div>
                      </div>
                    }
                    <img src="../../assets/dabitCardImage.png" alt="" />
                  </div>
                </div>
              </div>
              {current.context.message && <div className={`${styles.halfCentered}`} style={{ color: 'red' }}>{current.context.message}</div>}
            </div>
          </div>
        </div> */}

        <div className={styles.checkOutCenter}>
          <div className={styles.checkoutCard}>
            <div className={styles.checkoutRow}>

              <div className={`${styles.checkoutBox} ${styles.col3}`}
                onClick={() => {
                  waveService.send({
                    type: 'selectProduct',
                    product: current.context.products[0]
                  })
                  setPlan(AllPlans.wavePass)
                }}
              >
                <button className={`${styles.checkoutBoxBtn} ${current?.context?.selectedProduct?.name === "per-wave" ? styles.active : ""}`}>
                  <div className={styles.checkoutBoxBody}>
                     <h3>{current?.context?.cart?.waves?.length} WAVE</h3>
                     <p>€4.99 PER WAVE</p>
                  </div>
                  <h4>€{current.context.products[0]?.totalWavePrice?.toFixed(2)}</h4>
                </button>
              </div>

              <div className={`${styles.checkoutBox} ${styles.col6}`}
              onClick={() => {
                waveService.send({
                  type: 'selectProduct',
                  product: current.context.products[2]
                })
                setPlan(AllPlans.monthPass)
              }}
              >
                <button className={`${styles.checkoutBoxBtn} ${styles.centerCard}`}>
                  <div className={styles.checkoutBoxBody}>
                    <img src={unImg} className={styles.backgroundCard} />
                    <div className={styles.checkoutBoxBodyInSide}>
                      <h3>SURF EYE <span>UNLIMITED</span></h3>
                      <p>30 DAYS OF UNLIMITED WAVES</p>
                    </div>
                  </div>
                  <h4>€19 <span>/ month</span></h4>
                </button>
              </div>

              <div className={`${styles.checkoutBox} ${styles.col3}`}
              onClick={() => {
                waveService.send({
                  type: 'selectProduct',
                  product: current.context.products[1]
                })
                setPlan(AllPlans.dayPass)
              }}
              >
                <button className={`${styles.checkoutBoxBtn} ${current?.context?.selectedProduct?.name === "per-day" ? styles.active : ""}`} >
                  <div className={styles.checkoutBoxBody}>
                    <h3>DAY PASS</h3>
                    <p>1 DAY OF UNLIMITED WAVES</p>
                  </div>
                  <h4>9.99</h4>
                </button>
                {/* <p className={styles.paragraph}>SELECT ALL THE WAVES YOU WANT TO DOWNLOAD BEFORE BUYING. YOU WON’T BE ABLE TO SELECT MORE LATER</p> */}
              </div>

          </div>
        </div>
        {/* Desktop */}
        <div className={`${styles.bottomAction} ${styles.desktopBottomBar}`} >
          <div className={`${styles.bottomActionRow}`} >
            <div className={`${styles.boxLeft} ${styles.colN6}`} >
                <button className={`${styles.btnNewD}`} onClick={() => navigate('/cart')}>
                 <img src={cartIcon} />
                 <strong>{current?.context?.cart?.waves?.length}</strong> WAVES <span>SELECTED</span>
                </button>
            </div>
            
            <div className={`${styles.boxRight} ${styles.colN6}`} >
              <div className={`${styles.imgBox} ${styles.mobileImage}`}>
                  <img src={stripeImg} />
              </div>
              <div className={`${styles.discountCard}`} >
                  <div className={`${styles.mobileSideLeft}`}>
                    {/* <p className={`${styles.alertBox}`}>20% DISCOUNT APPLIED</p>
                    <input type="text" className={`${styles.formControl}`} placeholder="DISCOUNT CODE" /> */}

                      <CheckCoupons />
                    </div>
                    <div className={`${styles.buyNowAction}`}>
                        <h5>€{current?.context?.totalPrice.toFixed(2)}</h5>
                        {current?.context?.totalPrice <= 0 ? 
                        <>
                          <button onClick = {handleForNoCostSession}>
                          
                          {isLoadingCheckout ? (
                            <RotatingLines
                              strokeColor="black"
                              strokeWidth="5"
                              animationDuration="0.75"
                              width="60"
                              visible={true}
                            />
                          ) : (
                            <>
                              <>BUY NOW</>
                              <svg className={`${styles.arrowIcon}`} xmlns="http://www.w3.org/2000/svg" width="55.685" height="58.961" viewBox="0 0 55.685 58.961">
                              <path id="playIcon" d="M.326,6.478V53.817c0,5.289,9.962,7.806,15.938,4.029L53.322,33.925c3.586-2.266,3.586-6.044,0-8.309L15.866,2.449C10.288-1.328.326,1.442.326,6.478Z" transform="translate(-0.326 -0.694)" fill="#2E3848"/>
                            </svg>
                            </>
                          )}                  
                          
                          </button>
                        </>: 
                        <>
                          <button>
                          
                          {isLoadingCheckout ? (
                            <RotatingLines
                              strokeColor="black"
                              strokeWidth="5"
                              animationDuration="0.75"
                              width="60"
                              visible={true}
                            />
                          ) : (
                            <>
                              <>BUY NOW</>
                              <svg className={`${styles.arrowIcon}`} xmlns="http://www.w3.org/2000/svg" width="55.685" height="58.961" viewBox="0 0 55.685 58.961">
                              <path id="playIcon" d="M.326,6.478V53.817c0,5.289,9.962,7.806,15.938,4.029L53.322,33.925c3.586-2.266,3.586-6.044,0-8.309L15.866,2.449C10.288-1.328.326,1.442.326,6.478Z" transform="translate(-0.326 -0.694)" fill="#2E3848"/>
                            </svg>
                            </>
                          )}                  
                          <StripeCheckout
                          label="BUY NOW"
                          name="Surf Eye Purchase"
                          email={JSON.parse(localStorage.getItem("userInfo")).email}
                          panelLabel="Pay"
                          ComponentClass="div"
                          image={logostripe}
                          amount={current.context?.totalPrice * 100}
                          currency="EUR"
                          token={(token) => {
                            // waveService.send({
                            //   type: 'buy',
                            //   payId: token.id
                            // })
                            handleForPayout(token)
                          }}
                          stripeKey={process.env.REACT_APP_STRIPE_CLIENT_ID}
                          />
                          </button>
                        </>}
                        {/* <button>
                          
                        {isLoadingCheckout ? (
                          <RotatingLines
                            strokeColor="black"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="60"
                            visible={true}
                          />
                        ) : (
                          <>
                            <>BUY NOW</>
                            <svg className={`${styles.arrowIcon}`} xmlns="http://www.w3.org/2000/svg" width="55.685" height="58.961" viewBox="0 0 55.685 58.961">
                            <path id="playIcon" d="M.326,6.478V53.817c0,5.289,9.962,7.806,15.938,4.029L53.322,33.925c3.586-2.266,3.586-6.044,0-8.309L15.866,2.449C10.288-1.328.326,1.442.326,6.478Z" transform="translate(-0.326 -0.694)" fill="#2E3848"/>
                          </svg>
                          </>
                        )}                  
                        <StripeCheckout
                        label="BUY NOW"
                        name="Surf Eye Purchase"
                        email={JSON.parse(localStorage.getItem("userInfo")).email}
                        panelLabel="Pay"
                        ComponentClass="div"
                        image={logostripe}
                        amount={current.context?.totalPrice * 100}
                        currency="EUR"
                        token={(token) => {
                          // waveService.send({
                          //   type: 'buy',
                          //   payId: token.id
                          // })
                          handleForPayout(token)
                        }}
                        stripeKey={process.env.REACT_APP_STRIPE_CLIENT_ID}
                        />
                        </button> */}
                    </div>  
                </div>
                {current?.context?.totalPrice <= 0 ? <></> :(
                  //* Following code for wallet payment subscription
                <div className="justify-content-between gap-3 mt-3 flex-wrap">{/* d-flex */}
                  {planBuy != "month" ? <ExpressCheckoutElement onConfirm={onConfirm} onClick={onClick}
                  />: <></>}
                  {/* {planBuy == "month" ? <button className={`${styles.payWalletBtn}`} onClick={handleForCreateSubscription}
                  >Pay With Wallet</button>: <></>}  */}
                </div>) }
                <div className={`${styles.imgBox} ${styles.desktopImage}`}>
                    <img src={stripeImg} />
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>


      {faqHandler ? <FAQ handleClick={handleClick} /> : <></>}
    </>

  );
}
