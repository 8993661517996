import React,{ useState, useEffect } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import Form from 'react-bootstrap/Form';
import md from "./modal.module.scss";
import Select, { components } from 'react-select';
import { IoMdCheckmark } from "react-icons/io";
import { FaRegCheckSquare } from "react-icons/fa";
import { IoIosSquareOutline } from "react-icons/io";





const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '15px',
  boxShadow: 24,
  height: "auto",
  border: 'none',
  p: 4,
};

const defaultFormData = {
  title: "",
  discount: "",
  expiresAt: "",
  isFlat: false,
  isSingle: false,
  isMultipleUser: true,
  applicableto: ''
}

export default function CouponModal({ open, handleClose, handleAddCoupon }) {
  const [form, setForm] = useState({
    title: "",
    discount: "",
    expiresAt: "",
    isFlat: false,
    isSingle: false,
    isMultipleUser: true,
    applicableto: ''
  });

  useEffect(() => {
    if(open){
      setForm(defaultFormData)
      setSelectedOptions([])
    }

  },[open])

  const handleChangeInput = (e) =>
    setForm((prev) => ({ ...form, [e.target.name]: e.target.value }));
  
  const options = [
    { value: 'wave', label: 'WAVE' },
    { value: 'day', label: 'DAY' },
    { value: 'month', label: 'MONTH' },
  ];

  const MenuList = (props) => {
    const { selectOption } = props;
    return (
      <components.MenuList {...props}>
        {selectedOptions.map((option) => (
          <div
            key={option.value}
            onClick={() => selectOption(option)}
            style={{ cursor: 'pointer', padding: '5px', display: 'flex', alignItems: 'center' }}
          >
            <FaRegCheckSquare style={{ marginRight: '5px' }} />
            <span>{option.label}</span>
          </div>
        ))}
        <components.MenuList {...props}>
          {props?.children?.filter?.(
            (child) => !selectedOptions?.find((option) => option?.value === child?.props?.data?.value)
          )}
        </components.MenuList>
      </components.MenuList>
    );
  };

  const CheckboxOption = ({ children, isSelected, ...props }) => (
    <components.Option {...props}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <IoIosSquareOutline icon={isSelected ? <IoIosSquareOutline /> : ''} style={{ marginRight: '5px' }} />
        <span>{children}</span>
      </div>
    </components.Option>
  );

  const [selectedOptions, setSelectedOptions] = React.useState([]);


  const handleChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);

    form.applicableto = selectedOptions.map((arrVal, idx) => arrVal.value).join(',')
    setMenuIsOpen(true);
  };

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  return (
    <div className={`${md.modalOpenStart}`}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ onClick: null }}
      >
        <Fade in={open}>
          <Box sx={style} className={`${md.modalOpenStart}`}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              CREATE CODE
              <button onClick={handleClose}>X</button>
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              <div className={`${md.formStart}`}>
                <Form>
                  <Form.Group className={`${md.formInput}`}>
                    <Form.Control type="text" placeholder="Code Name" name="title" onChange={handleChangeInput}
                    value={form.title}/>
                  </Form.Group>
                  
                  <Form.Group className={`${md.formInput}`}>
                    <Form.Control type="number" placeholder="Discount" onChange={handleChangeInput}
                    value={form.discount} name="discount" />
                    <div className={`${md.inputRight}`}>
                      <span className={`${!form.isFlat ? md.active : ''}`} onClick={(e) => {
                        setForm({ ...form, isFlat: false });
                      }}>%</span>
                      <span className={`${form.isFlat ? md.active : ''}`} onClick={(e) => {
                       setForm({ ...form, isFlat: true });
                       }}>€</span>
                    </div>
                  </Form.Group>

                  <Form.Group className={`${md.formInput}`}>
                  <Select
                    className={`${md.select}`}
                    isMulti
                    options={options}
                    onChange={handleChange}
                    placeholder="Applicable to"
                    value={selectedOptions}
                    components={{ Option: CheckboxOption, MenuList }}
                    menuIsOpen={menuIsOpen} // Set menuIsOpen state here
                    onMenuOpen={() => setMenuIsOpen(true)} // Open menu on arrow click
                    onMenuClose={() => setMenuIsOpen(false)} // Close menu when clicking away
                  />
                  </Form.Group>

                  <Form.Group className={`${md.formInput}`}>
                    <Form.Control type="date" 
                    placeholder="Expiration Date"
                    name="expiresAt"
                    onChange={handleChangeInput}
                    value={form.expiresAt} />
                  </Form.Group>

                  <Form.Group className={`${md.formInput} ${md.placeHolderColor}`}>
                    <Form.Control type="text" placeholder="Redeemable Multiple times" disabled
                    // value={!form.isSingle}
                    />
                    <div className={`${md.inputRight}`}>
                      <span className={`${!form.isSingle ? md.active: ''}`} onClick={(e) => {
                        setForm({ ...form, isSingle: false });
                      }}>YES</span>
                      <span className={`${form.isSingle ? md.active: ''}`} onClick={(e) => {
                        setForm({ ...form, isSingle: true });
                      }}>NO</span>
                    </div>
                  </Form.Group>

                  <Form.Group className={`${md.formInput} ${md.placeHolderColor}`}>
                    <Form.Control type="text" placeholder="By Multiple Users" disabled 
                    // value={form.isMultipleUser}
                    />
                    <div className={`${md.inputRight}`}>
                      <span className={`${form.isMultipleUser ? md.active : ''}`} onClick={(e) => {
                        setForm({ ...form, isMultipleUser: true });
                      }}>YES</span>
                      <span className={`${!form.isMultipleUser ? md.active : ''}`} onClick={(e) => {
                        setForm({ ...form, isMultipleUser: false });
                      }}>NO</span>
                    </div>
                  </Form.Group>

                  <Form.Group className={`${md.formInputButton}`}>
                    <button onClick={(e) => {
                      e.preventDefault()
                      handleClose()
                      handleAddCoupon(
                        form.title,
                        form.discount,
                        form.expiresAt,
                        form.isFlat,
                        form.isMultipleUser,
                        form.isSingle,
                        form.applicableto
                      )
                      setForm(defaultFormData)
                    }
                    }>CREATE CODE</button>
                  </Form.Group>
                </Form>
              </div>
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
