// 'use client';
import React, { useEffect, useState } from 'react';
// import { LazyLoadImage } from 'react-lazy-load-image-component'; //import Image from 'next/image';
import { SectionLayout } from '../../../layouts/section';
import { GenericButton } from '../../../components/SitePage/button';
import { ChevronDownIcon, GoogleMapIcon } from '../../../assets/SitePage/icons';
import { useDisclosure } from '../../../hooks/use-disclosure';
import { cn } from '../../../_theme/utils';


export const RecordingAreaSection = ({ spot }) => {
  const { isOpen: isReadingMore, toggle } = useDisclosure();
  const [recordingArea, setRecordingAreaInfo] = useState({} as { about: string, imageUrl: string });
  useEffect(() => {
    const area: { about: string, imageUrl: string }[] = Object.values(spot?.recordingArea ?? {});
    console.log("Recording area:", area, spot?.recordingArea, spot);

    if (area.length !== 0) {
      setRecordingAreaInfo(area?.[0]);
    }
  }, [spot]);

  return (
    <SectionLayout
      className="pb-5 lg:pb-12 space-y-3 px-5"
      relativeId="recording-area"
    >
      <div className="flex items-center justify-between brand-big-tablet:pb-4">
        <h2 className="uppercase text-xl md:text-2xl font-bold">
          Recording area
        </h2>
      </div>
      <div className="flex flex-col brand-big-tablet:flex-row items-stretch gap-5 brand-big-tablet:gap-7 pb-2 md:pb-4">
        <div className="relative flex-1 rounded-3xl overflow-hidden w-full md:w-unset aspect-[357/221]">
          <img
            src={recordingArea.imageUrl}
            alt="Recording Area"
            className="object-cover w-full h-full"
          />
        </div>
        <div className="w-full xl:max-w-brand-md">
          <p className="mb-2 md:mb-4 uppercase font-bold text-brand-md md:text-xl text-left lg:text-center">
            About the recording area
          </p>
          <p
            className={cn(
              'mb-3 font-normal md:font-medium text-brand-md md:text-lg leading-tight line-clamp-[7] md:line-clamp-[7] flex-1',
              {
                'line-clamp-none md:line-clamp-none': isReadingMore,
              }
            )}
          >{recordingArea.about}
          </p>
          <GenericButton
            onClick={toggle}
            variant="secondary"
            className={cn('justify-between gap-8 shadow-brand-300')}
            wide
            small
            rightIcon={
              <ChevronDownIcon
                className={cn('w-4 h-2 stroke-brand-primary transition-all', {
                  'rotate-180': isReadingMore,
                })}
              />
            }
          >
            Read more
          </GenericButton>

          <div className="shadow-brand-600 mt-7  bg-white rounded-3xl px-6 py-6 lg:px-9 lg:py-10 flex items-center gap-8">
            <div className="flex-1 space-y-4 md:space-y-7">
              <p className="uppercase text-xs md:text-brand-md font-semibold md:font-medium">
                Find our beach partners
              </p>
              {(Object.values(spot.partners ?? {})).slice(0, 1).map((partner, i) => (<p key={i} className="uppercase font-bold text-lg ">{partner['name']}</p>))}
            </div>
            {(Object.values(spot.partners ?? {})).slice(0, 1).map((partner, index) => (
              <div key={index} className="relative max-w-[5rem] w-full aspect-[1/1] rounded-2xl overflow-hidden mr-2 md:mr-0">
                <img
                  src={partner['imageUrl']}
                  alt="Recording Area"
                  className="object-cover w-full h-full"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center">
        <GenericButton
          variant="primary"
          rightIcon={<GoogleMapIcon className="w-5 h-5 lg:w-8 lg:h-8" />}
          onClick={() => spot?.locationUrl && window.open(spot.locationUrl)}
        >
          Get directions
        </GenericButton>
      </div>
    </SectionLayout>
  );
};
