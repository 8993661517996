// 'use client';
import React from "react";
import { ChevronDownIcon } from "../../../assets/SitePage/icons";
import { cn } from "../../../_theme/utils";
import { useDisclosure } from "../../../hooks/use-disclosure";
import { AnimatePresence, motion } from "framer-motion";
import { Link } from "react-router-dom";
import { NavbarRouteType } from "./types";

type NavbarRouteProps = {
  route: NavbarRouteType;
  setOpenItems?: (isOpen: (string | JSX.Element)[]) => void;
  openItems?: (string | JSX.Element)[];
};

export const NavbarRoute = ({
  route,
  setOpenItems,
  openItems = [],
}: NavbarRouteProps) => {
  const { isOpen, open, close, toggle } = useDisclosure();

  const toggleIsOpen = () => {
    const value = !isOpen;
    toggle();
    if (value) {
      setOpenItems?.([...openItems, route.label]);
      return;
    }
    setOpenItems?.(openItems.filter((item) => item !== route.label));
  };
  return (
    <>
      {route.children ? (
        <button
          className={cn(
            "flex items-start gap-3 leading-none transition-all duration-500 uppercase font-semibold"
          )}
          onClick={toggleIsOpen}
        >
          {getRouteDetails(route, isOpen)}
        </button>
      ) : (
        <Link
          className={cn(
            "flex items-start gap-3 leading-none uppercase font-semibold"
          )}
          to={route.href}
        >
          {getRouteDetails(route)}
        </Link>
      )}
    </>
  );
};

const getRouteDetails = (route: NavbarRouteType, isOpen: boolean = false) => {
  return (
    <span className="">
      <span className="flex items-center justify-end gap-3 leading-none uppercase font-semibold">
        <span>{route.label}</span>
        <span>
          {route.children ? (
            <ChevronDownIcon
              className={cn("transition-all", { "rotate-180": isOpen })}
            />
          ) : null}
        </span>
      </span>
      <AnimatePresence exitBeforeEnter initial={false}>
        {isOpen ? (
          <motion.span
            className="flex flex-col items-end gap-5 font-medium text-brand-md"
            initial={{ height: 0, opacity: 0, marginTop: 0 }}
            animate={{ height: "auto", opacity: 1, marginTop: 20 }}
            transition={{
              duration: 0.3,
            }}
            exit={{ height: 0, opacity: 0 }}
          >
            {route.children?.map((childRoute) => (
              <Link key={childRoute.href} to={childRoute.href}>
                {childRoute.label}
              </Link>
            ))}
          </motion.span>
        ) : null}
      </AnimatePresence>
    </span>
  );
};
