import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
/* import { Box } from "@mui/material"; */
import tb from "./tableStyle.module.scss";
import { FiFilter } from "react-icons/fi";
import { IoMdCheckmark } from "react-icons/io";
import { Dropdown} from "react-bootstrap";
import Header from "../Components/Header";
import UserModal from '../Components/UserModal';
import axios from "axios";
import { endpoints } from "../../../Api/Api";
import moment from "moment";
import { useDebouncedCallback } from 'use-debounce';
import dayjs from 'dayjs';

export default function ScheduleDataGrid() {
  const Navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [refersh, setReferesh] = useState(false);
  const [userCount, setUserCount] = useState()
  const [users, setUsers] = useState([]);
  const [query, setQuery] = useState('');
  const [searchByParam, setSearchByParam] = useState("")
  const [searchInputVal, setSearchInputVal] = useState("")
  const [primaryUsersList, setPrimaryUsersList] = useState([])
  const [idUserModal, setIdUserModal] = useState('')
  const [targetUser, setTargetUser] = useState(null)


  const [selectedUserItem, setSelectedUserItem] = useState({
    userItem: [],
  });

  const handleChangeCheckbox = (e) => {
    const value = e.target.value;
    const checked = e.target.checked;

    const itemPurchased = selectedUserItem?.userItem;
    // Case 1 : The user checks the box
    if (checked === true) {
      setSelectedUserItem({
        userItem: [...itemPurchased, value]
        });
    }
    // Case 2  : The user unchecks the box
    else {
      setSelectedUserItem({
        userItem: itemPurchased.filter(
              (e) => e != value
            )
        });
    }
    // clear search ip box 
    setSearchInputVal('')
  };


  // filter data based on following condition(no unlimited, free access[freeaccess < new Date()], subscription[subsstatus === true])
  useEffect(() => {
    let res = []
    if(selectedUserItem?.userItem.length < 1){
      setUsers(primaryUsersList)
    }else{
      primaryUsersList.map((user) => {
        if(selectedUserItem?.userItem?.indexOf("SUBSCRIPTION") != -1 && user?.subsstatus == true){
          res.push(user)
        }else if(selectedUserItem?.userItem?.indexOf("FREE ACCESS") != -1 && Number(user?.freeaccessend) > Number(dayjs().format('YYYYMMDDHHmmss'))){
          res.push(user)
        } else if(selectedUserItem?.userItem?.indexOf("NO UNLIMITED") != -1 && !user?.subsstatus && (!user?.freeaccessend || Number(user?.freeaccessend) < Number(dayjs().format('YYYYMMDDHHmmss'))) ){
          res.push(user)
        }
        // else{
        //   res.push()
        // }
        
      })
      setUsers(res)
    }
  }, [selectedUserItem.userItem.length])

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    needToRerender()
    setOpen(false);
  }

  const resetCheckboxes = () => {
    setSelectedUserItem({
      userItem: [],
    })
    // clear checkbox too
    const subsCheckbox =  document.querySelector('[name="SUBSCRIPTION"]');
    const freeAccessCheckbox = document.querySelector('[name="FREE ACCESS"]');
    const noUnlimitedCheckbox =  document.querySelector('[name="NO UNLIMITED"]');
    if(subsCheckbox && subsCheckbox?.checked){
      document.querySelector('[name="SUBSCRIPTION"]').checked  = false
    }
    if(freeAccessCheckbox && freeAccessCheckbox?.checked){
      document.querySelector('[name="FREE ACCESS"]').checked = false
    }
    if(noUnlimitedCheckbox && noUnlimitedCheckbox?.checked){
      document.querySelector('[name="NO UNLIMITED"]').checked = false
    }
  }

  const handleModalClick = (event) => {
    event.stopPropagation(); // Prevent closing modal on click inside modal
  };

  const getUsers= () => {
    const token = localStorage.getItem("token");
    const noCache = Date.now();
    axios
      .get(`${endpoints.GET_USERS}?nocache=${noCache}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if(res.data.status === "True"){
          setUserCount({
            "allusercnt": res?.data?.allusercnt,
            "lastsevencnt": res?.data?.lastsevencnt,
            "lastthirtycnt": res?.data?.lastthirtycnt,
            "todayactivecnt": res?.data?.todayactivecnt
          })
          setUsers(res.data.data);
          // need to reset checkboxes
          resetCheckboxes()
        }
        
      })
      .catch((err) => console.log(err));
  };

  const SortByUserProperty = () => {
    try {
      const getToken = localStorage.getItem("token");
      const getUserInfo = localStorage.getItem("userInfo")
      if (getToken !== null && getUserInfo != null) {
        if (JSON.parse(getUserInfo).role === "superadmin") {
            axios
              .get(`${endpoints.GETUSERSBYFIELDS}?query=${searchByParam}`,
                {
                  headers: {
                    Authorization: `Bearer ${getToken}`,
                  },
                }
              )
              .then((response) => {
                if (response.data.status === "True") {
                  setUsers(response.data.users)
                  setPrimaryUsersList(response.data.users)
                }
              })
              .catch((error) => {
                console.log(error)
              });
            }
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  }

  // Debounce the user input to reduce the number of API requests
  const debouncedSearch = useDebouncedCallback(
    // Your search function that makes the API request
    async (searchQuery) => {
      try {
        const getToken = localStorage.getItem("token");
        const getUserInfo = localStorage.getItem("userInfo")
        if (getToken !== null && getUserInfo != null) {
          if (JSON.parse(getUserInfo).role === "superadmin") {
              axios
                .get(`${endpoints.SEARCHUSER}?query=${searchQuery}`,
                  {
                    headers: {
                      Authorization: `Bearer ${getToken}`,
                    },
                  }
                )
                .then((response) => {
                  if (response.data.status === "True") {
                    setUsers(response.data.users)
                  }
                })
                .catch((error) => {
                  console.log(error)
                });
              }
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    },
    // Debounce delay in milliseconds
    100
  );
  
  const handleInputChange = (e) => {
    setSearchInputVal(e.target.value)
  };

  const inputOnclickHandler = () => {
    setQuery(searchInputVal);
    // reset selected check boxes
    resetCheckboxes()
    // also clear field sort state
    setSearchByParam('')
  }

  const [needToSearch, setNeedToSearch] = useState(false)
  const handleForGetAllUserIndvProperty = (sortByProperty) => {
    if(sortByProperty === searchByParam){
      let allUser_rev = primaryUsersList.reverse()
      let allUser_revUp = allUser_rev.map(user => user)
      setUsers(allUser_revUp)
    }else{
      setSearchByParam(sortByProperty)
      setNeedToSearch(!needToSearch)
    }
    // need to reset checkboxes
    resetCheckboxes()
    setSearchInputVal('')
  }

  useEffect(() => {
    SortByUserProperty()
  }, [needToSearch])

  const dateFormatter = (date= '-') => {
    if(date && date != '-'){
      const dateFormat = moment(date).format('DD/MM/YYYY')
      return dateFormat
    }else{
      return "-";
    }
  }

  const numberFormatter = (num) => {
    if(num){
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }else{
      return '-';
    }
    
  }

  useEffect(() => {
    debouncedSearch(query);
  }, [query, debouncedSearch]);


  useEffect(() => {
    getUsers();
  }, [refersh]);

  const needToRerender = () => {
    setReferesh(!refersh)
    // need to reset checkboxes
    resetCheckboxes()
    setSearchInputVal('')
  }

  return (
    <>
    <div className={`${tb.bodyBackgound}`}>
    <Header/>
      <div className={`${tb.userManagement}`}>
        <div className="container">
          <div className={`${tb.userSalesBox}`}>
            <div className={`${tb.Heading}`}>
              <h3>USERS</h3>
            </div>
            <div className={`${tb.totalUser}`}>
              <div className={`${tb.boxUser}`}>
                <h4>TOTAL USERS</h4>
                <div className={`${tb.userCount}`}>
                  <span>{numberFormatter(userCount?.allusercnt)}</span>
                </div>
              </div>
              <div>
                <h3>NEW USERS</h3>
                <div className={`${tb.boxUser}`}>
                  <ul>
                    <li>
                      <h4>LAST 7 DAYS</h4>
                      <span>{numberFormatter(userCount?.lastsevencnt)}</span>
                    </li>
                    <li>
                      <h4>LAST 30 DAYS</h4>
                      <span>{numberFormatter(userCount?.lastthirtycnt)}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={`${tb.boxUser}`}>
                <h4>ACTIVE TODAY</h4>
                <div className={`${tb.userCount}`}>
                  <span>{numberFormatter(userCount?.todayactivecnt)}</span>
                </div>
              </div>
            </div>
            <div className={`${tb.filterRemove}`}>
              <span onClick={() => {
                getUsers()
                // reset selected check boxes
                resetCheckboxes()
                // reset search box
                setSearchInputVal('')
              }}>REMOVE ALL FILTERs   <span>X</span></span>
              <div className={`${tb.filterRemove}`}>
                <input type="search" placeholder="USER EMAIL / NAME" value={searchInputVal} onChange={handleInputChange}/>
                <button onClick={inputOnclickHandler}>Search</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className={`${tb.tabbingSection}`}>
        <div className="container">
          <div className={`${tb.tableResponsive}`}>
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th onClick={() => handleForGetAllUserIndvProperty("email")}>USER EMAIL</th>
                  <th onClick={() => handleForGetAllUserIndvProperty("created_at")}>created at</th>
                  <th onClick={() => handleForGetAllUserIndvProperty("lastactive")}>LAST ACTIVE</th>
                  <th>UNLIMITED
                  <Dropdown className={`${tb.dropDownFilter}`}>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <FiFilter className={`${tb.filterIcon}`} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <ul>
                        <li><label><input type="checkbox" value="SUBSCRIPTION" name="SUBSCRIPTION" onChange={handleChangeCheckbox}/> SUBSCRIPTION</label></li>
                        <li><label><input type="checkbox" value="FREE ACCESS" name="FREE ACCESS" onChange={handleChangeCheckbox}/> FREE ACCESS</label></li>
                        <li><label><input type="checkbox" value="NO UNLIMITED" name="NO UNLIMITED" onChange={handleChangeCheckbox}/> NO UNLIMITED</label></li>
                      </ul>
                    </Dropdown.Menu>
                  </Dropdown>
                  </th>
                  <th onClick={() => handleForGetAllUserIndvProperty("expireson")}>EXPIRES ON</th>
                  <th onClick={() => handleForGetAllUserIndvProperty("spend")}>SPEND</th>
                  <th onClick={() => handleForGetAllUserIndvProperty("purchases")}>PURCHASES</th>
                  <th onClick={() => handleForGetAllUserIndvProperty("session")}>SESSIONS</th>
                  <th>W</th>
                  <th>D</th>
                </tr>
              </thead>

              <tbody>
                {users && users?.length > 0 &&
                  users?.map((user, index) => {
                    return (<tr onClick={() => {
                      setIdUserModal(user?.id)
                      setTargetUser(user)
                      handleOpen()
                    }} key={index}>
                      <td>{++index}</td>
                      <td>{user.email}</td>
                      <td>{dateFormatter(user?.created_at)}</td>
                      <td>{dateFormatter(user?.lastactive)}</td>
                      <td>
                        {user?.subsstatus == true ? <span className={`${tb.yelloIcon}`}><IoMdCheckmark /></span>:(user?.freeaccess && Number(user?.freeaccessend) > Number(dayjs().format("YYYYMMDDHHmmss")) ? <span className={`${tb.greenIcon}`}><IoMdCheckmark /></span> :<span></span>)}
                        
                        {/* <span></span> */}
                        {/* <span className={`${tb.yelloIcon}`}><IoMdCheckmark /></span> */}
                        {/* <span className={`${tb.greenIcon}`}><IoMdCheckmark /></span> */}
                      </td>
                      <td>{user?.updateExpireOn && user?.updateExpireOn != 0? <>{dayjs(String(user?.updateExpireOn)).format("DD/MM/YYYY")}</>: <>--</>}</td>
                      {/* <td>{user?.subsstatus == true ? <>{dayjs(user?.expireson).format("DD/MM/YYYY")}</>:(user?.freeaccess && Number(user?.freeaccessend) > Number(dayjs().format("YYYYMMDDHHmmss")) ? <>{dayjs(user?.freeaccessend).format("DD/MM/YYYY")}</> :<>--</>)}</td> */}
                      <td>{ (user?.spend)?.toFixed(2) ? `€${(user?.spend)?.toFixed(2)}` : '--'}</td>
                      <td>{user?.purchase || user?.purchase == 0 ? user?.purchase : '--'}</td>
                      <td>{user?.sessions || user?.sessions == 0 ? user?.sessions : '--'}</td>
                      <td>
                        <span>1</span>
                      </td>
                      <td>
                        <span>0</span>
                      </td>
                    </tr>)
                  })}
                
              </tbody>
            </table>
          </div>
        </div>
      </section>

    
      <UserModal open={open} handleClose={handleClose} id={idUserModal} userDetails={targetUser}/>
    </div>
    </>
  );
}
