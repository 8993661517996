import React, { useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";

import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { developement_url } from "../../Api/Api";
import Footer from "../../components/Footer/Footer";
import ReactGA from "react-ga4";


ReactGA.initialize("G-9RWTM70QHK");
// Send pageview with a custom path
ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Terms and Conditions" });

const TermsAndConditions = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const getToken = localStorage.getItem("token");
    const getUserInfo = localStorage.getItem("userInfo");
  }, []);

  return (
    <>
      <Navbar home={false} />
      <div className="toc-container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>TERMS AND CONDITIONS</title>
          {/* <link
            rel="canonical"
            href={`${developement_url}termsandconditions`}
          /> */}
        </Helmet>
        <div className="toc-heading">TERMS AND CONDITIONS</div>
        <div className="toc-content">
          <p>Last updated: 27-June-2023</p>
          <p>Welcome to Surf Eye!</p>
          <p>
            These terms and conditions outline the rules and regulations for the
            use of Surf Eye's Website, located at www.surfeye.video.
          </p>
          <p>
            By accessing this website we assume you accept these terms and
            conditions. Do not continue to use Surf Eye if you do not agree to
            take all of the terms and conditions stated on this page.
          </p>
          <p>
            The following terminology applies to these Terms and Conditions,
            Privacy Statement and Disclaimer Notice and all Agreements:
            "Client", "You" and "Your" refers to you, the person log on this
            website and compliant to the Company’s terms and conditions. "The
            Company", "Ourselves", "We", "Our" and "Us", refers to our Company.
            "Party", "Parties", or "Us", refers to both the Client and
            ourselves. All terms refer to the offer, acceptance and
            consideration of payment necessary to undertake the process of our
            assistance to the Client in the most appropriate manner for the
            express purpose of meeting the Client’s needs in respect of
            provision of the Company’s stated services, in accordance with and
            subject to, prevailing law of Portugal. Any use of the above
            terminology or other words in the singular, plural, capitalization
            and/or he/she or they, are taken as interchangeable and therefore as
            referring to the same.
          </p>
          <p className="toc-bold">Cookies</p>
          <p>
            We employ the use of cookies. By accessing Surf Eye, you agreed to
            use cookies in agreement with the Surf Eye's Privacy Policy.
          </p>
          <p>
            Most interactive websites use cookies to let us retrieve the user’s
            details for each visit. Cookies are used by our website to enable
            the functionality of certain areas to make it easier for people
            visiting our website. Some of our affiliate/advertising partners may
            also use cookies.
          </p>
          <p className="toc-bold">License</p>
          <p>
            Unless otherwise stated, Surf Eye and/or its licensors own the
            intellectual property rights for all material on Surf Eye. All
            intellectual property rights are reserved. You may access this from
            Surf Eye for your own personal use subjected to restrictions set in
            these terms and conditions.
          </p>
          <p>You must not:</p>
          <p>Republish material from Surf Eye</p>
          <p>Sell, rent or sub-license material from Surf Eye</p>
          <p>Reproduce, duplicate or copy material from Surf Eye</p>
          <p>Redistribute content from Surf Eye</p>
          <p>Select videos where you do not appear</p>
          <p>Sell or transfer your profile</p>
          <p>This Agreement shall begin on the date hereof.</p>
          <p>
            Parts of this website offer an opportunity for users to post and
            exchange opinions and information in certain areas of the website.
            Surf Eye does not filter, edit, publish or review Comments prior to
            their presence on the website. Comments do not reflect the views and
            opinions of Surf Eye,its agents and/or affiliates. Comments reflect
            the views and opinions of the person who posts their views and
            opinions. To the extent permitted by applicable laws, Surf Eye shall
            not be liable for the Comments or for any liability, damages or
            expenses caused and/or suffered as a result of any use of and/or
            posting of and/or appearance of the Comments on this website.
          </p>
          <p>
            Surf Eye reserves the right to monitor all Comments and to remove
            any Comments which can be considered inappropriate, offensive or
            causes breach of these Terms and Conditions.
          </p>
          <p>You warrant and represent that:</p>
          <p>
            You are entitled to post the Comments on our website and have all
            necessary licenses and consents to do so;
          </p>
          <p>
            The Comments do not invade any intellectual property right,
            including without limitation copyright, patent or trademark of any
            third party;
          </p>
          <p>
            The Comments do not contain any defamatory, libelous, offensive,
            indecent or otherwise unlawful material which is an invasion of
            privacy.
          </p>
          <p>
            The Comments will not be used to solicit or promote business or
            custom or present commercial activities or unlawful activity.
          </p>
          <p>
            You hereby grant Surf Eye a non-exclusive license to use, reproduce,
            edit and authorize others to use, reproduce and edit any of your
            Comments in any and all forms, formats or media.
          </p>
          <p className="toc-bold">Hyperlinking your content</p>
          <p>
            The following organizations may link to our Website without prior
            written approval:
          </p>
          <p>Government agencies;</p>
          <p>Search engines;</p>
          <p>News organizations;</p>
          <p>
            Online directory distributors may link to our Website in the same
            manner as they hyperlink to the Websites of other listed businesses;
            and
          </p>
          <p>
            System wide Accredited Businesses except soliciting non-profit
            organizations, charity shopping malls, and charity fundraising
            groups which may not hyperlink to our Website.
          </p>
          <p>
            These organizations may link to our home page, to publications or to
            other Website information so long as the link: (a) is not in any way
            deceptive; (b) does not falsely imply sponsorship, endorsement or
            approval of the linking party and its products and/or services; and
            (c) fits within the context of the linking party’s site.
          </p>
          <p>
            We may consider and approve other link requests from the following
            types of organizations:
          </p>
          <p>commonly-known consumer and/or business information sources;</p>
          <p>dot.com community sites;</p>
          <p>associations or other groups representing charities;</p>
          <p>online directory distributors;</p>
          <p>internet portals;</p>
          <p>accounting, law and consulting firms; and</p>
          <p>educational institutions and trade associations.</p>
          <p>
            We will approve link requests from these organizations if we decide
            that: (a) the link would not make us look unfavorably to ourselves
            or to our accredited businesses; (b) the organization does not have
            any negative records with us; (c) the benefit to us from the
            visibility of the hyperlink compensates the absence of Surf Eye; and
            (d) the link is in the context of general resource information.
          </p>
          <p>
            These organizations may link to our home page so long as the link:
            (a) is not in any way deceptive; (b) does not falsely imply
            sponsorship, endorsement or approval of the linking party and its
            products or services; and (c) fits within the context of the linking
            party’s site.
          </p>
          <p>
            If you are one of the organizations listed in paragraph 2 above and
            are interested in linking to our website, you must inform us by
            sending an e-mail to Surf Eye. Please include your name, your
            organization name, contact information as well as the URL of your
            site, a list of any URLs from which you intend to link to our
            Website, and a list of the URLs on our site to which you would like
            to link. Wait 2-3 weeks for a response.
          </p>
          <p>Approved organizations may hyperlink to our Website as follows:</p>
          <p>By use of our corporate name; or</p>
          <p>By use of the uniform resource locator being linked to; or</p>
          <p>
            By use of any other description of our Website being linked to that
            makes sense within the context and format of content on the linking
            party{"’"}s site.
          </p>
          <p>
            No use of Surf Eye's logo or other artwork will be allowed for
            linking absent a trademark license agreement.
          </p>
          <p className="toc-bold">iFrames</p>
          <p>
            Without prior approval and written permission, you may not create
            frames around our Web Pages that alter in any way the visual
            presentation or appearance of our Website.
          </p>
          <p className="toc-bold">Content liability</p>
          <p>
            We shall not be held responsible for any content that appears on
            your Website. You agree to protect and defend us against all claims
            that are rising on your Website. No link(s) should appear on any
            Website that may be interpreted as libelous, obscene or criminal, or
            which infringes, otherwise violates, or advocates the infringement
            or other violation of, any third party rights.
          </p>
          <p className="toc-bold">Your Privacy</p>
          <p>Please read our Privacy Policy</p>
          <p className="toc-bold">Reservation of Rights</p>
          <p>
            We reserve the right to request that you remove all links or any
            particular link to our Website. You approve to immediately remove
            all links to our Website upon request. We also reserve the right to
            amend these terms and conditions and its linking policy at any time.
            By continuously linking to our Website, you agree to be bound to and
            follow these linking terms and conditions.
          </p>
          <p className="toc-bold">Removal of links from our website</p>
          <p>
            If you find any link on our Website that is offensive for any
            reason, you are free to contact and inform us any moment. We will
            consider requests to remove links but we are not obligated to or so
            or to respond to you directly.
          </p>
          <p>
            We do not ensure that the information on this website is correct, we
            do not warrant its completeness or accuracy; nor do we promise to
            ensure that the website remains available or that the material on
            the website is kept up to date.
          </p>
          <p className="toc-bold">Disclaimer</p>
          <p>
            To the maximum extent permitted by applicable law, we exclude all
            representations, warranties and conditions relating to our website
            and the use of this website. Nothing in this disclaimer will:
          </p>
          <p>
            limit or exclude our or your liability for death or personal injury;
          </p>
          <p>
            limit or exclude our or your liability for fraud or fraudulent
            misrepresentation;
          </p>
          <p>
            limit any of our or your liabilities in any way that is not
            permitted under applicable law; or
          </p>
          <p>
            exclude any of our or your liabilities that may not be excluded
            under applicable law.
          </p>
          <p>
            The limitations and prohibitions of liability set in this Section
            and elsewhere in this disclaimer: (a) are subject to the preceding
            paragraph; and (b) govern all liabilities arising under the
            disclaimer, including liabilities arising in contract, in tort and
            for breach of statutory duty.
          </p>
          <p>
            As long as the website and the information and services on the
            website are provided free of charge, we will not be liable for any
            loss or damage of any nature.
          </p>
          <p className="toc-bold">Dispute Resolution</p>
          <p>
            In the event of any dispute or claim arising out of or in connection with these terms and 
            conditions or the use of our website, both parties agree to seek 
            resolution through mediation or arbitration before pursuing any legal action. This clause is 
            intended to encourage the swift and cost-effective resolution of disputes.
          </p>
          <p>1. Mediation:</p>
          <p>
            If any dispute arises, the parties shall first attempt to resolve the matter amicably through
            mediation. The mediator shall be agreed upon by both parties, and the mediation shall take 
            place in Portugal. The costs of mediation shall be borne equally by both 
            parties unless otherwise agreed.
          </p>
          <p>2. European Dispute Resolution:</p>
          <p>
            If the dispute cannot be resolved through mediation, either party may refer the matter to the 
            European Online Dispute Resolution platform (ODR). The ODR platform is an online platform 
            provided by the European Commission, designed to facilitate the resolution of disputes between
            consumers and online merchants. For more information about the ODR platform and its procedures, 
            please visit: https://ec.europa.eu/consumers/odr/main/?event=main.home2.show.
          </p>
          <p>3. Arbitration:</p>
          <p>
            If the dispute remains unresolved after attempting mediation and utilizing the European 
            Dispute Resolution platform, either party may initiate arbitration. The arbitration shall 
            be conducted in accordance with the rules of European Dispute Resolution Platform, 
            and the decision of the arbitrator(s) shall be final and binding upon both parties. The place 
            of arbitration shall be Portugal. The costs of arbitration, including the arbitrator’s fees, 
            shall be borne as determined by the arbitrator(s) unless otherwise agreed.
          </p>
          <p>
            By using our website, you acknowledge and agree to the terms and conditions of this dispute 
            resolution clause.
          </p>
          <p className="toc-bold">Contact</p>
          <p>
            In order to resolve a complaint regarding the website or to receive
            additional information regarding use of the site, contact us at
            info@surfeye.video .
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsAndConditions;
