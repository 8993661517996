import React from 'react';
import { Icon, type IconProps } from './core';

export const PreviewIcon = (props: IconProps) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    width="61"
    height="58"
    viewBox="0 0 61 58"
    fill="none"
    {...props}
  >
    <path
      d="M30.2454 21.8008C34.8027 21.8008 38.4947 25.3086 38.4947 29.6387C38.4947 33.9687 34.8027 37.4765 30.2454 37.4765C25.6881 37.4765 21.9961 33.9687 21.9961 29.6387C21.9961 25.3086 25.6881 21.8008 30.2454 21.8008Z"
      stroke="#01243C"
      strokeWidth="2.7571"
    />
    <path
      d="M10.4245 31.3413C9.69775 30.3813 9.67699 29.1079 10.383 28.1283C12.6463 24.935 19.1664 18.7051 30.5453 18.7051C40.5746 18.7051 46.887 24.3473 49.6279 27.5798C50.5831 28.6965 50.6661 30.2638 49.8148 31.4588C47.4268 34.8285 39.9101 40.5686 30.2338 40.5686C21.3259 40.5686 12.7917 34.515 10.4245 31.3609V31.3413Z"
      stroke="#01243C"
      strokeWidth="2.7571"
    />
    <path
      d="M2.94336 23.2581C-5.07711 53.2321 40.2341 70.0768 56.0383 40.6894C69.2677 16.1068 35.41 -11.2972 9.18808 12.1959"
      stroke="#01243C"
      strokeWidth="2.7571"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.56543 2V12.9504H20.463"
      stroke="#01243C"
      strokeWidth="2.7571"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
