import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import tb from "./tableStyle.module.scss";
import { FiFilter } from "react-icons/fi";
import axios from "axios";
import { endpoints } from "../../../Api/Api";
import { useDebouncedCallback } from 'use-debounce';
import { Dropdown} from "react-bootstrap";
import Header from "../Components/Header";
import dayjs from "dayjs";
import moment from "moment";

export default function ScheduleDataGrid() {
  const Navigate = useNavigate();
  const [refresh, setRefresh] = useState(false);
  const [primarySalesList, setPrimarySalesList] = useState([])
  const [salesRecord, setSalesRecord] = useState([]);
  const [searchStack, setSearchStack] = useState([])
  const [itemCategory, setItemCategory] = useState([])
  const [codeCategory, setCodeCategory] = useState([])
  const [locationCategory, setLocationCategory] = useState([])
  const [totalRevenue, setTotalRevenue] = useState(0)
  const [query, setQuery] = useState('');
  const [searchByParam, setSearchByParam] = useState("")
  const [searchInputVal, setSearchInputVal] = useState("")
  const [subsRecord, setSubsRecord] = useState({
    "activeaot": 0,
    "newsubs": 0,
    "churned": 0
  })

  // dates state 
  const [createAtStart, setCreateAtStart] = useState()
  const [createAtEnd, setCreateAtEnd] = useState()
  const [createdAtError, setCreateAtError] = useState()
  const [surfDateStart, setSurfDateStart] = useState()
  const [surfDateEnd, setSurfDateEnd] = useState()
  const [surfDateError, setSurfDateError] = useState()


  const createAtSelectorHandler = (e) => {
    // case 1 if one of date filed is empty
    if(!createAtStart || !createAtEnd){
      setCreateAtError("Dates should not be empty")
      return
    }
    // case 2 if startDate < endDate
    const startDate = dayjs(createAtStart).format("YYYYMMDD")
    const endDate = dayjs(createAtEnd).format("YYYYMMDD")
    if(Number(startDate) > Number(endDate)){
      setCreateAtError("End Date should be Greater than start !")
      return
    }
    
    // for filter data state set
    setSelectedSalesItem({
      SalesItem:{
        ...selectedSalesItem.SalesItem,
        createdAt_start: [createAtStart],
        createdAt_end: [createAtEnd]
      }
    })
    const index = searchStack.indexOf("createdAt_end");
    if (index > -1) { // only splice array when item is found
      searchStack.splice(index, 1); // 2nd parameter means remove one item only
      setSearchStack([...searchStack, "createdAt_end"])
    }else{
      setSearchStack([...searchStack, "createdAt_end"])
    }
    setRefresh(!refresh)    
  }
  

  const surfDateSelectorHandler = () => {
    // case 1 if one of date filed is empty
    if(!surfDateStart || !surfDateEnd){
      setSurfDateError("Dates should not be empty")
      return
    }
    // case 2 if startDate < endDate
    const startDate = dayjs(surfDateStart).format("YYYYMMDD")
    const endDate = dayjs(surfDateEnd).format("YYYYMMDD")
    if(Number(startDate) > Number(endDate)){
      setSurfDateError("End Date should be Greater than start !")
      return
    }
    // for filter data state set
    setSelectedSalesItem({
      SalesItem:{
        ...selectedSalesItem.SalesItem,
        surfdate_start: [surfDateStart],
        surfdate_end: [surfDateEnd]
      }
    })
    const index = searchStack.indexOf("surfdate_end");
    if (index > -1) { // only splice array when item is found
      searchStack.splice(index, 1); // 2nd parameter means remove one item only
      setSearchStack([...searchStack, "surfdate_end"])
    }else{
      setSearchStack([...searchStack, "surfdate_end"])
    }
    setRefresh(!refresh)
  }
  
  const numberFormatter = (num) => {
    if(num){
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }else{
      return '-';
    }
    
  }

  // GET_SALES
  const getSales = () => {
    const token = localStorage.getItem("token");
    const noCache = Date.now();
    axios
      .get(`${endpoints.GET_SALES}?nocache=${noCache}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if(res.data.status === "True"){
          setSubsRecord({
            "activeaot": res.data?.activeaot || 0,
            "newsubs": res.data?.newsubs || 0,
            "churned": res.data?.churned || 0
          })
          setPrimarySalesList(res.data.sales)
          setSalesRecord(res.data.sales)
          setTotalRevenue(numberFormatter(res.data.totalrevenue?.toFixed(2)) || 0)
          setItemCategory(res.data.itemcategory)
          setCodeCategory(res.data.codecategory)
          setLocationCategory(res.data.locationcategory)
          // need to reset checkboxes
          // resetCheckboxes()
        }
        
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getSales();
  }, []);

  const [selectedSalesItem, setSelectedSalesItem] = useState({
    SalesItem: {
      item: [],
      code: [],
      location: [],
      createdAt_start: [],
      createdAt_end: [],
      surfdate_start: [],
      surfdate_end: []
    },
  });

  // filter functions
  const handleChangeCheckbox = (e) => {
    const name = e.target.name
    const value = e.target.value;
    const checked = e.target.checked;

    const index = searchStack.indexOf(name);
    if (index > -1) { // only splice array when item is found
      searchStack.splice(index, 1); // 2nd parameter means remove one item only
      setSearchStack([...searchStack, name])
    }else{
      setSearchStack([...searchStack, name])
    }

    const itemSelected = selectedSalesItem?.SalesItem;
    // Case 1 : The user checks the box
    if (checked === true) {
      itemSelected[name].push(value)
      setSelectedSalesItem[itemSelected]
    }
    // Case 2  : The user unchecks the box
    else {
      itemSelected[name] = itemSelected[name].filter((e) => e != value)
      setSelectedSalesItem[itemSelected]
    }
    setRefresh(!refresh)
  };

  // filter data based on following condition(item, code, location, date, surfdate)
  useEffect(() => {
    if(selectedSalesItem?.SalesItem.item.length < 1 && selectedSalesItem.SalesItem.code.length < 1 && selectedSalesItem.SalesItem.location.length < 1 && (selectedSalesItem.SalesItem.createdAt_start.length < 1 && selectedSalesItem.SalesItem.createdAt_end.length < 1) && (selectedSalesItem.SalesItem.surfdate_start.length < 1 && selectedSalesItem.SalesItem.surfdate_end.length < 1)){
      // update total revenue, new subs, churned as well
      let toalRevenue = 0
      let newSubs = 0
      let churned = 0
      primarySalesList.map((salesRecord, idx) => {
        // for revenue
        if(salesRecord?.revenue){
          toalRevenue+= salesRecord?.revenue
        }
        // calculate month and new sub
        if(salesRecord?.item && salesRecord?.item === "per-month"){
          newSubs += 1
        }
        // calculate churned
        if(salesRecord?.item && salesRecord?.item === "canc sub"){
          churned += 1
        }

      })
      // set subs records
      setSubsRecord({
        ...subsRecord,
        "newsubs": newSubs,
        "churned": churned
      })
      // set total revenue
      setTotalRevenue(toalRevenue?.toFixed(2))
      // set sales record
      setSalesRecord(primarySalesList)
    }else{
      let resultant = primarySalesList
      searchStack.map((filterparam, idx) => {
        let tmpRes = fn[filterparam](resultant, filterparam)
        resultant = tmpRes
      })
      // update total revenue, new subs, churned as well
      let toalRevenue = 0
      let newSubs = 0
      let churned = 0
      resultant.map((salesRecord, idx) => {
        // for revenue
        if(salesRecord?.revenue){
          toalRevenue+= salesRecord?.revenue
        }
        // calculate month and new sub
        if(salesRecord?.item && salesRecord?.item === "per-month"){
          newSubs += 1
        }
        // calculate churned
        if(salesRecord?.item && salesRecord?.item === "canc sub"){
          churned += 1
        }

      })
      // set subs records
      setSubsRecord({
        ...subsRecord,
        "newsubs": newSubs,
        "churned": churned
      })
      // set total revenue
      setTotalRevenue(toalRevenue?.toFixed(2))
      // set sales record
      setSalesRecord(resultant)
    }
    setSearchInputVal('')
  }, [refresh]) 

  let fn = {
    item: function(list, src){
      if(selectedSalesItem.SalesItem[src]?.length < 1){
        return list
      }else{
        let arr = []
        list?.map((salerecord) => {
          // Check if salerecord matches from selectedSalesItem.SalesItem.item
          const statusMatches = selectedSalesItem.SalesItem.item.includes(salerecord.item);
          if(statusMatches){
            arr.push(salerecord)
          }
        })
        return arr
      }
    },
    code: function(list, src){
      if(selectedSalesItem.SalesItem[src]?.length < 1){
        return list
      }else{
        let arr = []
        list?.map((salerecord) => {
          // Check if salerecord matches from selectedSalesItem.SalesItem.code
          const statusMatches = selectedSalesItem.SalesItem.code.includes(salerecord.code);
          if(statusMatches){
            arr.push(salerecord)
          }
        })
        return arr
      }
    },
    location: function(list, src){
      if(selectedSalesItem.SalesItem[src]?.length < 1){
        return list
      }else{
        let arr = []
        list?.map((salerecord) => {
          // Check if multiTimes is either true or false
          const statusMatches = selectedSalesItem.SalesItem.location.includes(salerecord.location);
          if(statusMatches){
            arr.push(salerecord)
          }
        })
        return arr
      }
    },
    createdAt_end: function(list, src){
      if(selectedSalesItem.SalesItem['createdAt_start']?.length < 1 || selectedSalesItem.SalesItem['createdAt_end']?.length < 1){
        return list
      }else{
        let arr = []
        list?.map((salerecord) => {
          // Check if multiTimes is either true or false
          const statusMatches = Number(dayjs(selectedSalesItem.SalesItem.createdAt_start[0]).format("YYYYMMDD")) <= Number(dayjs(salerecord.createdAt).format("YYYYMMDD"));
          const statusMatches_ =  Number(dayjs(selectedSalesItem.SalesItem.createdAt_end[0]).format("YYYYMMDD")) >= Number(dayjs(salerecord.createdAt).format("YYYYMMDD"));
          if(statusMatches && statusMatches_){
            arr.push(salerecord)
          }
        })
        return arr
      }
    },
    surfdate_end: function(list, src){
      if(selectedSalesItem.SalesItem['surfdate_start']?.length < 1 || selectedSalesItem.SalesItem['surfdate_end']?.length < 1){
        return list
      }else{
        let arr = []
        list?.map((salerecord) => {
          // Check if multiTimes is either true or false
          const statusMatches = Number(dayjs(selectedSalesItem.SalesItem.surfdate_start[0]).format("YYYYMMDD")) <= Number(dayjs(salerecord.surfdate).format("YYYYMMDD"));
          const statusMatches_ =  Number(dayjs(selectedSalesItem.SalesItem.surfdate_end[0]).format("YYYYMMDD")) >= Number(dayjs(salerecord.surfdate).format("YYYYMMDD"));
          if(statusMatches && statusMatches_){
            arr.push(salerecord)
          }
        })
        return arr
      }
    }
  }

  // sort by sales property
  const SortBySalesProperty = () => {
    try {
      const getToken = localStorage.getItem("token");
      const getUserInfo = localStorage.getItem("userInfo")
      if (getToken !== null && getUserInfo != null) {
        if (JSON.parse(getUserInfo).role === "superadmin") {
            axios
              .get(`${endpoints.GETSALESBYFIELDS}?query=${searchByParam}`,
                {
                  headers: {
                    Authorization: `Bearer ${getToken}`,
                  },
                }
              )
              .then((response) => {
                if (response.data.status === "True") {
                  setSalesRecord(response.data.sales)
                  setPrimarySalesList(response.data.sales)
                  // update total revenue, new subs, churned as well
                  let toalRevenue = 0
                  let newSubs = 0
                  let churned = 0
                  response?.data?.sales?.map((salesRecord, idx) => {
                    // for revenue
                    if(salesRecord?.revenue){
                      toalRevenue+= salesRecord?.revenue
                    }
                    // calculate month and new sub
                    if(salesRecord?.item && salesRecord?.item === "per-month"){
                      newSubs += 1
                    }
                    // calculate churned
                    if(salesRecord?.item && salesRecord?.item === "canc sub"){
                      churned += 1
                    }

                  })
                  // set subs records
                  setSubsRecord({
                    ...subsRecord,
                    "newsubs": newSubs,
                    "churned": churned
                  })
                  // set total revenue
                  setTotalRevenue(toalRevenue?.toFixed(2))
                }
              })
              .catch((error) => {
                console.log(error)
              });
            }
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  }

  const [needToSearch, setNeedToSearch] = useState(false)
  const handleForGetAllSalesIndvProperty = (sortByProperty) => {
    if(sortByProperty === searchByParam){
      let allSales_rev = primarySalesList.reverse()
      let allSales_revUp = allSales_rev.map(sale => sale)
      setSalesRecord(allSales_revUp)
      // update total revenue, new subs, churned as well
      let toalRevenue = 0
      let newSubs = 0
      let churned = 0
      allSales_revUp?.map((salesRecord, idx) => {
        // for revenue
        if(salesRecord?.revenue){
          toalRevenue+= salesRecord?.revenue
        }
        // calculate month and new sub
        if(salesRecord?.item && salesRecord?.item === "per-month"){
          newSubs += 1
        }
        // calculate churned
        if(salesRecord?.item && salesRecord?.item === "canc sub"){
          churned += 1
        }

      })
      // set subs records
      setSubsRecord({
        ...subsRecord,
        "newsubs": newSubs,
        "churned": churned
      })
      // set total revenue
      setTotalRevenue(toalRevenue?.toFixed(2))
    }else{
      setSearchByParam(sortByProperty)
      setNeedToSearch(!needToSearch)
    }
    // need to reset checkboxes
    removeAllFiltersHandler()
    setSearchInputVal('') 
  }

  useEffect(() => {
    SortBySalesProperty()
  }, [needToSearch])


  // search by email 
  const handleInputChange = (e) => {
    setSearchInputVal(e.target.value)
  };

  const inputOnclickHandler = () => {
    setQuery(searchInputVal);
    // reset selected check boxes
    removeAllFiltersHandler()
    // also clear field sort state
    setSearchByParam('')
  }
  // Debounce the user input to reduce the number of API requests
  const debouncedSearch = useDebouncedCallback(
    // Your search function that makes the API request
    async (searchQuery) => {
      try {
        const getToken = localStorage.getItem("token");
        const getUserInfo = localStorage.getItem("userInfo")
        if (getToken !== null && getUserInfo != null) {
          if (JSON.parse(getUserInfo).role === "superadmin") {
              axios
                .get(`${endpoints.SEARCHSALESRECORD}?query=${searchQuery}`,
                  {
                    headers: {
                      Authorization: `Bearer ${getToken}`,
                    },
                  }
                )
                .then((response) => {
                  if (response.data.status === "True") {
                    setSalesRecord(response.data.sales)
                    // update total revenue, new subs, churned as well
                    let toalRevenue = 0
                    let newSubs = 0
                    let churned = 0
                    response?.data?.sales?.map((salesRecord, idx) => {
                      // for revenue
                      if(salesRecord?.revenue){
                        toalRevenue+= salesRecord?.revenue
                      }
                      // calculate month and new sub
                      if(salesRecord?.item && salesRecord?.item === "per-month"){
                        newSubs += 1
                      }
                      // calculate churned
                      if(salesRecord?.item && salesRecord?.item === "canc sub"){
                        churned += 1
                      }

                    })
                    // set subs records
                    setSubsRecord({
                      ...subsRecord,
                      "newsubs": newSubs,
                      "churned": churned
                    })
                    // set total revenue
                    setTotalRevenue(toalRevenue?.toFixed(2))
                  }
                })
                .catch((error) => {
                  console.log(error)
                });
              }
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    },
    // Debounce delay in milliseconds
    100
  );

  useEffect(() => {
    debouncedSearch(query);
  }, [query, debouncedSearch]);

  // remove all filters
  const removeAllFiltersHandler = () => {
    setSelectedSalesItem({
      SalesItem: {
        item: [],
        code: [],
        location: [],
        createdAt_start: [],
        createdAt_end: [],
        surfdate_start: [],
        surfdate_end: []
      }
    })
    // clear search stack bucket
    setSearchStack([])
    // clear checkbox too
    const itemCheckBoxes =  document.querySelectorAll('[name="item"]');
    const codeCheckBoxes = document.querySelectorAll('[name="code"]');
    const locationCheckbox =  document.querySelectorAll('[name="location"]');

    for (let idx = 0; idx < itemCheckBoxes.length; idx++) {
      if(itemCheckBoxes[idx].checked){
        itemCheckBoxes[idx].checked = false
      }
    }

    for (let idx = 0; idx < codeCheckBoxes.length; idx++) {
      if(codeCheckBoxes[idx].checked){
        codeCheckBoxes[idx].checked = false
      }
    }

    for (let idx = 0; idx < locationCheckbox.length; idx++) {
      if(locationCheckbox[idx].checked){
        locationCheckbox[idx].checked = false
      }
    }
  }
  

  return (
    <>
    <div className={`${tb.bodyBackgound}`}>
    <Header/>
      <div className={`${tb.userManagement}`}>
        <div className="container">
          <div className={`${tb.userSalesBox}`}>
            <div className={`${tb.Heading}`}>
              <h3>SALES</h3>
            </div>
            <div className={`${tb.totalUser}`}>
              <div className={`${tb.boxUser}`}>
                <h4>TOTAL REVENUE</h4>
                <div className={`${tb.userCount}`}>
                  <span>€{totalRevenue}</span>
                </div>
              </div>
              <div>
                <h3>SUBSCRIPTIONS</h3>
                <div className={`${tb.boxUser}`}>
                  <ul>
                    <li>
                      <h4>ACTIVE <br></br> <text>AS OF TODAY</text></h4>
                      <span>{numberFormatter(subsRecord.activeaot)}</span>
                    </li>
                    <li>
                      <h4><text className={`${tb.smallText}`}>NEW</text></h4>
                      <span>{numberFormatter(subsRecord.newsubs)}</span>
                    </li>
                    <li>
                      <h4><text className={`${tb.smallText}`}>CHURNED</text></h4>
                      <span>{numberFormatter(subsRecord.churned)}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={`${tb.filterRemove}`}>
              <span onClick={() => {
                removeAllFiltersHandler()
                // call getSales for latest records
                getSales()
                // also clear field sort state
                setSearchByParam('')
              }}>REMOVE ALL FILTERs   <span>X</span></span>
              <div className={`${tb.filterRemove}`}>
                <input type="search" placeholder="USER EMAIL" value={searchInputVal} onChange={handleInputChange}/>
                <button onClick={inputOnclickHandler}>Search</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className={`${tb.tabbingSection}`}>
        <div className="container">
          <div className={`${tb.tableResponsive}`}>
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th onClick={() => handleForGetAllSalesIndvProperty("email")}>USER EMAIL</th>
                  <th><span onClick={() => handleForGetAllSalesIndvProperty("createdAt")}>DATE</span> 
                  <Dropdown className={`${tb.dropDownFilter}`}>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <FiFilter className={`${tb.filterIcon}`} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <ul className={`${tb.dateFilter}`}>
                        <li>
                          <label> From</label>
                          <input type="date" name="create_at_start" onChange={(e) => setCreateAtStart(e.target.value)}/>
                        </li>
                        <li>
                          <label> TO</label>
                          <input type="date" name="create_at_end" onChange={(e) => setCreateAtEnd(e.target.value)}/>
                        </li>
                        <li>
                          <button onClick={createAtSelectorHandler}>Submit</button>
                        </li>
                      </ul>
                    </Dropdown.Menu>
                  </Dropdown>
                  </th>
                  <th><span onClick={() => handleForGetAllSalesIndvProperty("item")}>ITEM</span> 
                  <Dropdown className={`${tb.dropDownFilter}`}>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <FiFilter className={`${tb.filterIcon}`} />
                    </Dropdown.Toggle>

                  {itemCategory.length &&
                    <Dropdown.Menu>
                      <ul>
                        {itemCategory.map((item, key) => (
                          <li key={key}><label><input type="checkbox" value={item} name="item" onChange={handleChangeCheckbox}/> {item}</label></li>
                        ))}
                      </ul>
                    </Dropdown.Menu>
                  }
                  </Dropdown>
                  </th>
                  <th onClick={() => handleForGetAllSalesIndvProperty("revenue")}>REVENUE</th>
                  <th onClick={() => handleForGetAllSalesIndvProperty("net")}>NET €</th>
                  <th>CODE 
                    <Dropdown className={`${tb.dropDownFilter}`}>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <FiFilter className={`${tb.filterIcon}`} />
                    </Dropdown.Toggle>
                    {codeCategory.length && 
                      <Dropdown.Menu>
                        <ul>
                          {codeCategory.map((code, key) => (
                            <li key={key}><label><input type="checkbox" value={code} name="code"  onChange={handleChangeCheckbox}/> {code}</label></li>
                          ))}
                        </ul>
                      </Dropdown.Menu>
                    }
                  </Dropdown>
                  </th>
                  <th><span onClick={() => handleForGetAllSalesIndvProperty("location")}>LOCATION</span> 
                    <Dropdown className={`${tb.dropDownFilter}`}>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <FiFilter className={`${tb.filterIcon}`} />
                    </Dropdown.Toggle>

                    {locationCategory.length && 
                      <Dropdown.Menu>
                        <ul>
                          {locationCategory.map((location, key) => (
                            <li><label><input type="checkbox" value={location} name="location"  onChange={handleChangeCheckbox}/> {location}</label></li>
                          ))}
                        </ul>
                      </Dropdown.Menu>
                    }
                  </Dropdown>
                  </th>
                  <th><span onClick={() => handleForGetAllSalesIndvProperty("surfdate")}>SURF DATE</span> 
                    <Dropdown className={`${tb.dropDownFilter}`}>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <FiFilter className={`${tb.filterIcon}`} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <ul className={`${tb.dateFilter}`}>
                        <li>
                          <label> From</label>
                          <input type="date" name="surfdate_start" onChange={(e) => setSurfDateStart(e.target.value)}/>
                        </li>
                        <li>
                          <label> TO</label>
                          <input type="date" name="surfdate_end" onChange={(e) => setSurfDateEnd(e.target.value)}/>
                        </li>
                        <li>
                          <button onClick={surfDateSelectorHandler}>Submit</button>
                        </li>
                      </ul>
                    </Dropdown.Menu>
                  </Dropdown>
                  </th>
                </tr>
              </thead>

              <tbody>
               {salesRecord && salesRecord.map((record, idx) => (
                <tr key={idx}>
                  <td>{++idx}</td>
                  <td>{record.email}</td>
                  <td>{new Date(record.createdAt).toLocaleDateString('pt-PT') || '--'}</td>
                  <td>{record?.item || '--'}</td>
                  <td>{record.revenue?.toFixed(2) || '--'}</td>
                  <td>{record.net?.toFixed(2) || '--'}</td>
                  <td>{record?.code}</td>
                  <td>{record?.location || '--'}</td>
                  <td>{record?.surfdate ? new Date(record.surfdate).toLocaleDateString('pt-PT') : '--'}</td>
                </tr>
               ))} 
                {/* <tr>
                  <td>1</td>
                  <td>JOAOCARLOS@GMAIL.COM</td>
                  <td>11/02/2024</td>
                  <td>WAVE</td>
                  <td>€4,99</td>
                  <td>€4,06</td>
                  <td>WAVE20</td>
                  <td>TARQUINIO</td>
                  <td>11/02/2024</td>
                </tr> */}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>


    </>
  );
}
