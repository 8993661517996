import { Button, Grid } from "@mui/material";
import { styled } from "@mui/system";
import dayjs from "dayjs";
import React,{ useEffect, useState } from "react";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import { apolloClient } from "../../..";
import { graphql } from "../../../gql";
import { SpanInfo, SpanType } from '../../../gql/graphql';
import styles from "./TimeAndPlaceSelector.module.css";
import styles4 from "../Checkout/Checkout.module.css"
import watchStyles from "../../../pages/AppLayout.module.css";
import axios from "axios";
import { endpoints } from "../../../Api/Api";

import moment from "moment"
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isToday from 'dayjs/plugin/isToday';



dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isToday);

const COUNT_WAVES = graphql(/* GraphQL */ `
query countWaves($spanQuery: WaveSpansQuery!) {
  waves {
    getWavePeriods(spanQuery: $spanQuery) {
      startTime
      endTime
      count
    } 
  }
}
`);

const availableDates = () => {
  const dates: Array<Partial<SpanInfo>> = []
  const eodToday = dayjs.utc().hour(23).minute(59).second(59).millisecond(0);
  dates.push({
    startTime: eodToday.hour(0).minute(0).second(0).toDate(),
    endTime: eodToday.toDate()
  })
  for (let i = 1; i < 8; i++) {
    const tmDate = eodToday.subtract(i, 'day');
    dates.push({
      startTime: tmDate.hour(0).minute(0).second(0).toDate(),
      endTime: tmDate.toDate()
    })
  }
  return dates
}


export const loadDaySelectorData = async ({ params }) => {

  const shownDates = availableDates();

  const eodToday = dayjs().utc().hour(23).minute(59).second(59).millisecond(0);
  const startTime = eodToday.subtract(8, 'days');
  const query = await apolloClient.query({
    query: COUNT_WAVES, variables: {
      spanQuery: {
        location: params.location,
        type: SpanType.Day,
        endTime: eodToday.toDate(),
        startTime: startTime.toDate()
      }
    }
  });

  const periods = query.data.waves.getWavePeriods;
  
  const ret: Array<Partial<SpanInfo>> = shownDates.map(element => {
    const extraInfo = periods.find(p => {
      const a = p.startTime;
      const b = element.startTime
      return a.getTime() == b.getTime();
    });
    if (extraInfo) {
      return {
        ...element,
        count: extraInfo.count
      };
    } else {
      return element;
    }
  });
  return ret;
  // return periods
}


type DateButtonParams = {
  date: Partial<SpanInfo>,
  onClick?: () => void,
  passName?: string,
  buyedDates?: string
}

const MUIDateButton = styled(Button)`
      &&.Mui-disabled {
        color: #707070;
      }
      width: 100%;
      margin-bottom: 1rem;
      border-radius: 0;
      font-size: 1.5rem;
      line-height: 1.5rem;
      padding: 1rem 0;
      background-color: rgba(18, 24, 33, 0.41);
      font-family: "exo 2", sans-serif;
      display: flex;
      flex-direction: column;
    ${props => props.theme.breakpoints.down(1000)} {
        font-size: 0.8rem;      
      }
  `;

const DateButton = ({ date, onClick: onSelect, passName, buyedDates  }: DateButtonParams) => {
  let PassVariant = "none"
  const passType = passName
  let isDateExist = false
  if(buyedDates){
    const cnvtD = moment(date?.startTime).format("YYYYMMDD")
    if(buyedDates[cnvtD]){
      isDateExist = true
    }
    if(buyedDates[cnvtD] == "month"){
      PassVariant = "gold"
    }
  }

  return (
    <MUIDateButton
      className={isDateExist === true && date.count != undefined ? passType == "All" || PassVariant === "gold" ? `${watchStyles.buttonBorderColor2}` :`${watchStyles.buttonBorderColor1} ` :`${watchStyles.defalutBtn}`}
      variant="contained"
      disableElevation
      disabled={date.count == undefined}
      onClick={() => {
        if (onSelect != undefined) {
          onSelect();
        }
      }}
    >
      <div style={{
        display: 'flex',
        justifyContent: 'space-around',
        width: '100%'
      }}>

        <div style={{
          width: '100px'
        }} >{dayjs(date.startTime).isToday() ? 'Today' : ''}</div>

        <div style={{
          display: 'flex',
          flexDirection: 'column',
        }}>
          <span >{dayjs(date.startTime).format('dddd')}</span>
          <span >{dayjs(date.startTime).format('MMMM D')}</span>

        </div>
        <div style={{
          width: '100px'
        }}>{date.count != undefined ? `${date.count} waves` : 'no waves'}</div>
      </div>

      {isDateExist === true && date.count != undefined ? passType == "All" || PassVariant === "gold" ? 
        <div className={`${watchStyles.DayPass}`}>
          <span>UNLIMITED</span>
        </div> 
        :<div className={`${watchStyles.DayPass}`}>
          <span>DAY PASS</span>
        </div>  
        :<></>
      }
    </MUIDateButton> 
  )

  // blue color border
  // return (
  //   <MUIDateButton
  //     className={`${watchStyles.buttonBorderColor1}`} 
  //     variant="contained"
  //     disableElevation
  //     disabled={date.count == undefined}
  //     onClick={() => {
  //       if (onSelect != undefined) {
  //         onSelect();
  //       }
  //     }}
  //   >
  //     <div style={{
  //       display: 'flex',
  //       justifyContent: 'space-around',
  //       width: '100%'
  //     }}>

  //       <div style={{
  //         width: '100px'
  //       }} >{dayjs(date.startTime).isToday() ? 'Today' : ''}</div>

  //       <div style={{
  //         display: 'flex',
  //         flexDirection: 'column',
  //       }}>
  //         <span >{dayjs(date.startTime).format('dddd')}</span>
  //         <span >{dayjs(date.startTime).format('MMMM D')}</span>

  //       </div>
  //       <div style={{
  //         width: '100px'
  //       }}>{date.count != undefined ? `${date.count} waves` : 'no waves'}</div>
  //     </div>

  //     <div className={`${watchStyles.DayPass}`}>
  //       <span>DAY PASS</span>
  //     </div>
  //   </MUIDateButton>
  // )
  
  // yellow color border
  // return (
  //   <MUIDateButton
  //     className={`${watchStyles.buttonBorderColor2}`} 
  //     variant="contained"
  //     disableElevation
  //     disabled={date.count == undefined}
  //     onClick={() => {
  //       if (onSelect != undefined) {
  //         onSelect();
  //       }
  //     }}
  //   >
  //     <div style={{
  //       display: 'flex',
  //       justifyContent: 'space-around',
  //       width: '100%'
  //     }}>

  //       <div style={{
  //         width: '100px'
  //       }} >{dayjs(date.startTime).isToday() ? 'Today' : ''}</div>

  //       <div style={{
  //         display: 'flex',
  //         flexDirection: 'column',
  //       }}>
  //         <span >{dayjs(date.startTime).format('dddd')}</span>
  //         <span >{dayjs(date.startTime).format('MMMM D')}</span>

  //       </div>
  //       <div style={{
  //         width: '100px'
  //       }}>{date.count != undefined ? `${date.count} waves` : 'no waves'}</div>
  //     </div>

  //     <div className={`${watchStyles.DayPass}`}>
  //       <span>UNLIMITED</span>
  //     </div>
  //   </MUIDateButton>



    
  // )
}

export const DaySelector = () => {
  const availableDates = useLoaderData() as Array<Partial<SpanInfo>>;
  const navigate = useNavigate();
  const params = useParams();
  const {location} = params
  const [forExistingWavesPopup, setForExistingWavesPopup] = useState(false)
  const [selectedDate, setSelectedDate] = useState<any>({})
  const [activePass, setActivePass] = useState<any>()
  const [allDates, setAllDates] = useState()
  const [passType, setPassType] = useState()


  const waveItems = JSON.parse(localStorage.getItem("waves")) || {}
  const selectedWavesData = Object.keys(waveItems).length

  useEffect(() => {
    const customertoken = localStorage.getItem("token")
    axios.get(endpoints.GET_ACTIVE_PASS,
    {
      headers: {
        Authorization: `Bearer ${customertoken}`,
      },
    }
    ).then((response) => {
      setActivePass(response?.data)
      setAllDates({...response?.data?.data[location],...response?.data?.data["All"]})
      setPassType(response?.data?.passType)
    })
      .catch((err) => {
        console.log(err, "eror while get pass info!!")
    })

  }, [])

  let dateFromLocal = JSON.parse(localStorage.getItem("date"))
  if(dateFromLocal){
    const day = dateFromLocal.substring(6,8)
    const month = dateFromLocal.substring(4,6)
    const year = dateFromLocal.substring(0,4)
    const resDate = `${day}-${month}-${year}`
    dateFromLocal = resDate
  }else{
    dateFromLocal = ""
  }


  const handleForPopup = (date:any) => {
    let DateFormat = dayjs(date?.startTime).format('YYYYMMDD')
    const locationFromLocal = JSON.parse(localStorage.getItem("location"))
    const dateFromLocal = JSON.parse(localStorage.getItem("date"))
    if((location === locationFromLocal && DateFormat === dateFromLocal) || (locationFromLocal === null || dateFromLocal === null)){
      // no popup but navigate to the next router
      navigate(`day/${DateFormat}`)
    }else if(selectedWavesData <= 0){
      // no popup but navigate to the next router
      navigate(`day/${DateFormat}`)
    }else{
      setSelectedDate(date)
      setForExistingWavesPopup(true)
    }  
  }
  return (
    <div className={watchStyles.watch}>
      <div className={watchStyles.watchContainer}>
        <div className={styles.HeadingContainer}>
          <Button
            className={`${styles["button-title-btn"]} ${styles.HeaderFirst}`}
            onClick={() => navigate(-1)}>
            <MdOutlineArrowBackIosNew />
            Location
          </Button>
          <h2 className={styles.header}>When did you surf?</h2>
          <div className={styles.HeaderLast}></div>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            {availableDates.slice(0, 4).map((date, idx) => (
              <DateButton key={'db' + idx} date={date} onClick={() => handleForPopup(date)} passName={passType} buyedDates={allDates}
              />
            ))}
          </ Grid>
          <Grid item xs={12} md={6}>
            {availableDates.slice(4, 8).map((date, idx) => (
              <DateButton key={'db' + idx} date={date} onClick={() => handleForPopup(date)} passName={passType} buyedDates={allDates}
              />
            ))}
          </ Grid>
        </Grid>
      </div>

    {forExistingWavesPopup == true && selectedWavesData >= 0?
      <div className={`${styles4.modal}`}>
      <div className={`${styles4.blur}`}></div>
      <div className={`${styles4.boxWhite}`}>
          <h2>ARE YOU SURE?</h2>
          <div className={`${styles4.bgColor}`}>
              <h3>YOU WILL LOSE THE WAVES YOU'VE SELECTED, FROM {JSON.parse(localStorage.getItem("location"))} ON {dateFromLocal}</h3>
              <div className={`${styles4.btnAll}`}>
              <a className={`${styles4.btn}`} onClick={() => {
                navigate(`day/${dayjs(selectedDate?.startTime).format('YYYYMMDD')}`)
                localStorage.removeItem("waves")
                localStorage.removeItem("date")
                localStorage.removeItem("location")

               }
              }>YES</a>
              <a className={`${styles4.btn} ${styles4.active}`} 
              onClick={() => setForExistingWavesPopup(false)}
              >NO</a>
          </div>
          </div>
      </div>
      </div>
      : <></>}
    </div>
  )
}
