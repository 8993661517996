import React,{ useState, useEffect } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import Form from 'react-bootstrap/Form';
import md from "./modal.module.scss";
import Select, { components } from 'react-select';
import { IoMdCheckmark } from "react-icons/io";
import { FaRegCheckSquare } from "react-icons/fa";
import { IoIosSquareOutline } from "react-icons/io";
import dayjs from "dayjs";
import axios from "axios";
import { endpoints } from '../../../Api/Api';





const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '15px',
  boxShadow: 24,
  height: "auto",
  border: 'none',
  p: 4,
};

const defaultFormData = {
  title: "",
  discount: "",
  expiresAt: "",
  isFlat: false,
  isSingle: false,
  isMultipleUser: true,
  applicableto: '',
  status: true
}

export default function UpdateCouponModal({ open, handleClose, couponDetails, needToRerender }) {
  const [form, setForm] = useState({
    title: "",
    discount: "",
    expiresAt: "",
    isFlat: false,
    isSingle: false,
    isMultipleUser: true,
    applicableto: '',
    status: true
  });

  const options = [
    { value: 'wave', label: 'WAVE' },
    { value: 'day', label: 'DAY' },
    { value: 'month', label: 'MONTH' },
  ];
  const [expiresAt_l, setExpiresAt_l] = useState('')


  useEffect(() => {
    if(open){
      setForm({
        title: couponDetails.name,
        discount: couponDetails.discount,
        expiresAt: dayjs(couponDetails.expire_at).format("YYYY-MM-DD"),
        isFlat: couponDetails.flatprice,
        isSingle: couponDetails.singleUse,
        isMultipleUser: couponDetails.multiUser,
        applicableto: couponDetails.applicableto,
        status: couponDetails.status
      })
      setExpiresAt_l(dayjs(couponDetails.expire_at).format("YYYY-MM-DD"))
      const couponsapplicable_arr = couponDetails?.applicableto?.split(',')
      const filterSelectedOptions = options?.filter((option, idx) => {
        if(couponsapplicable_arr?.includes(option.value)){
          return option
        }
      })
      setSelectedOptions(filterSelectedOptions)
      form.applicableto = filterSelectedOptions?.map((arrVal, idx) => arrVal.value)?.join(',')
    }

  },[open])

  const handleChangeInput = (e) =>{
    setForm((prev) => ({ ...form, [e.target.name]: e.target.value }));
    if(e.target.name == 'expiresAt'){
      setExpiresAt_l(e.target.value)
    }
  }
    


   const handleUpdateCoupon = async () => {
        const { title, discount, expiresAt, isSingle, isMultipleUser, flatprice, applicableto, status } = form;
        if (title === "" || discount === "" || expiresAt === "") {
            Swal.fire("Fields Cannot be Empty   ", "", "error");
        } else {
            const token = localStorage.getItem("token");
            const formData = new FormData();
            formData.append("id", couponDetails.id);
            formData.append("name", title);
            formData.append("discount", discount);
            formData.append("expire_at", expiresAt);
            formData.append(
            "status",
            status === true ? "True" : "False"
            );
            formData.append("flatprice", flatprice ? 1 : 0);
            formData.append("singleUse", isSingle);
            formData.append("multiUser", isMultipleUser);
            formData.append("applicableto", applicableto);
            // if (isMultipleUser === false && isSingle === true) {
            //   formData.append("type", 1);
            // } else if (isMultipleUser === false && isSingle === false) {
            //   formData.append("type", 2);
            // } else if (isMultipleUser === true && isSingle === false) {
            //   formData.append("type", 3);
            // } else if (isMultipleUser === true && isSingle === true) {
            //   formData.append("type", 4);
            // }
            const response = await axios.post(endpoints.PUT_COUPONS, formData, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            });
            if (response.data.status === "True") {
            Swal.fire(response.data.message, "", "success");
            handleClose();
            needToRerender();
            }
        }
   };

   const handleInactivate = async () => {
    const {id, title, discount, expiresAt, isSingle, isMultipleUser, flatprice, applicableto } = form;
    if (title === "" || discount === "" || expiresAt === "") {
        Swal.fire("Fields Cannot be Empty   ", "", "error");
        return
    }
    const token = localStorage.getItem("token");
    const formData = new FormData();
    formData.append("id", couponDetails.id);
    formData.append("name", title);
    formData.append("discount", discount);
    formData.append("expire_at", expiresAt);
    formData.append("status", "False");
    formData.append("type", '-1');
    formData.append("flatprice", flatprice ? 1 : 0);
    formData.append("singleUse", isSingle);
    formData.append("multiUser", isMultipleUser);
    formData.append("applicableto", applicableto);
    const response = await axios.post(endpoints.PUT_COUPONS, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.data.status === "True") {
      Swal.fire(response.data.message, "", "success");
      // getCoupouns();
      handleClose();
      needToRerender();
    }
   };

   const handleActivate = async () => {
      const {id, title, discount, expiresAt, isSingle, isMultipleUser, flatprice, applicableto } = form;
      if (title === "" || discount === "" || expiresAt === "") {
          Swal.fire("Fields Cannot be Empty   ", "", "error");
          return
      }
      const token = localStorage.getItem("token");
      const formData = new FormData();
      formData.append("id", couponDetails.id);
      formData.append("name", title);
      formData.append("discount", discount);
      formData.append("expire_at", expiresAt);
      formData.append("status", "True");
      formData.append("type", '-1');
      formData.append("flatprice", flatprice ? 1 : 0);
      formData.append("singleUse", isSingle);
      formData.append("multiUser", isMultipleUser);
      formData.append("applicableto", applicableto);
      const response = await axios.post(endpoints.PUT_COUPONS, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.status === "True") {
        Swal.fire(response.data.message, "", "success");
        // getCoupouns();
        handleClose();
        needToRerender();
      }
   };
  

  const MenuList = (props) => {
    const { selectOption } = props;
    return (
      <components.MenuList {...props}>
        {selectedOptions.map((option) => (
          <div
            key={option.value}
            onClick={() => selectOption(option)}
            style={{ cursor: 'pointer', padding: '5px', display: 'flex', alignItems: 'center' }}
          >
            <FaRegCheckSquare style={{ marginRight: '5px' }} />
            <span>{option.label}</span>
          </div>
        ))}
        <components.MenuList {...props}>
          {props?.children?.filter?.(
            (child) => !selectedOptions?.find((option) => option?.value === child?.props?.data?.value)
          )}
        </components.MenuList>
      </components.MenuList>
    );
  };

  const CheckboxOption = ({ children, isSelected, ...props }) => (
    <components.Option {...props}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <IoIosSquareOutline icon={isSelected ? <IoIosSquareOutline /> : ''} style={{ marginRight: '5px' }} />
        <span>{children}</span>
      </div>
    </components.Option>
  );

  const [selectedOptions, setSelectedOptions] = React.useState([]);


  const handleChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
    form.applicableto = selectedOptions.map((arrVal, idx) => arrVal.value).join(',')
    setMenuIsOpen(true);
  };

  const statusHandler = (e) => {
    setForm((prev) => ({ ...form, ['status']: !form.status }));
  }
  const [menuIsOpen, setMenuIsOpen] = useState(false);


  return (
    <div className={`${md.modalOpenStart}`}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ onClick: null }}
      >
        <Fade in={open}>
          <Box sx={style} className={`${md.modalOpenStart}`}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              EDIT CODE
              <button onClick={handleClose}>X</button>
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              <div className={`${md.formStart}`}>
                <Form>
                  <Form.Group className={`${md.formInput}`}>
                    <Form.Control type="text" placeholder="Code Name" name="title" onChange={handleChangeInput}
                    value={form.title}/>
                  </Form.Group>
                  
                  <Form.Group className={`${md.formInput}`}>
                    <Form.Control type="number" placeholder="Discount" onChange={handleChangeInput}
                    value={form.discount} name="discount" />
                    <div className={`${md.inputRight}`}>
                      <span className={`${!form.isFlat ? md.active : ''}`} onClick={(e) => {
                        setForm({ ...form, isFlat: false });
                      }}>%</span>
                      <span className={`${form.isFlat ? md.active : ''}`} onClick={(e) => {
                       setForm({ ...form, isFlat: true });
                       }}>€</span>
                    </div>
                  </Form.Group>

                  <Form.Group className={`${md.formInput}`}>
                  <Select
                    className={`${md.select}`}
                    isMulti
                    options={options}
                    onChange={handleChange}
                    placeholder="Applicable to"
                    value={selectedOptions}
                    components={{ Option: CheckboxOption, MenuList }}
                    menuIsOpen={menuIsOpen} // Set menuIsOpen state here
                    onMenuOpen={() => setMenuIsOpen(true)} // Open menu on arrow click
                    onMenuClose={() => setMenuIsOpen(false)} // Close menu when clicking away
                  />
                  </Form.Group>

                  {/* <Form.Group className={`${md.formInput}`}>
                    <Form.Control type="date" 
                    placeholder="Expiration Date"
                    name="expiresAt"
                    onChange={handleChangeInput}
                    value={form.expiresAt} />
                  </Form.Group> */}
                  <Form.Group className={`${md.formInput} ${md.dateIcon}`}>
                    <Form.Control type="date" placeholder="22/2/2024" value={form.expiresAt} name="expiresAt" onChange={handleChangeInput}/>
                    {Number(dayjs(expiresAt_l).format("YYYYMMDD")) < Number(dayjs().format("YYYYMMDD")) ? (
                      <div className={`${md.inputRight}`}>
                        <button onClick={(e) => {
                          e.preventDefault()
                          // handleInactivate()
                          }}>Expired</button>
                      </div>
                    ) : 
                    (form?.status ? 
                    (
                      <div className={`${md.inputRight}`}>
                        <button name="status" onClick={(e) => {
                          e.preventDefault()
                          // handleInactivate()
                          statusHandler()
                          }}>SET AS INACTIVE</button>
                      </div>
                    ): 
                    (
                      <div className={`${md.inputRight}`}>
                        <button name="status" onClick={(e) => {
                          e.preventDefault()
                          // handleInactivate()
                          statusHandler()
                          }}>SET AS ACTIVE</button>
                      </div>
                    ))}
                    
                    
                  </Form.Group>

                  <Form.Group className={`${md.formInput} ${md.placeHolderColor}`}>
                    <Form.Control type="text" placeholder="Redeemable Multiple times" disabled
                    // value={!form.isSingle}
                    />
                    <div className={`${md.inputRight}`}>
                      <span className={`${!form.isSingle ? md.active: ''}`} onClick={(e) => {
                        setForm({ ...form, isSingle: false });
                      }}>YES</span>
                      <span className={`${form.isSingle ? md.active: ''}`} onClick={(e) => {
                        setForm({ ...form, isSingle: true });
                      }}>NO</span>
                    </div>
                  </Form.Group>

                  <Form.Group className={`${md.formInput} ${md.placeHolderColor}`}>
                    <Form.Control type="text" placeholder="By Multiple Users" disabled 
                    // value={form.isMultipleUser}
                    />
                    <div className={`${md.inputRight}`}>
                      <span className={`${form.isMultipleUser ? md.active : ''}`} onClick={(e) => {
                        setForm({ ...form, isMultipleUser: true });
                      }}>YES</span>
                      <span className={`${!form.isMultipleUser ? md.active : ''}`} onClick={(e) => {
                        setForm({ ...form, isMultipleUser: false });
                      }}>NO</span>
                    </div>
                  </Form.Group>

                  <Form.Group className={`${md.formInputButton}`}>
                    <button onClick={(e) => {
                      e.preventDefault()
                      handleUpdateCoupon()
                    }
                    }>UPDATE CODE</button>
                  </Form.Group>
                </Form>
              </div>
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
