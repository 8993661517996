import axios from "axios";
import { m } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { FaUserAlt } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { api_url, developement_url, endpoints } from "../../../Api/Api";
import Logo from "../../../assets/logo.svg";
import "./Users.styles.scss";
import ReactGA from "react-ga4";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DateTimePicker from 'react-datetime-picker';
// import {Link} from 'react-router-dom';

import {AiOutlineMenu} from 'react-icons/ai';
import 'react-datetime-picker/dist/DateTimePicker.css'
import 'react-calendar/dist/Calendar.css'
import 'react-clock/dist/Clock.css'


ReactGA.initialize("G-9RWTM70QHK");
// Send pageview with a custom path
ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "User Panel" });

const IconHam = ()=>{
  return (
      <div className='hamburger-menu-icon'>
          <AiOutlineMenu size={27}/>
      </div>
  )
}

export function Hamburger({target, children, links,type}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [value, setValue] = React.useState(new Date('2014-08-18T21:11:54'));

  const handleChange = (newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <div
        onClick={handleClick}
      >
       {target||<IconHam/>}
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >{type==='dateIp'?(<div className='videoviewinput'>
         <DateTimePicker className='videoviewinput-input' onChange={handleChange} value={value} />
      </div>):
        links.map((l,id)=><MenuItem sx={{color : '#01243C', paddingX : '40px', borderBottom : '1px solid lightgray' , fontFamily : ['Exo 2','sans-serif'], fontWeight :'bold', textTransform : 'uppercase', fontStyle : 'italic'}} key={id} onClick={handleClose}><Link to={l.href}>{l.title}</Link></MenuItem>)
      
      }
      </Menu>
    </div>
  )
    }

const NavbarItem = ({ title, onClick, link }) => {
  return (
    <div className="users__header__nav-links-link">
      <Link to={link || "#"}>{title}</Link>
    </div>
  );
};

const Header = () => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const handleAdminLogout = () => {
    localStorage.removeItem("userInfo");
    localStorage.removeItem("token");
    navigate("/");
  };


  return (
    <div className="users">
      <div className="container">
        <div className="users__header">
          <div className="headerLogos">
          <Link to="/">
            <div className="users__header__logo">
              <img src={Logo} alt="" />
            </div>
          </Link>
          <Link className="appLink" to="/session">App</Link>
          </div>
          <div className="users__header__nav">
            <div className="users__header__nav__ham" style={{color: '#1f2735'}}>
              {/* <div
                onClick={handleAdminLogout}
                style={{ marginLeft: "10px", marginRight: "10px" }}
              >
                <NavbarItem title="Logout" />
              </div> */}
              <Hamburger
                color="#1f2735"
                links={[
                  { title: "sales", href: "/admin/sales" },
                  { title: "USERS", href: "/admin/users" },
                  { title: "PARTNERS", href: "" },
                  { title: "Coupons", href: "/admin/coupons" },
                  { title: "👤 Admin", href: "/admin/users" },
                ]}
              />
            </div>
            <div className="users__header__nav-links">
            <div>
                <NavbarItem title="sales" link="/admin/sales" />
              </div>
              <div>
                <NavbarItem title="USERS" link="/admin/users" />
              </div>
              <div>
                <NavbarItem title="PARTNERS" />
              </div>
              <div>
                <NavbarItem title="CODES" link="/admin/coupons" />
              </div>
            </div>
            <div className="users__header__nav__user">
              <FaUserAlt />
              <div className="">
                <Link to="/admin/users">Admin</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
