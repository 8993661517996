import React from 'react';
import { SectionLayout } from '../../../layouts/section';
import { LiveVideos } from './live';

export const HeroSection = ({ spot }) => {
  return (
    <SectionLayout relativeId="live">
      <LiveVideos source={spot} />
    </SectionLayout>
  );
};
