import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Outlet } from "react-router-dom";
import { Sidebar2 } from "../components/Session/Sidebar/Sidebar2";
import { CartContextProvider } from "../context/CartContext";
import styles from "./AppLayout.module.css";

export const AppLayout = ({ title }) => {
    const [sidebar, setSidebar] = useState(false);
    return (
        <>
        <CartContextProvider>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{title}</title>
            </Helmet>
            {sidebar && (
                <div
                    onClick={() => setSidebar(false)}
                    className={`${styles.overlay__session__sidebar}${sidebar ? ` ${styles.show}` : ` ${styles.hidden}`
                        }`}
                ></div>
            )}
            <div className={styles.session}>

                <div
                    className={`${styles.session__sidebar}${sidebar ? ` ${styles.show}` : ""}`}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <Sidebar2
                        setSidebar={setSidebar}
                    />
                </div>
                <div
                    className={`${styles.sidebar__open}${sidebar ? ` ${styles.hidden}` : ""}`}
                    onClick={() => setSidebar(true)}
                >
                    <MdKeyboardArrowRight size={40} />
                </div>
                <div className={styles["session-container"]}>
                    {/* <div className={styles.watch}>
                        <div className={styles.watchContainer}> */}
                            {/* <CartContextProvider> */}
                                <Outlet />
                            {/* </CartContextProvider> */}
                        {/* </div>
                    </div> */}

                </div>
            </div>
        </CartContextProvider>
        </>

    );

}