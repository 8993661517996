import React, { useEffect, useState } from "react";
import { MdArrowBack } from "react-icons/md";
import { Link} from "react-router-dom";
import Logo from "../../../assets/logo.svg";
import ContactIcon from "../../../assets/contactIcon.svg";
import PlayIcon from "../../../assets/playIcon.svg";
import SessionIcon from "../../../assets/mysessionIcon.svg";
import LogoutIcon from "../../../assets/logoutIcon.svg";
import myAccount from "../../../assets/myAccount.svg";
import "./Sidebar.styles.scss";
import { useforLogoutBtnClicked } from "../../../utils/hooks";
import { NavLink } from "react-router-dom";
import { MdEmail } from "react-icons/md";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { FaUser } from "react-icons/fa";

import  Profile  from "../Profile/Profile"

const Button = ({
  title,
  id,
  view,
  link,
}) => {
  return (
    <NavLink to={link}>
      <div
        className={`sidebar__nav__button${view === id ? " sidebar__nav__active" : ""
          }`}
      >
        {title}
      </div>
    </NavLink>
  );
};

export const Sidebar2 = ({  setSidebar}) => {
  const handleCustomerLogout = useforLogoutBtnClicked();
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))


  return (
    <div className="sidebar">
      <div onClick={() => setSidebar(false)} className="sidebar__arrow">
        <MdArrowBack size={32} />
      </div>
      <div className="sidebar-container">
        <Link to="/">
          <div className="sidebar__logo">
            <img src={Logo} alt="Logo"></img>
          </div>
        </Link>
        <div className="sidebar__nav">
        <NavLink to="/session" 
            style={{
                display: 'block',
                width: '100%'
            }}
         className={({ isActive }) => {
          return `sideBar_iconSet sidebar__nav__button${isActive ? " sidebar__nav__active" : ""}`;
         }}>Watch
         <img className="sidebar_icon" src={PlayIcon} alt="Logo"></img>
         </NavLink>
        <NavLink to="/mysession" 
            style={{
                display: 'block',
                width: '100%'
            }}
         className={({ isActive }) =>` sideBar_iconSet sidebar__nav__button${isActive ? " sidebar__nav__active" : ""}`}>My Sessions
         <img className="sidebar_icon" src={SessionIcon} alt="Logo"></img>
         </NavLink>

         <NavLink to="/profile" 
            style={{
                display: 'block',
                width: '100%'
            }}
         className={({ isActive }) =>` sideBar_iconSet sidebar__nav__button${isActive ? " sidebar__nav__active" : ""}`}>MY ACCOUNT
         <img className="sidebar_icon" src={myAccount} alt="Logo"></img>
         </NavLink>

        </div>
        <div className="sidebar__buttons">
          {userInfo?.role === "superadmin" ?
            (<NavLink to="/admin/users">
              <div
                className={`sidebar__nav__button sidebar__nav__button-logout sideBar_iconSet`}>
                ADMIN PANEL
                <FaUser />
              </div>
            </NavLink>) : <></>}

          <div className="socialIconShow">
            <div className="hoverBody">
              <div className="hoverBodyIn">
                <div className="emailIcon">
                  <NavLink to="mailto:info@surfeye.video" className="btn actionBtn">
                      <MdEmail />
                      <h6>EMAIL</h6>
                  </NavLink>
                </div>
                <div className="emailIcon">
                  <NavLink to="https://wa.me/351934673643/" target="_blank" className="btn actionBtn">
                      <AiOutlineWhatsApp />
                      <h6>WHATSAPP</h6>
                  </NavLink>
                </div>
              </div>
            </div>
            <div className={`sidebar__nav__button sidebar__nav__button-contact`}>
              <a className="sideBar_iconSet">Contact US
              <img className="sidebar_icon" src={ContactIcon} alt="Logo"></img>
              </a>
            </div>
          </div>
          <div
            className={`sidebar__nav__button sidebar__nav__button-logout sideBar_iconSet`}
            onClick={handleCustomerLogout}
          >
            Logout
            <img className="sidebar_icon" src={LogoutIcon} alt="Logo"></img>
          </div>
        </div>
      </div>
    </div>
  );
};
