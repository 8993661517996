import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { developement_url } from "../../Api/Api";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import "./TermsAndConditions.styles.scss";
import ReactGA from "react-ga4";


ReactGA.initialize("G-9RWTM70QHK");
// Send pageview with a custom path
ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Privacy Policy1" });

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  useEffect(() => {
    const getToken = localStorage.getItem("token");
    const getUserInfo = localStorage.getItem("userInfo");
    if (getToken !== null) {
      if (JSON.parse(getUserInfo).role === "customer") {
        navigate("/session");
      } else {
        navigate("/admin/coupons");
      }
    }
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>PRIVACY POLICY</title>
        {/* <link rel="canonical" href={`${developement_url}privacypolicy`} /> */}
      </Helmet>
      <Navbar home={false} />
      <div className="toc-container">
        <div className="toc-heading">SURF EYE PRIVACY NOTICE</div>
        <div className="toc-content">
          <p>
          <b>SURF EYE LDA</b>. (hereinafter <b> “Surf Eye”, “We”, “Our”, “Us” </b>) is the entity responsible for operating the cameras that will capture a specific area of the sea in certain beaches. Surf Eye is also the entity responsible for operating the website https://www.surfeye.video/, a Platform that allows users who so desire and who position themselves in the filmed area to buy the videos, generated by the camaras, of each wave session they have surfed on a given day and time (hereinafter “Website”). Surf Eye through the website also offers its users a premium personalized Surf Eye Coaching service that allows users to have feedback from a professional coach (hereinafter jointly referred as “Services”).
          </p>
          <p>
          In this document, the user of the website (hereinafter “User”, “Data Subject”, “You”) may find information pertaining the processing of personal data that is carried out by Surf Eye in the performance of its business.
          </p>
          <p>Please learn more on the answers to the following questions:</p>

          <ul>
            <li>How Is Personal Data Collected And Processed Within The Surf Eye Services?</li>
            <li>Who Is The Data Controller?</li>
            <li>What Are The Purposes And Legal Grounds For Processing Your Personal Data?</li>
            <li>How Long Do We Retain Your Personal Data?</li>
            <li>To Whom Do We Transfer Your Personal Data?</li>
            <li>What Rights Do The Data Subject Have?</li>
          </ul>

          <p className="toc-bold">I. How is personal data collected and processed within the Surf Eye services?</p>

          <p>Surf Eye services function on the basis of a camera that is installed on some beaches along the coast in order to capture a specific area of the sea, which will be identified and signaled on a notice in the place where the camera is located. The cameras will only record the exact area of the sea marked, <b>not covering any other area</b> and in most cases, the cameras will be active between 08:00 and 18:00.</p>
          <p>The purpose of using these cameras is to give users who so desire and who place themselves in the marked recording area, the possibility to purchase the videos in which they appear surfing so that they can improve their technique or just watch their performance that day. In case the videos are not purchased, the images will be <b> deleted after 7 days from the date of capture</b>.</p>
          <p>Surf Eye cameras are developed using Artificial Intelligence systems enabling to identify when a surfer catches a wave and to record the entire wave and performance of that surfer. Notwithstanding, Surf Eye cameras generate <b> videos of low resolution</b>, <b>thus not allowing the clear identification of faces and people in general</b>.</p>
          <p>Surf Eye will store the images so that surfers can access the website, register or log in, select the beach where they have been surfing and the respective time and buy the corresponding video if so desired. In this regard, the images captured by the cameras <b> will only be accessed and viewed by people who purchase the respective images</b>.</p>
          <p>If you were in the filming area and do not want the video to be made available for sale, please send us an email to <a href="mailto: info@surfeye.video"> info@surfeye.video</a> and we will delete the corresponding images immediately.</p>
          <p>Surf Eye also offers the Surf Eye Coaching service that allows users to get professional feedback by analyzing the videos captured.</p>
          
          
          
          <p className="toc-bold">II.  Who is the Data Controller?</p>
          <p>The Controller of your personal data is Surf Eye Lda., with registered office at Costa do Castelo 45, Lisboa 1100-179 Portugal and with legal person identification number 517387573.</p>
          <p>You can contact us via email at <a href="mailto: info@surfeye.video"> info@surfeye.video</a>.</p>


          <p className="toc-bold">III.   What are the purposes and legal grounds for processing your personal data?</p>
          <p>We will process your personal data for the following purposes and based on these legal grounds:</p>

              <div className="table-responsive">
                <table>
                  <thead>
                    <tr>
                      <th>PURPOSE</th>
                      <th>LEGAL BASIS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Collecting images</td>
                      <td>Our legitimate interest in providing our services and to offer the possibility for surfers who so desire and who position themselves in the marked area, to buy the videos and watch the waves they have surfed, so that they can improve their technique or just watch their performance that day.</td>
                    </tr>

                    <tr>
                      <td>Within the scope of the operation of our Website, and in order to be able to provide you with our services, we will process the personal data that you provide us with for the following purposes:
                        <ul>
                          <li>User account creation.</li>
                          <li>Selling the videos.</li>
                        </ul>
                      </td>
                      <td>Performance of a contract to which the data subject is party.</td>
                    </tr>

                    <tr>
                      <td>Manage your library of saved videos/sessions</td>
                      <td>Performance of a contract to which the data subject is party.</td>
                    </tr>

                    <tr>
                      <td>Payment processing, billing and administrative management</td>
                      <td>Performance of a contract to which the data subject is a party and the fulfillment of legal obligations to which we are bound.</td>
                    </tr>
                    
                    <tr>
                      <td>Provide the premium personalized Surf Eye Coaching service if you acquire it</td>
                      <td>Performance of a contract to which the data subject is a party.</td>
                    </tr>

                    <tr>
                      <td>Comply with legal obligations</td>
                      <td>Processing is necessary for compliance with a legal obligation to which we are subject.</td>
                    </tr>

                    <tr>
                      <td>Sharing your waves on social media</td>
                      <td>If you tick the applicable check box before proceeding with the purchase of the videos and, as such, by providing us with your consent to do so, we may share one of your waves that has been filmed through our services on our social media channels.

                        <p>If you tag us on social media (for example in a story) we will repost your post on our social media page on the legal basis of the consent that you have given us by tagging us in your post and/or story.</p>
                      </td>
                    </tr>

                    <tr>
                      <td>Sending commercial communications about our services</td>
                      <td>In case you are not yet our Customer:

                        <p>In order to send you commercial communications through e-mail  related to our activity, you must provide us with your consent to do so. In this case, the legal basis applicable to the processing of your personal data will be your consent . This consent may be revoked at any time, without this, however, making unlawful the processing of personal data carried out on the basis of this consent until the date on which it is revoked. In this case, failure to provide your consent will prevent us from sending you commercial communications related to our activity.</p>
                      </td>
                    </tr>

                    <tr>
                      <td>In case you are our Customer:</td>
                      <td>If you are already our Customer, we want to keep you updated at all times on news related to our services. Therefore, unless you object, we will send you commercial communications via e-mail  related to our products and services. The legal basis applicable to the processing of your personal data is our legitimate interest in keeping you updated on the latest news about our products and services and in this sense being able to provide you with a quality service.</td>
                    </tr>

                    <tr>
                      <td>Provide technical assistance and for the purpose of responding to requests, inquiries, or complaints</td>
                      <td>Our legitimate interest in providing you with a quality and personalized service, managing the complaints we receive as applicable.</td>
                    </tr>
                  </tbody>
                </table>

              </div>

              <p>If the legal basis for the processing of your personal data is consent or legitimate interest, you may respectively withdraw your consent or object to the processing at any time, without any consequences, but without compromising the lawfulness of the processing carried out in the meantime. If consent is withdrawn or if you expressly object, Surf Eye will immediately cease processing your personal data for the purpose in question, unless there is a further need to process the data for the fulfillment of legal and/or contractual obligations.</p>
              
              
              <p className="toc-bold">IV.  How long do we retain your personal data?</p>
              <p>The time limit during which we retain your personal data may vary depending on the purpose for which it was processed. Therefore, we will retain your personal data within the following time limits:</p>
              
              <div className="table-responsive">
                <table>
                  <thead>
                    <tr>
                      <th>PURPOSE</th>
                      <th>LEGAL BASIS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Collecting images</td>
                      <td>
                        <ul>
                          <li>If the images are not purchased, they will be deleted within 7 days of their capture.</li>
                          <li>For as long as necessary to ensure full performance of the contract entered into with you.</li>
                        </ul>
                      </td>
                    </tr>

                    <tr>
                      <td>User account creation and selling the videos.</td>
                      <td>
                        <ul>
                          <li>For as long as necessary to ensure full performance of the contract entered into with you;</li>
                          <li>During the applicable legal time limits.</li>
                        </ul>
                      </td>
                    </tr>

                    <tr>
                      <td>Provide the premium personalized Surf Eye Coaching service if you acquire it</td>
                      <td>
                        <ul>
                          <li>For as long as necessary to ensure full performance of the contract entered into with you.</li>
                        </ul>
                      </td>
                    </tr>

                    <tr>
                      <td>Manage your library of saved videos/sessions</td>
                      <td>For as long as you wish to save the video and until you decide to delete it from your session library.</td>
                    </tr>

                    <tr>
                      <td>Payment processing, billing and administrative management</td>
                      <td>For a period of ten (10) years.</td>
                    </tr>

                    <tr>
                      <td>Comply with legal obligations</td>
                      <td>For the time required to comply with the legal obligations applicable in each case.</td>
                    </tr>

                    <tr>
                      <td>Sharing your waves on social media</td>
                      <td>Until you revoke your consent.</td>
                    </tr>

                    <tr>
                      <td>Sending commercial communications about our services</td>
                      <td>Until you revoke your consent or object to the processing, whichever is applicable.

                        <p>Without prejudice, in case you do not engage in activity with Us during 1 year, we will delete your personal data for this purpose.</p>
                      </td>
                    </tr>

                    <tr>
                      <td>Provide technical assistance and for the purpose of responding to requests, inquiries, or complaints</td>
                      <td>Until the moment that personal data are no longer necessary for the fulfillment of this purposes.</td>
                    </tr>
                  </tbody>
                </table>

              </div>
              
              
              <p>Notwithstanding, you can always send us an email at <a href="mailto: info@surfeye.video"> info@surfeye.video</a> requesting us to remove the video on the website and we will delete the corresponding images immediately.</p>
              
              
              <p className="toc-bold">V. To whom do we transfer your personal data?</p>
              <p>Surf Eye will not communicate your personal data to third parties, except in compliance with the legal obligations applicable in each case or where necessary for the services to be provided.</p>
              <p>Surf Eye, for the provision of its services, rely on <b> servers</b> located in Europe who will process your personal data on our behalf and in accordance with Surf Eye's specific instructions.</p>
              <p>If you decide to purchase the premium personalized <b> Surf Eye Coaching</b> service, the professional coach will need to analyze your videos and process your personal data in order to be able to provide you with the service of live video analysis, wave by wave feedback and corrections and tips both for inland and at sea exercises to improve your surfing.</p>
              <p>As mentioned above, if you provide us with your consent to do so, we may share a video of you on <b> Surf Eye's social media accounts</b>. Additionally, by tagging us on social media, you are giving us permission to republish your post and/or story on Surf Eye’s social media page/account. In case you don't want us to repost your story/post, you can always contact us and we will delete it immediately.</p>
              <p>When we share a video of you that you have authorized us to share on Surf Eye's <b>social media</b> accounts, since this involves the sharing of personal data on social media, accessible from any country in the world, the processing of the data will allow third parties located outside the territory of the European Economic Area to view the personal data.</p>
              <p>In accordance with the provisions of the Portuguese Civil Code, and the rights provided therein, namely the image right, by providing us with your consent to publish a video of you on Surf Eye's social media accounts, you authorize us to use, copy, edit, transform, reproduce and display (hereinafter "Use") your image for the purposes indicated above for the time legally permitted and for all territories in the world. The Use of the Image as provided herein does not generate or grant reimbursement, payment of compensation or economic rights of any kind.</p>
              
              
              <p className="toc-bold">VI. What rights do the Data Subject have?</p>
              <p>Under the conditions set out under applicable data protection law (e.g., the GDPR), you may have the following rights:</p>
              <p>-  Right of access: You have the right to obtain confirmation from Us as to whether or not</p>
              <p> Personal Data concerning you is processed, and, where that is the case, to request access to the Personal Data. The access information includes – inter alia – the purposes of the processing, the categories of Personal Data concerned, and the recipients or categories of recipients to whom the Personal Data have been, or will be disclosed. However, this is not an absolute right and the interests of other individuals may restrict your right of access. </p>
              <p>You also have the right to obtain a copy of the Personal Data undergoing processing. For further copies requested by you, We may charge a reasonable fee based on administrative costs.</p>
              <p>-  Right to rectification: You have the right to obtain rectification of inaccurate Personal Data </p>
              <p>concerning you from Us. Depending on the purposes of the processing, you have the right to have incomplete Personal Data completed, including by means of providing a supplementary statement.</p>
              <p>- Right to erasure ("right to be forgotten"): Under certain circumstances, you have the right to obtain the erasure of Personal Data concerning you from Us and We may be obliged to erase such Personal Data.</p>
              <p>-  Right to restriction of processing: Under certain circumstances, you have the right to obtain the restriction of processing your Personal Data from Us. In this case, the respective data will be marked and may only be processed by Us for certain purposes.</p>
              <p>- Right to data portability: Under certain circumstances, you have the right to receive the Personal Data concerning you, which you have provided to Us, in a structured, commonly used and machine-readable format, and you have the right to transmit those data to another entity without hindrance from Us. at any time to processing of your Personal Data by Us which is based on the necessity of processing for the purposes of the legitimate interests pursued by Us or by a third party, except where such interests are overridden by your interests or fundamental rights and freedoms which require protection. We will no longer process your Personal Data unless We demonstrate compelling legitimate grounds for the processing which override your interests, rights and freedoms or for the establishment, exercise or defense of legal claims.</p>
              <p>- Right to lodge a complaint: In addition to the rights set out above, you also have the right to lodge a complaint with the competent data protection supervisory authority - Comissão Nacional de Proteção de Dados (“CNPD”) through the website or via email geral@cnpd.pt.</p>
              <p>You can always send us an email at <a href="mailto:info@surfeye.video">info@surfeye.video</a> requesting us to remove the video on the website and we will delete the corresponding images immediately.</p>
              <p >
              If you have any question or wish to exercise these rights, you may send an email to <a href="mailto:info@surfeye.video"> info@surfeye.video</a>. The exercise of rights is free of charge, unless the request is obviously ungrounded, excessive or repeated.
              </p>
              <p className="toc-bold">VII. Final Provisions</p>
              <p>This policy is subject to change due to technological developments, changes in laws regarding the functionality of electronic services, etc.</p>
              <p>The Controller of personal data will publish information about changes in the policy on the Website.</p>
              <p>Date of last change: 26 July 2023</p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
