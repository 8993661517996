import { Button } from "@mui/material";
import React, { useEffect, useRef } from "react";
import axios from 'axios';
import { endpoints } from "../../../Api/Api";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import styles from "./Watch.module.css";
import styles2 from "./TimeAndPlaceSelector.module.css";
import watchStyles from "../../../pages/AppLayout.module.css";
import { useWaveContext } from "../../../context/CartContext";
import { useActor } from "@xstate/react";
import { useState } from 'react';
import { RotatingLines } from "react-loader-spinner";
import Swal from "sweetalert2";

import cardMart from "../../../assets/cardMart.svg";
import PlayIcon from "../../../assets/playIcon.svg";
import faqIcon from "../../../assets/faqIcon.svg";

import { FAQ } from "../../Modal/faq"
import { CartWaveList } from "./cartWavesList";

export type WaveInfo = { id?: string | null, gif?: string | null, site?: string | null, createdAt?: Date | null };





const locationTitles = {
  'nova-praia': {
    title1: 'Nova Praia',
    title2: 'Costa da Caparica'
  },
  'carcavelos-tunnel': {
    title1: 'Tunnel',
    title2: 'Carcavelos'
  },
  'peniche-bar-do-bruno': {
    title1: 'Bar do Bruno',
    title2: 'Baleal Peniche'
  },
  'caparica-tarquinio': {
    title1: 'Praia do Tarquínio',
    title2: 'Costa da Caparica'
  },
  'caparica-fonte-da-telha': {
    title1: 'Swara',
    title2: 'Fonte Da Telha'
  },
  "aljezur-arrifana": {
    title1: 'Swara',
    title2: 'Fonte Da Telha'
  }
}


export const CartWaves = () => {
  const waves = JSON.parse(localStorage.getItem("waves"));
  const location = JSON.parse(localStorage.getItem("location"))
  const day = JSON.parse(localStorage.getItem("date"))
  const navigate = useNavigate();
  const [isLoadingCheckout, setIsLoadingCheckout] = useState(false);
  const [allDates, setAllDates] = useState({})
  const [passType, setPassType] = useState()
  

  const { waveService } = useWaveContext();
  const [current] = useActor(waveService);
  // const ctx = current.context.cart.waves
  // console.log(ctx, "waves---------------------")
  const scrollRef = useRef(null);

  const [faqHandler, setFaqHandler] = useState(false)  

  useEffect(() => {
    waveService.send({
      type: 'reset',
    })
    const wavesFromLocal = JSON.parse(localStorage.getItem("waves"))
      for (let key in wavesFromLocal) {
          const wave = wavesFromLocal[key];
          waveService.send({
            type: 'updateWaves',
            data: wave
          })

        }
  }, [])

  // useEffect(() => {
  //   const customertoken = localStorage.getItem("token")
  //   axios.get(endpoints.GET_ACTIVE_PASS,
  //   {
  //     headers: {
  //       Authorization: `Bearer ${customertoken}`,
  //     },
  //   }
  //   ).then((response) => {
  //     setAllDates({...response?.data?.data[location],...response?.data?.data["All"]})
  //     setPassType(response?.data?.passType)
  //   })
  //     .catch((err) => {
  //       console.log(err, "eror while get pass info!!")
  //   })

  // }, [])

  const handleCreateSesssion = () => {
    const data = current.context.cart.waves
    setIsLoadingCheckout(true);
    if(data.length <= 0){
      setIsLoadingCheckout(false);
      return
    }
    const customertoken = localStorage.getItem("token");
      axios
        .post(
          endpoints.CREATE_SESION_FOR_ACTIVE_SUBSCRIPTION,
          {
            data: data,
          },
          {
            headers: {
              Authorization: `Bearer ${customertoken}`,
            },
          }
        )
        .then((response) => {
          if (response.data.status === "True") {
            setIsLoadingCheckout(false);
            // Swal.fire(response.data.message, "", "success");
            waveService.send({
              type: 'reset'
            });
            localStorage.removeItem("date")
            localStorage.removeItem("waves")
            localStorage.removeItem("location")
            
            Swal.fire(response.data.message, "", "success");
            navigate("/mysession")
          } else {
            setIsLoadingCheckout(false);
            // Swal.fire(response.data.message, "", "error");
            navigate('/session/checkout')
          }
        })
        .catch((error) => {
          setIsLoadingCheckout(false);
          console.log(error)
        });
  }

  const handleClick = (bool:boolean) => {
    setFaqHandler(bool)
  }

  return (
    <>
    <div className={watchStyles.watch}>
      <div className={watchStyles.watchContainer}>
        <div className={styles.HeadingContainer} ref={scrollRef}>
          <div >
            <Button className={`${styles2["button-title-btn"]} ${styles.HeaderFirst}`}
              onClick={() => navigate(-1)} 
              // onClick={() => setBackButtonPopup(true)}
            >
              <MdOutlineArrowBackIosNew />
              {"BACK"}
            </Button>
          </div>
          <div>
            <div className={styles.faqsButton}>
              <button onClick={() => setFaqHandler(true)}>
                FAQ
                <img src={faqIcon} alt="" />
              </button>
            </div>
          </div>
          <div className={styles.HeaderLast}>
            <h2>{locationTitles[location]?.title1}</h2>
            <h3>{locationTitles[location]?.title2}</h3>
          </div>
        </div>
        
        <h3 className={styles.header} >
          <span>Previews of start and end of wave | 20% OFF First Purchase: <b>WAVE20</b><br/>
            If a <span style={{ color: 'red' }}><b>Red Line</b></span> Appears, Surfer is crossing Recording Area</span>
        </h3>

        <CartWaveList waves={waves} 
          selectedWaves={current.context.cart.waves}
          onInitialScroll={() => scrollRef.current.scrollIntoView()}
          onChange={(wave) => {
            waveService.send({
            type: 'updateWaves',
            data: wave
          })
          // update waves localStorage too
          const wavesFromLocal = JSON.parse(localStorage.getItem("waves"))
            if(wavesFromLocal){
              if(!wavesFromLocal[wave?.id]){
                wavesFromLocal[wave.id] = wave
                localStorage.setItem("waves", JSON.stringify(wavesFromLocal))
              }else{
                delete wavesFromLocal[wave?.id];
                localStorage.setItem("waves", JSON.stringify(wavesFromLocal))
              }
            }
          }
        }
        />
        <div className={styles.wavesSeclect}>
          <button className={styles.btn} onClick={() => navigate('/cart')}>
            <img src={cardMart} alt="" />
            <span>{current.context.cart.waves.length}</span>
            WAVES <b> SELECTED</b>
          </button>

          <div className={styles.nextButton}>
            <button className={styles.btnNext} onClick={() =>{ handleCreateSesssion()}
            }>
            {isLoadingCheckout ? (
              <RotatingLines
                strokeColor="black"
                strokeWidth="5"
                animationDuration="0.75"
                width="60"
                visible={true}
              />
            ) : (
              <>
                <>NEXT</>
                <img src={PlayIcon} alt="" />
              </>
            )}
            </button>
          </div>
        </div>
      </div>

    </div>

      { faqHandler ?<FAQ handleClick={handleClick}/>: <></>}
      </>
  )
}