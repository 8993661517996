import React from 'react';
import { Icon, type IconProps } from './core';

export const TiktokIcon = (props: IconProps) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    width="54"
    height="61"
    viewBox="0 0 54 61"
    fill="none"
    {...props}
  >
    <path
      d="M53.8309 24.8839C48.6264 24.8964 43.5496 23.2733 39.3176 20.244V41.3714C39.3161 45.2844 38.1201 49.1038 35.8894 52.3187C33.6587 55.5337 30.4997 57.991 26.8347 59.362C23.1698 60.7331 19.1735 60.9526 15.3805 59.9912C11.5874 59.0298 8.17819 56.9333 5.60877 53.9821C3.03936 51.0308 1.43218 47.3655 1.00215 43.4761C0.572118 39.5868 1.33972 35.6589 3.20233 32.2176C5.06493 28.7763 7.93375 25.9856 11.4252 24.2188C14.9166 22.452 18.8643 21.7932 22.7402 22.3305V32.9568C20.9666 32.3989 19.062 32.4158 17.2985 33.0049C15.5349 33.594 14.0026 34.7253 12.9204 36.2372C11.8382 37.7491 11.2614 39.5643 11.2723 41.4236C11.2833 43.2829 11.8814 45.0911 12.9814 46.5902C14.0814 48.0893 15.6269 49.2024 17.3972 49.7707C19.1676 50.339 21.0722 50.3334 22.8392 49.7546C24.6061 49.1759 26.145 48.0536 27.2361 46.5481C28.3272 45.0425 28.9147 43.2307 28.9146 41.3714V0.0693359H39.3176C39.3103 0.947841 39.3839 1.82515 39.5374 2.69017C39.8989 4.62119 40.6506 6.45818 41.7464 8.08875C42.8421 9.71933 44.2591 11.1092 45.9104 12.1734C48.2598 13.7269 51.0143 14.5549 53.8309 14.5543V24.8839Z"
      fill="#F8F8F8"
    />
  </Icon>
);
