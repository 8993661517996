import { useMutation } from "@apollo/client";
import { graphql } from "../gql";

export const TRACK = graphql(/* GraphQL */ `
mutation track($info: String){
  track {
    track(info: $info)
  }
}`);

export const useTrack = () => {
    const [fn] = useMutation(TRACK);
    return fn;
}