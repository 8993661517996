import React, { useState, useEffect, useRef } from "react";
import "./Navbar.styles.scss";
import Logo from "../../assets/logo2.svg";
import Hamburger from "../Hamburger/Hamburger";
import { Link } from "react-router-dom";
import { FaRegUser } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import { regions } from "../../pages/LandingPage";
import { useSearchParams } from "react-router-dom";
const NavbarItem = ({ title, onClick, link, handleClick }) => {
  const [isActive, setIsActive] = useState(false);

  const onClickHandler = (event) => {
    // Pehle se active link ka active class hata do
    const activeLink = document.querySelector(".navbar__link.active");
    if (activeLink) {
      activeLink.classList.remove("active");
    }

    // Naye link ko active kar do
    event.target.closest(".navbar__link").classList.add("active");
    handleClick();
  };
  return (
    <div className={`navbar__link ${isActive ? "active" : ""}`}>
      <a className="" onClick={onClickHandler}>
        {title}
      </a>
    </div>
  );
};

const Navbar = ({
  isHome,
  isAuth,
  authHandler,
  handleClick,
  handleRegionItemClick,
}) => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const timeout = setTimeout(() => {
      const section = searchParams.get("section");
      if (!section) return;

      handleClick(section);

      if (section === "register") {
        authHandler(true);
        setIsOpenOnbording(false);
      } else if (section === "login") {
        authHandler();
        setIsOpenOnbording(false);
      }
    }, 0);

    return () => clearTimeout(timeout);
  }, [searchParams]);

  console.log("[LandingPage:searchParams]:", searchParams.get("section"));

  const [age, setAge] = React.useState("");
  const dropdownRef = useRef(null);
  const dropdownRef1 = useRef(null);
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenOnbording, setIsOpenOnbording] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setIsOpenOnbording(false);
  };

  const toggleDropdownOnbording = () => {
    setIsOpenOnbording(!isOpenOnbording);
    setIsOpen(false);
  };

  const [isSticky, setSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
      if (
        dropdownRef1.current &&
        !dropdownRef1.current.contains(event.target)
      ) {
        setIsOpenOnbording(false);
      }
    };

    document.body.addEventListener("click", handleClickOutside);

    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, [setIsOpen, setIsOpenOnbording]);

  return (
    <div className={isSticky ? "navbar sticky" : "navbar"}>
      <div className="navbar-container">
        <Link to="/">
          <div className="navbar__logo">
            <img src={Logo} alt="" />
          </div>
        </Link>
        <div className="navbar__links">
          <NavbarItem
            title="How it works"
            handleClick={() => handleClick("howitworks")}
          />
          <NavbarItem
            title="highlights"
            handleClick={() => handleClick("highlight")}
          />

          <div className="profileDropdown" ref={dropdownRef}>
            <div className="HeaderDropdown">
              <span onClick={toggleDropdown}>
                {" "}
                Locations <IoIosArrowDown />
              </span>
              <div className="dropdownMenu">
                {isOpen ? (
                  <div className="dropdownBox">
                    <Link
                      to="/beachcam/bar-do-bruno"
                      // onClick={() => {
                      //   handleRegionItemClick(0, regions[0]);
                      //   handleClick("location");
                      //   setIsOpen(false);
                      // }}
                    >
                      Bar do Bruno - Baleal
                      {/* {" "} */}
                      
                      {/* {" "} */}
                    </Link>
                    <Link
                      to="/beachcam/carcavelos-tunnel"
                      // href="/"
                      // onClick={() => {
                      //   handleRegionItemClick(1, regions[1]);
                      //   handleClick("location");
                      //   setIsOpen(false);
                      // }}
                    >
                      {/* {" "} */}
                      Tunnel - Carcavelos
                      {/* {" "} */}
                    </Link>
                    <Link
                      to="/beachcam/caparica-tarquinio"
                      // href="/"
                      // onClick={() => {
                      //   handleRegionItemClick(2, regions[2]);
                      //   handleClick("location");
                      //   setIsOpen(false);
                      // }}
                    >
                      {" "}
                      Tarquínio - Caparica{" "}
                    </Link>
                    <Link
                      to="/beachcam/nova-praia"
                      // href="/"
                      // onClick={() => {
                      //   handleRegionItemClick(3, regions[3]);
                      //   handleClick("location");
                      //   setIsOpen(false);
                      // }}
                    >
                      {" "}
                      Nova Praia - Caparica{" "}
                    </Link>
                    <Link
                      to="/beachcam/caparica-fonte-da-telha"
                      // href="/"
                      // onClick={() => {
                      //   handleRegionItemClick(4, regions[4]);
                      //   handleClick("location");
                      //   setIsOpen(false);
                      // }}
                    >
                      {" "}
                      Swara - Fonte da Telha{" "}
                    </Link>
                    <Link
                      to="/beachcam/aljezur-arrifana"
                      // href="/"
                      // onClick={() => {
                      //   handleRegionItemClick(4, regions[4]);
                      //   handleClick("location");
                      //   setIsOpen(false);
                      // }}
                    >
                      {" "}
                      Arrifana - Aljezur{" "}
                    </Link>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <NavbarItem
            title="pricing"
            link=""
            handleClick={() => handleClick("price")}
          />
          <div className="profileDropdown" ref={dropdownRef1}>
            <div className="HeaderDropdown">
              <span onClick={toggleDropdownOnbording}>
                <FaRegUser className="icon" /> <IoIosArrowDown />
              </span>
              <div className="dropdownMenu">
                {isOpenOnbording ? (
                  <div className="dropdownBox">
                    <Link
                      onClick={() => {
                        authHandler();
                        setIsOpenOnbording(false);
                      }}
                    >
                      {" "}
                      Login{" "}
                    </Link>
                    <Link
                      onClick={() => {
                        authHandler(true);
                        setIsOpenOnbording(false);
                      }}
                    >
                      {" "}
                      Register{" "}
                    </Link>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="navbar__menu">
          <Hamburger
            handleSetLocationReference={handleClick}
            handleRegionItemClick={handleRegionItemClick}
            authHandler={authHandler}
            links={[
              { title: "How it works" },
              { title: "highlights" },
              { title: "Locations" },
              { title: "pricing" },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
