import { ReactNode } from "react";
import React from "react";
import "./globals.scss";
import { useInjectTailwindCss } from "../hooks/use-inject-tailwind-css";

type ThemeProviderProps = {
  children: ReactNode | React.ReactNode[];
};

const ThemeProvider = ({ children }: ThemeProviderProps) => {
  useInjectTailwindCss();

  return (
    <main className="tw-enabled twp font-exo text-brand-primary">
      {children}
    </main>
  );
};

export default ThemeProvider;