import React from 'react';
import { Icon, type IconProps } from './core';

export const YoutubeIcon = (props: IconProps) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    width="58"
    height="41"
    viewBox="0 0 58 41"
    fill="none"
    {...props}
  >
    <path
      d="M56.6761 6.46295C56.0161 3.9778 54.0714 2.02058 51.6024 1.35637C47.127 0.149414 29.1813 0.149414 29.1813 0.149414C29.1813 0.149414 11.2357 0.149414 6.76018 1.35637C4.29111 2.02068 2.34649 3.9778 1.68648 6.46295C0.487305 10.9674 0.487305 20.3656 0.487305 20.3656C0.487305 20.3656 0.487305 29.7638 1.68648 34.2683C2.34649 36.7534 4.29111 38.6291 6.76018 39.2933C11.2357 40.5003 29.1813 40.5003 29.1813 40.5003C29.1813 40.5003 47.1269 40.5003 51.6024 39.2933C54.0714 38.6291 56.0161 36.7534 56.6761 34.2683C57.8752 29.7638 57.8752 20.3656 57.8752 20.3656C57.8752 20.3656 57.8752 10.9674 56.6761 6.46295ZM23.312 28.8984V11.8328L38.3111 20.3658L23.312 28.8984Z"
      fill="#F8F8F8"
    />
  </Icon>
);
