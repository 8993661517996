import React,{ useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
/* import { Box } from "@mui/material"; */
import tb from "./tableStyle.module.scss";
import { FiFilter } from "react-icons/fi";
import { FiEdit  } from "react-icons/fi";
import CouponModal from '../Components/CouponModal';
import EditCouponModal from '../Components/EditCouponModal';
import { Dropdown} from "react-bootstrap";
import Header from "../Components/Header";
import axios from "axios";
import { endpoints } from "../../../Api/Api";
import dayjs from "dayjs";

export default function ScheduleDataGrid() {
  const Navigate = useNavigate();
  /* const handleRowClick = (params: any) => {}; */

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openUpdate, setOpenUpdate] = React.useState(false);
  const handleOpenUpdate = () => setOpenUpdate(true);
  const handleCloseUpdate = () => setOpenUpdate(false);
  const [editCoupon, setEditCoupon] = React.useState(null);

  const [coupons, setCoupons] = useState([]);
  const [primaryCouponsList, setPrimaryCouponsList] = useState([])
  const [searchStack, setSearchStack] = useState([])
  const [searchByParam, setSearchByParam] = useState("")



  const handleAddCoupon = async (
    title,
    discount,
    expiresAt,
    isFlat,
    isMultipleUser,
    isSingle,
    applicableto
  ) => {

    if (title === "" || discount === "" || expiresAt === "") {
      Swal.fire("Fields Cannot be Empty   ", "", "error");
    } else {
      const token = localStorage.getItem("token");
      const formData = new FormData();
      formData.append("name", title);
      formData.append("discount", discount);
      formData.append("expire_at", expiresAt);
      formData.append("flatprice", isFlat ? 1 : 0);
      formData.append("singleUse", isSingle);
      formData.append("multiUser", isMultipleUser);
      formData.append("applicableto", applicableto);
      // if (isMultipleUser === false && isSingle === true) {
      //   formData.append("type", 1);
      // } else if (isMultipleUser === false && isSingle === false) {
      //   formData.append("type", 2);
      // } else if (isMultipleUser === true && isSingle === false) {
      //   formData.append("type", 3);
      // } else if (isMultipleUser === true && isSingle === true) {
      //   formData.append("type", 4);
      // }
      const response = await axios.post(endpoints.POST_COUPONS, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.status === "True") {
        Swal.fire(response.data.message, "", "success");
        getCoupouns();
        setSearchByParam('')
        // reset all cheked box and their filters
        resetCheckboxes()
      }
    }
  };

  const getCoupouns = () => {
    const token = localStorage.getItem("token");
    const noCache = Date.now();
    axios
      .get(`${endpoints.GET_COUPONS}?nocache=${noCache}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setCoupons(res.data.data);
        setPrimaryCouponsList(res.data.data)
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getCoupouns()
  },[])

  const needToRerender = () => {
    getCoupouns()
    setSearchByParam('')
    // reset all cheked box and their filters
    resetCheckboxes()
  }

  const SortByCouponsProperty = () => {
    try {
      const getToken = localStorage.getItem("token");
      const getUserInfo = localStorage.getItem("userInfo")
      if (getToken !== null && getUserInfo != null) {
        if (JSON.parse(getUserInfo).role === "superadmin") {
            axios
              .get(`${endpoints.GETCOUPONSBYFIELDS}?query=${searchByParam}`,
                {
                  headers: {
                    Authorization: `Bearer ${getToken}`,
                  },
                }
              )
              .then((response) => {
                if (response.data.status === "True") {
                  setCoupons(response.data.coupons)
                  setPrimaryCouponsList(response.data.coupons)
                }
              })
              .catch((error) => {
                console.log(error)
              });
            }
      }
    } catch (error) {
      console.error('Error fetching coupons data:', error);
    }
  }

  const handleForGetAllCouponsIndvProperty = (sortByProperty) => {
    if(sortByProperty === searchByParam){
      let allCoupons_rev = primaryCouponsList.reverse()
      let allCoupons_revUp = allCoupons_rev.map(coupon => coupon)
      setCoupons(allCoupons_revUp)
    }
    setSearchByParam(sortByProperty)
    // reset all cheked box and their filters
    resetCheckboxes()
  }

  const [refresh, setRefresh] = useState(false)
  useEffect(() => {
    SortByCouponsProperty()
  }, [searchByParam])

  const handleForOpenEditPopup = (targetCoupon) => {
    setEditCoupon(targetCoupon)
    handleOpenUpdate()
  }

  const [selectedCouponItem, setSelectedCouponItem] = useState({
    CouponItem: {
      status: [],
      appliesto: [],
      multitimes: [],
      multiuser: []
    },
  });

  const handleChangeCheckbox = (e) => {
    const name = e.target.name
    const value = e.target.value;
    const checked = e.target.checked;

    const index = searchStack.indexOf(name);
    if (index > -1) { // only splice array when item is found
      searchStack.splice(index, 1); // 2nd parameter means remove one item only
      setSearchStack([...searchStack, name])
    }else{
      setSearchStack([...searchStack, name])
    }

    const itemSelected = selectedCouponItem?.CouponItem;
    // Case 1 : The user checks the box
    if (checked === true) {
      itemSelected[name].push(value)
      setSelectedCouponItem[itemSelected]
    }
    // Case 2  : The user unchecks the box
    else {
      itemSelected[name] = itemSelected[name].filter((e) => e != value)
      setSelectedCouponItem[itemSelected]
    }
    setRefresh(!refresh)
  };
  let fn = {
    status: function(list, src){
      if(selectedCouponItem.CouponItem[src]?.length < 1){
        return list
      }else{
        let arr = []
        list?.map((coupon) => {
          // Check if status matches 'true', 'false', or 'expired'
          const statusMatches = selectedCouponItem.CouponItem.status.includes(String(coupon.status));
          const expiretime = Number(dayjs(coupon.expire_at).format("YYYYMMDDHHmmss"))
          const currenttime = Number(dayjs().format("YYYYMMDDHHmmss"))
          const isCouponExpired = expiretime < currenttime ? true : false;
          const isCouponUsage = coupon?.usage ? true : false;
          const isCouponSingleUse = coupon.singleUse
          const isCouponMultiUse = coupon.multiUser

          // if coupon is single use and usage is greater than 0 and multiuser(false) it means status redeemed
          // if date.now() is greater than coupon expired_at it means status expired
          if(isCouponExpired || (isCouponUsage && isCouponSingleUse && !isCouponMultiUse)){
            return
          }
          else if(statusMatches){
            arr.push(coupon)
          }
        })
        // for expired resultant
        const isExpiredInStack = selectedCouponItem.CouponItem.status.includes("expired");
        if(isExpiredInStack){
          list?.map((coupon) => {
            // Check coupon expired or not
              const expiretime = Number(dayjs(coupon.expire_at).format("YYYYMMDDHHmmss"))
              const currenttime = Number(dayjs().format("YYYYMMDDHHmmss"))
              // expired if current time is greater than expire_at
              if(expiretime < currenttime){
                arr.push(coupon)
              }
          })
        }

        // for redeemed result
        const isRedeemedInStack = selectedCouponItem.CouponItem.status.includes("redeemed");
        if(isRedeemedInStack){
          list?.map((coupon) => {
            const expiretime = Number(dayjs(coupon.expire_at).format("YYYYMMDDHHmmss"))
            const currenttime = Number(dayjs().format("YYYYMMDDHHmmss"))
            const isCouponExpired = expiretime < currenttime ? true : false;
            const isCouponUsage = coupon?.usage ? true : false;
            const isCouponSingleUse = coupon.singleUse
            const isCouponMultiUse = coupon.multiUser
            
            if(!isCouponExpired && (isCouponUsage && isCouponSingleUse && !isCouponMultiUse)){
              arr.push(coupon)
            }
          })

        }
        
        return arr
      }
    },
    appliesto: function(list, src){
      if(selectedCouponItem.CouponItem[src]?.length < 1){
        return list
      }else{
        let arr = []
        list?.map((coupon) => {
          // Check if appliesTo matches any permutation of selected values
          const selectedValues = selectedCouponItem.CouponItem.appliesto;
          const appliesToValues = coupon?.applicableto?.split(',');
          
          // Check if all selected values are present in appliesToValues
          let appliesToMatches = true;
          if(selectedCouponItem.CouponItem.appliesto.length < 1){
            appliesToMatches = false;
          }else{
            appliesToMatches = selectedValues?.every(value =>
              appliesToValues?.includes(value)
            );
          }

          if(appliesToMatches){
            arr.push(coupon)
          }
        })
        return arr
      }
    },
    multitimes: function(list, src){
      if(selectedCouponItem.CouponItem[src]?.length < 1){
        return list
      }else{
        let arr = []
        list?.map((coupon) => {
          // Check if multiTimes is either true or false
          const multiTimesMatches = selectedCouponItem.CouponItem.multitimes.includes(String(coupon.singleUse));
          if(multiTimesMatches){
            arr.push(coupon)
          }
        })
        return arr
      }
    },
    multiuser: function(list, src){
      if(selectedCouponItem.CouponItem[src]?.length < 1){
        return list
      }else{
        let arr = []
        list?.map((coupon) => {
          // Check if multiUser is either true or false
          const multiUserMatches = selectedCouponItem.CouponItem.multiuser.includes(String(coupon.multiUser));
          if(multiUserMatches){
            arr.push(coupon)
          }
        })
        return arr
      }
    }
  }

  // filter data based on following condition(no unlimited, free access[freeaccess < new Date()], subscription[subsstatus === true])
  useEffect(() => {
    let res = []
    if(selectedCouponItem?.CouponItem.appliesto.length < 1 && selectedCouponItem.CouponItem.multitimes.length < 1 && selectedCouponItem.CouponItem.multiuser.length < 1 && selectedCouponItem.CouponItem.status.length < 1){
      setCoupons(primaryCouponsList)
    }else{
      let resultant = primaryCouponsList
      searchStack.map((filterparam, idx) => {
        let tmpRes = fn[filterparam](resultant, filterparam)
        resultant = tmpRes
      })
      setCoupons(resultant)
    }
  }, [refresh])  
  
  const resetCheckboxes = () => {
    setSelectedCouponItem({
      CouponItem: {
        status: [],
        appliesto: [],
        multitimes: [],
        multiuser: []
      }
    })
    // clear search stack bucket
    setSearchStack([])
    // clear checkbox too
    const statusCheckBoxes =  document.querySelectorAll('[name="status"]');
    const appliestoCheckBoxes = document.querySelectorAll('[name="appliesto"]');
    const multitimesCheckbox =  document.querySelectorAll('[name="multitimes"]');
    const multiuserCheckbox =  document.querySelectorAll('[name="multiuser"]');

    for (let idx = 0; idx < statusCheckBoxes.length; idx++) {
      if(statusCheckBoxes[idx].checked){
        statusCheckBoxes[idx].checked = false
      }
    }

    for (let idx = 0; idx < appliestoCheckBoxes.length; idx++) {
      if(appliestoCheckBoxes[idx].checked){
        appliestoCheckBoxes[idx].checked = false
      }
    }

    for (let idx = 0; idx < multitimesCheckbox.length; idx++) {
      if(multitimesCheckbox[idx].checked){
        multitimesCheckbox[idx].checked = false
      }
    }

    for (let idx = 0; idx < multiuserCheckbox.length; idx++) {
      if(multiuserCheckbox[idx].checked){
        multiuserCheckbox[idx].checked = false
      }
    }
  }

  return (
    <>
    <div className={`${tb.bodyBackgound}`}>
      <Header/>
      <div className={`${tb.userManagement}`}>
        <div className="container">
          <div className={`${tb.userSalesBox}`}>
            <div className={`${tb.Heading}`}>
              <h3>CODES</h3>
            </div>
            <div className={`${tb.filterRemove}`}>
              <span onClick={() => {
                resetCheckboxes()
                // call getCoupouns for latest records
                getCoupouns()
                // also clear field sort state
                setSearchByParam('')
              }}>REMOVE ALL FILTERs   <span>X</span></span>
              <div className={`${tb.filterRemove}`}>
              <button onClick={handleOpen}>CREATE CODE +</button>
              <CouponModal open={open} handleClose={handleClose} handleAddCoupon={handleAddCoupon}/>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className={`${tb.tabbingSection}`}>
        <div className="container">
          <div className={`${tb.tableResponsive}`}>
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th onClick={() => handleForGetAllCouponsIndvProperty("name")}>CODE</th>
                  <th onClick={() => handleForGetAllCouponsIndvProperty("created_at")}>CREATED ON</th>
                  <th onClick={() => handleForGetAllCouponsIndvProperty("expire_at")}>EXPIRES ON</th>
                  <th>STATUS 
                  <Dropdown className={`${tb.dropDownFilter}`}>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <FiFilter className={`${tb.filterIcon}`} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <ul>
                        <li><label><input type="checkbox" value="true" onChange={handleChangeCheckbox} name="status"/> ACTIVE</label></li>
                        <li><label><input type="checkbox" value="false" onChange={handleChangeCheckbox} name="status"/> INACTIVE</label></li>
                        <li><label><input type="checkbox" value="expired" onChange={handleChangeCheckbox} name="status"/> EXPIRED</label></li>
                        <li><label><input type="checkbox" value="redeemed" onChange={handleChangeCheckbox} name="status"/> REDEEMED</label></li>
                      </ul>
                    </Dropdown.Menu>
                  </Dropdown>
                  </th>
                  <th onClick={() => handleForGetAllCouponsIndvProperty("discount")}>AMOUNT</th>
                  <th>APPLIES TO 
                  <Dropdown className={`${tb.dropDownFilter}`}>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <FiFilter className={`${tb.filterIcon}`} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <ul>
                        <li><label><input type="checkbox" value="day" onChange={handleChangeCheckbox} name="appliesto"/> DAY</label></li>
                        <li><label><input type="checkbox" value="wave" onChange={handleChangeCheckbox} name="appliesto"/> WAVE</label></li>
                        <li><label><input type="checkbox" value="month" onChange={handleChangeCheckbox} name="appliesto"/> MONTH</label></li>
                      </ul>
                    </Dropdown.Menu>
                  </Dropdown>
                  </th>
                  <th onClick={() => handleForGetAllCouponsIndvProperty("usage")}>TIMES USED</th>
                  <th>MULT. TIMES
                  <Dropdown className={`${tb.dropDownFilter}`}>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <FiFilter className={`${tb.filterIcon}`} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <ul>
                        <li><label><input type="checkbox" value="false" onChange={handleChangeCheckbox} name="multitimes"/> YES</label></li>
                        <li><label><input type="checkbox" value="true" onChange={handleChangeCheckbox} name="multitimes"/> NO</label></li>
                      </ul>
                    </Dropdown.Menu>
                  </Dropdown>
                  </th>
                  <th>MULT. USERS
                  <Dropdown className={`${tb.dropDownFilter}`}>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <FiFilter className={`${tb.filterIcon}`} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <ul>
                        <li><label><input type="checkbox" value="true" onChange={handleChangeCheckbox} name="multiuser"/> YES</label></li>
                        <li><label><input type="checkbox" value="false" onChange={handleChangeCheckbox} name="multiuser"/> NO</label></li>
                      </ul>
                    </Dropdown.Menu>
                  </Dropdown>
                  </th>
                  <th>EDIT</th>
                </tr>
              </thead>

              <tbody>
                {coupons.length && coupons?.map((coupon, idx) => {
                  // vars for expired coupon
                  let date = new Date(coupon.expire_at);
                  let options = { timeZone: 'Europe/Lisbon' }; // 'pt-PT' equivalent 'Europe/Lisbon'
                  let ptPTDate = date.toLocaleString('en-US', options);
                  let ptPTMilliseconds = new Date(ptPTDate).getTime();
                  let curretnDate = new Date()
                  let ptCurrentDate = curretnDate.toLocaleString('en-US', options);
                  let currentTimeMilliSeconds = new Date(ptCurrentDate).getTime()

                  // vars for redeemed
                  const isCouponUsage = coupon?.usage ? true : false;
                  const isCouponSingleUse = coupon.singleUse
                  const isCouponMultiUse = coupon.multiUser
                  

                  return (
                  <tr key={idx}>
                    <td>{++idx}</td>
                    <td>{coupon.name}</td>
                    <td>{new Date(coupon.created_at).toLocaleDateString('pt-PT')}</td>
                    <td>{new Date(coupon.expire_at).toLocaleDateString('pt-PT')}</td>
                    <td>{currentTimeMilliSeconds > ptPTMilliseconds ? 'Expired': (isCouponUsage && isCouponSingleUse && !isCouponMultiUse) ? 'Redeemed' : (coupon.status) ?'ACTIVE': 'INACTIVE' }</td>
                    {/* {coupon.status ?'ACTIVE': 'INACTIVE'} */}
                    <td>{coupon.flatprice ?`€${coupon.discount}`:`${coupon.discount}%`}</td>
                    <td>{coupon?.applicableto || '--'}</td>
                    <td>{coupon?.usage || '--'}</td>
                    <td>{coupon.singleUse ?'SINGLE': 'MULTIPLE'}</td>
                    <td>{coupon.multiUser ?'MULTIPLE': 'SINGLE'}</td>
                    <td>
                      <FiEdit onClick={() => {
                        handleForOpenEditPopup(coupon)
                      }}/>
                    </td>
                  </tr> 
                )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </section>

      <EditCouponModal open={openUpdate} handleClose={handleCloseUpdate} couponDetails={editCoupon} needToRerender={needToRerender}/>
      </div>
    </>
  );
}
