import React, { useEffect, useMemo, useState } from "react";
import { useDebouncedCallback } from 'use-debounce';
import { useWaveContext } from "../../../context/CartContext";
import { useActor } from "@xstate/react";
import styles from "./Checkout.module.css";



export const CheckCoupons = (props:  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
  const { waveService } = useWaveContext();
  const [current] = useActor(waveService);
  const [couponCode, setCouponCode] = useState("");

  const refetchDb = useDebouncedCallback(
    () => {
      if (couponCode != "") {
        waveService.send({
          type: 'coupon',
          couponCode: couponCode
        });
      }
    },
    1000
  );

  useEffect(() => {
    refetchDb();
    if (couponCode == "") {
      waveService.send({
        type: 'coupon',
        couponCode: couponCode
      });
    }
  }, [couponCode]);

  console.log(`Some coupon: ${couponCode}`);

  return (<>
    {(couponCode != "" && current.context.coupon?.message && current?.context?.selectedProduct?.name != "per-month") ?<p className={`${styles.alertBox}`}>{current?.context?.coupon?.message}</p>:<p className={`${styles.alertBox}`} style={{textIndent: "-9999px" }}>INVALID COUPON</p>}
    <input
      disabled = {(current?.context?.selectedProduct?.name === "per-month")? true : false}
      className={`${styles.formControl}`}
      placeholder="Discount Code"
      onChange={(e) => {
        setCouponCode(e.target.value);
      }}
      onBlur={(e) => {
        setCouponCode(e.target.value)
      }}
      value={(current?.context?.selectedProduct?.name === "per-month")? "" :couponCode}
    />

    {/* {(couponCode != "" && current.context.coupon?.message) && <div className={styles.coupons_buttons} style={{ justifyContent: "flex-end" }}>
      
    </div>} */}


  </>)
}
