import styles from "../Session/Checkout/Checkout.module.css";

export const FAQ = ({handleClick}) => {
    return (
        <>
        <div className={styles.faqModalStart}>
        <div className={styles.background}></div>
        <div className={styles.faqModal}>
          <div className={styles.faqHeading}>
            <h2>FAQ</h2>
            <button className={styles.closeFaq}  onClick={() => handleClick(false)}>
              X close
            </button>
          </div>
          <div className={styles.faqBox}>
            <div className={styles.boxText}>
              <h3>GENERAL</h3>
              <h4>What is Surf Eye?</h4>
              <p>Surf Eye is a network of high-resolution cameras that automatically record and edit every
                wave surfed within our Recording Areas (check our landing page for exact locations).</p>
              <h4>Do I need to book in advance?</h4>
              <p>No, our cameras record every surfer, whether they've paid or not.</p>
              <h4>How do I get recorded?</h4>
              <p>Surf within one of our recording areas. Our cameras are active every day; you just need to
                remember the time your session started. Visit our website 45 minutes after your session to
                select your waves from 4-second previews. Full wave videos are available after purchase.</p>
              <h4>How does the camera know who I am?</h4>
              <p>Our cameras don't identify individuals. After your session, visit our website, sign up or log in,
                and select and download your waves.</p>
              <h4>Do I need to wear something to be recorded?</h4>
              <p>No, it's not necessary, but if you want to make yourself more recognizable, you can wear
                distinctive or colored items (lycra, hat, etc.), though it's entirely optional.</p>
              <h3>VIDEOS</h3>
              <h4>I can only see a few seconds of my wave; how do I know if the wave is fully filmed?</h4>
              <p>Our previews are all 4 seconds long. As long as you surf within the recording area, the full
                video of your wave will be available for download.
              </p>
              <h4>Is the quality of the final video better than the preview?</h4>
              <p>Yes, the quality of the downloaded video is slightly better than the preview.</p>
              <h4>Do I need to select all the waves I want to download before checkout?</h4>
              <p>Yes, select all your waves before reaching checkout; you cannot add more later.</p>
              <h4>I surfed a few days ago and haven't downloaded my videos yet. Can I still purchase them?</h4>
              <p>Yes, but hurry up. All unclaimed videos are deleted 7 days after recording.</p>
              <h3>PRICING</h3>
              <h4>What is a wave?</h4>
              <p>A wave is one video of a wave you selected.</p>
              <h4>What is a day pass?</h4>
              <p>A day pass lets you download unlimited waves from your selected day. Make sure to select all
                your waves before purchasing, as you cannot add more later.</p>
              <h4>What is Surf Eye Unlimited?</h4>
              <p>Surf Eye Unlimited grants you 30 days of unlimited videos from all our active and upcoming
                cameras. Perfect for improving your surfing and technique with video analysis.</p>
              <h3>PAYMENT</h3>
              <h4>I have a discount code. How do I redeem it?</h4>
              <p>At the bottom of the checkout page, you'll find an "Discount code" input field. Enter your code,
                and your discount will be applied. If it doesn't work, you may have already used it or it has
                expired.</p>
              <h4>What are accepted payment methods?</h4>
              <p>We accept all major credit and debit cards; cash payments are not accepted.</p>
              <p><b>For any other questions,</b> feel free to contact us at <b> <a href="mailto: info@surfeye.video"> info@surfeye.video</a></b> or on <b> WhatsApp at
                <a href="https://wa.me/351934673643" target="_blank"> +351 934673643</a>.</b></p>
            </div>
          </div>
        </div>
      </div>
        </>
    )
}