import React from 'react';
import { Icon, type IconProps } from './core';

export const InstagramIcon = (props: IconProps) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    width="54"
    height="54"
    viewBox="0 0 54 54"
    fill="none"
    {...props}
  >
    <path
      d="M26.9882 13.7416C19.4697 13.7416 13.4053 19.8061 13.4053 27.3246C13.4053 34.843 19.4697 40.9075 26.9882 40.9075C34.5067 40.9075 40.5712 34.843 40.5712 27.3246C40.5712 19.8061 34.5067 13.7416 26.9882 13.7416ZM26.9882 36.1552C22.1296 36.1552 18.1575 32.195 18.1575 27.3246C18.1575 22.4541 22.1178 18.4939 26.9882 18.4939C31.8587 18.4939 35.8189 22.4541 35.8189 27.3246C35.8189 32.195 31.8469 36.1552 26.9882 36.1552ZM44.295 13.186C44.295 14.9474 42.8764 16.3542 41.1268 16.3542C39.3654 16.3542 37.9586 14.9356 37.9586 13.186C37.9586 11.4364 39.3772 10.0178 41.1268 10.0178C42.8764 10.0178 44.295 11.4364 44.295 13.186ZM53.2911 16.4015C53.0902 12.1575 52.1208 8.39828 49.0117 5.30103C45.9145 2.20379 42.1553 1.23443 37.9113 1.02164C33.5374 0.773386 20.4273 0.773386 16.0533 1.02164C11.8212 1.2226 8.06195 2.19197 4.95289 5.28921C1.84383 8.38645 0.886281 12.1457 0.673494 16.3896C0.425242 20.7636 0.425242 33.8737 0.673494 38.2476C0.87446 42.4916 1.84383 46.2508 4.95289 49.3481C8.06195 52.4453 11.8094 53.4147 16.0533 53.6275C20.4273 53.8757 33.5374 53.8757 37.9113 53.6275C42.1553 53.4265 45.9145 52.4571 49.0117 49.3481C52.109 46.2508 53.0784 42.4916 53.2911 38.2476C53.5394 33.8737 53.5394 20.7754 53.2911 16.4015ZM47.6404 42.9408C46.7184 45.2578 44.9333 47.0429 42.6045 47.9768C39.1171 49.3599 30.842 49.0407 26.9882 49.0407C23.1344 49.0407 14.8475 49.3481 11.372 47.9768C9.05496 47.0547 7.26991 45.2696 6.33601 42.9408C4.95289 39.4534 5.27207 31.1784 5.27207 27.3246C5.27207 23.4707 4.96471 15.1838 6.33601 11.7083C7.25809 9.39128 9.04314 7.60623 11.372 6.67233C14.8593 5.28921 23.1344 5.60839 26.9882 5.60839C30.842 5.60839 39.1289 5.30103 42.6045 6.67233C44.9215 7.59441 46.7065 9.37946 47.6404 11.7083C49.0236 15.1957 48.7044 23.4707 48.7044 27.3246C48.7044 31.1784 49.0236 39.4653 47.6404 42.9408Z"
      fill="#F8F8F8"
    />
  </Icon>
);
