import React from 'react';
import { Icon, type IconProps } from './core';

export const SwellIcon = (props: IconProps) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    width="65"
    height="65"
    viewBox="0 0 65 65"
    fill="none"
    {...props}
  >
    <circle
      cx="32.9386"
      cy="32.8"
      r="29.9177"
      stroke="#01243C"
      strokeWidth="4.15526"
    />
    <path
      d="M52.914 29.1477C49.3927 29.1477 47.6538 27.4957 45.9583 25.9307C44.3498 24.4526 42.8717 23.018 39.8721 23.018C36.8725 23.018 35.351 24.4091 33.7859 25.9307C32.0905 27.4957 30.3516 29.1477 26.8303 29.1477C26.4825 29.1477 26.2217 28.8868 26.2217 28.5391C26.2217 28.1913 26.4825 27.9304 26.8303 27.9304C29.8299 27.9304 31.3515 26.5393 32.9165 25.0178C34.6119 23.4527 36.3508 21.8008 39.8721 21.8008C43.3934 21.8008 45.1323 23.4527 46.8278 25.0178C48.4363 26.4958 49.9143 27.9304 52.914 27.9304C53.2617 27.9304 53.5226 28.1913 53.5226 28.5391C53.5226 28.8868 53.2617 29.1477 52.914 29.1477Z"
      fill="#01243C"
    />
    <path
      d="M26.7794 33.5403C23.2581 33.5403 21.5191 31.8883 19.8237 30.3233C18.2152 28.8452 16.7371 27.4106 13.7375 27.4106C13.3897 27.4106 13.1289 27.1498 13.1289 26.802C13.1289 26.4542 13.3897 26.1934 13.7375 26.1934C17.2588 26.1934 18.9977 27.8453 20.6932 29.4103C22.3017 30.8884 23.7797 32.323 26.7794 32.323C29.779 32.323 31.3005 30.9319 32.8655 29.4103C34.561 27.8453 36.2999 26.1934 39.8212 26.1934C40.169 26.1934 40.4298 26.4542 40.4298 26.802C40.4298 27.1498 40.169 27.4106 39.8212 27.4106C36.8216 27.4106 35.3 28.8017 33.735 30.3233C32.0396 31.9318 30.3006 33.5403 26.7794 33.5403Z"
      fill="#01243C"
    />
    <path
      d="M52.914 37.9758C49.3927 37.9758 47.6538 36.3238 45.9583 34.7588C44.3498 33.2807 42.8717 31.8461 39.8721 31.8461C36.8725 31.8461 35.351 33.2373 33.7859 34.7588C32.0905 36.3238 30.3516 37.9758 26.8303 37.9758C26.4825 37.9758 26.2217 37.715 26.2217 37.3672C26.2217 37.0194 26.4825 36.7586 26.8303 36.7586C29.8299 36.7586 31.3515 35.3674 32.9165 33.8459C34.6119 32.2809 36.3508 30.6289 39.8721 30.6289C43.3934 30.6289 45.1323 32.2809 46.8278 33.8459C48.4363 35.324 49.9143 36.7586 52.914 36.7586C53.2617 36.7586 53.5226 37.0194 53.5226 37.3672C53.5226 37.715 53.2617 37.9758 52.914 37.9758Z"
      fill="#01243C"
    />
    <path
      d="M26.7794 42.3625C23.2581 42.3625 21.5191 40.7106 19.8237 39.1455C18.2152 37.6675 16.7371 36.2329 13.7375 36.2329C13.3897 36.2329 13.1289 35.972 13.1289 35.6242C13.1289 35.2765 13.3897 35.0156 13.7375 35.0156C17.2588 35.0156 18.9977 36.6676 20.6932 38.2326C22.3017 39.7107 23.7797 41.1453 26.7794 41.1453C29.779 41.1453 31.3005 39.7542 32.8655 38.2326C34.561 36.6676 36.2999 35.0156 39.8212 35.0156C40.169 35.0156 40.4298 35.2765 40.4298 35.6242C40.4298 35.972 40.169 36.2329 39.8212 36.2329C36.8216 36.2329 35.3 37.624 33.735 39.1455C32.0396 40.7106 30.3006 42.3625 26.7794 42.3625Z"
      fill="#01243C"
    />
  </Icon>
);
