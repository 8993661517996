import React, { useEffect, useState } from "react";
import { Navbar } from "../../components/SitePage/navbar";
import { HeroSection } from "../../components/SitePage/hero";
import { FeaturesSection } from "../../components/SitePage/features";
import { AnalyticsSection } from "../../components/SitePage/analytics";
import { RecordingAreaSection } from "../../components/SitePage/recording-area";
import { NearbySpotsSection } from "../../components/SitePage/nearby-spots";
import { ForecastsSection } from "../../components/SitePage/forecasts";
import { SpotMetricsSection } from "../../components/SitePage/spot-metrics";
import { AboutSection } from "../../components/SitePage/about";
import { FooterSection } from "../../components/SitePage/footer";
import "../../_theme/globals.scss";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { endpoints } from "../../Api/Api";
import { buildQuery } from "../../components/SitePage/tab/utils";
import ThemeProvider from "../../_theme/provider";


const SitePage = () => {
  const location = useLocation();
  const { stream } = useParams();
  const [spot, setSpot] = useState({});
  const [nearbySpots, setNearbySpots] = useState([]);

  useEffect(() => {
    const getAllSpots = async () => {
      try {
        console.debug("location: ", stream);
        const response = await axios.get(`${endpoints.GET_SPOTS}/${stream}`);
        const result = response.data;
        if (result.status !== "success") {
          //TODO: Error handling (error from backend
          return;
        }

        setSpot(result.data);
        console.debug("spots:", result.data);
        return result.data;
      } catch (error) {
        console.error("[SitePage::getAllSpots]:", error);
      }
    };

    async function getNeabySpots(spot) {
      try {
        const query = buildQuery("slugs", spot.nearbySpots ?? []);
        const response = await axios.get(`${endpoints.GET_SPOTS}?${query}`);
        const result = response.data;
        if (result.status !== "success") {
          //TODO: Error handling (error from backend)
          return;
        }

        console.debug("query:", query, "nearbySpots:", result);

        const closest = result.data.map((entry, idx) => ({
          name: entry.name,
          rating: entry.analytics["30min"].conditionRating,
          isTopSpot: idx == 0,
          waveHeight: entry.forcasts.today.surf,
          windDirection: entry.forcasts.today.windDirection,
          windSpeed: entry.forcasts.today.windSpeed,
          imageUrl: entry.spotImage,
        }));

        setNearbySpots(closest);
      } catch (error) {
        console.error("[SitePage::getNearbySpots]:", error);
      }
    }

    getAllSpots().then(getNeabySpots);
    window.scrollTo(0, 0);
  }, [stream]);

  console.log("spot:", spot);
  console.log("nearbySpots:", nearbySpots);

  return (
    <ThemeProvider>
      <Navbar spot={spot} />
      <HeroSection spot={spot} />
      <FeaturesSection spot={spot} />
      {/* <AnalyticsSection spot={spot} /> */}
      <RecordingAreaSection spot={spot} />
      {/* <NearbySpotsSection spots={nearbySpots} /> */}
      {/* <ForecastsSection spot={spot} /> */}
      <SpotMetricsSection spot={spot} />
      <AboutSection spot={spot} />
      <FooterSection />
    </ThemeProvider>
  );
};
export default SitePage;