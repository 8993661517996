import dayjs from "dayjs";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import LazyLoad from "react-lazy-load";
import { WaveInfo } from "./Watch2";
import styles from "./WaveList.module.css";
import { InView, useInView } from 'react-intersection-observer';
import { useTrack } from "../../../services/track";
import { RotatingLines } from "react-loader-spinner";
import { FullPreview } from "../../Modal/fullPreview";


import timeTable from "../../../assets/LandingNew/timeTable.svg";


import { MdKeyboardArrowRight } from "react-icons/md";

const WatchItem = ({ img, id, onChange, site, date, selected }) => {
  const timestamp = dayjs(date).format('HH:mm')
  return (
    <div
      // className="watch__items-item"
      className={`${styles["WatchItem-item"]} ${selected && styles.selected}`}
      onClick={onChange}
    >
      <LazyLoad height={160} >
        <img src={`/${img}`} alt={`wave-${timestamp}`} />
      </LazyLoad>
      <div
        // className="watch__items-item-checkbox"
        className={styles["WatchItem-item-checkbox-container"]}
      >
        <caption>{timestamp}</caption>
        <input onChange={onChange} type="checkbox" id={id} value={site} checked={selected} />

      </div>
    </div>
  );
};

const WatchVideo = ({ id, onChange, date, selected, video, site, videoLength }) => {
  const [fullPreview, setFullPreview] = useState(false)
  const [targetVideoSrc, setTargetVideoSrc] = useState({})
  const handleClick = (bool: boolean) => {
    setFullPreview(bool)
  }
  const timestamp = dayjs(date).format('HH:mm')
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const handleVideoLoaded = () => {
    setIsVideoLoaded(true);
  };
  const videoRef = useRef();
  const { ref, inView, entry } = useInView({
    threshold: 0.2,
  });
  return (<><div ref={ref}
    onContextMenu={(e) => {
      e.preventDefault();
      return false;
    }}

    className={`${styles["WatchItem-item"]} ${selected && styles.selected}`}
    onClick={onChange}>
    <div className={`${styles.videoCard}`}>
      {inView ?
        (
          <>
            <div className={`${styles.videoPosterBox}`}>
              {!isVideoLoaded && (
                <div style={{ position: 'relative', width: '100%' }}>

                  <img className={`${styles.posterImg}`}
                    src={'/videoLoading.png'}
                    alt="Video Loading..."
                  />
                  <div
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      zIndex: 1,
                    }}
                  >
                    <RotatingLines
                      strokeColor="white"
                      strokeWidth="5"
                      animationDuration="0.75"
                      width="26"
                      visible={true}
                    />
                  </div>
                </div>
              )}
              <video
                className={`${styles.videoBox}`}
                // controls preload="none"
                // controlsList="play timeline nodownload nofullscreen "
                // disablePictureInPicture
                playsInline
                loop
                width="100%"
                height="100%"
                style={{
                  display: isVideoLoaded ? 'block' : 'none'
                }}
                ref={videoRef}
                autoPlay
                src={video}
                onLoadedData={handleVideoLoaded}
              ></video>
            </div>
          </>
        )
        :
        (
          <div className={`${styles.dummyPoster}`} style={{
            height: '259px',
            backgroundColor: 'black '
          }} />
        )
      }
      <div
        // className="watch__items-item-checkbox"
        className={styles["WatchItem-item-checkbox-container"]}
      >
        <caption>{timestamp}</caption>
        <div className={styles["timeBox"]}>
          <img src={timeTable} alt="time" />
          <span>00:{videoLength < 10 ? '0'+videoLength: videoLength}</span>
        </div>
        <div className={styles["checkBox"]}>
          <input onChange={onChange} type="checkbox" id={id} value={site} checked={selected} />
          <span className={styles.checkmark}></span>
        </div>
      </div>

      <div className={styles["fullPreview"]} onClick={(e) => {
        e.stopPropagation()
        setTimeout(() => {
          setFullPreview(true)
        }, 100)
        setTargetVideoSrc({id,video})
      }}>
        <button>full preview <MdKeyboardArrowRight /></button>
      </div>
      
    </div>
  </div>
  {fullPreview ? <FullPreview handleClick={handleClick} vidSrc={targetVideoSrc} onChange={onChange} selected={selected}/> : <></>}
  </>)

}

type TimeHeaderProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  onVisible?: () => void,
  sRef?: React.MutableRefObject<undefined>
}

const TimeHeader = (props: TimeHeaderProps) => {
  const pAny2 = props as any;
  const { ref, onVisible, ...pAny } = pAny2;
  const { ref: inViewRef, inView } = useInView();

  // Use `useCallback` so we don't recreate the function on each render
  const setRefs = useCallback(
    (node) => {
      // Ref's from useRef needs to have the node assigned to `current`
      if (props.sRef != undefined) {
        props.sRef.current = node;
      }
      // Callback refs, like the one from `useInView`, is a function that takes the node as an argument
      inViewRef(node);
    },
    [inViewRef],
  );
  useEffect(() => {
    if (inView) {
      if (onVisible != undefined) {
        onVisible();
      }
    }
  }, [inView]);

  return <div ref={setRefs} {...pAny}></div>;
}

type Params = {
  waves: Array<WaveInfo>;
  selectedWaves: Array<WaveInfo>;
  sccrollTime?: Date;
  onChange?: (wave: Partial<WaveInfo>) => void;
  onInitialScroll: () => void;
  onTrack?: (info: String) => void;
}

type WaveGroup = {
  startIdx: number,
  endIdx: number,
  startDate: Date,
  endDate: Date
}

type WaveGroupInfo = {
  groups: Array<WaveGroup>,
  summaries: Array<number>,
  selected?: number
}

const groupWaves = (waves: Array<WaveInfo>, selectedTime: Date): WaveGroupInfo => {
  if (waves.length == 0) {
    return {
      groups: [],
      summaries: []
    };
  }
  const retGroups: Array<WaveGroup> = [];
  const retSummaries: Array<number> = [];
  const startDate = dayjs(waves[0].createdAt).minute(0).second(0);
  let currentGroup: WaveGroup = {
    startIdx: 0,
    endIdx: 0,
    startDate: startDate.toDate(),
    endDate: startDate.add(1, 'hour').toDate()
  }
  let selectedIdx = 0;
  for (let i = 1; i < waves.length; i++) {
    if (waves[i].createdAt >= currentGroup.endDate) {
      retGroups.push(currentGroup);
      retSummaries.push(currentGroup.endIdx - currentGroup.startIdx + 1);
      const groupStartDate = dayjs(waves[i].createdAt).minute(0).second(0);
      if (groupStartDate.toDate() < selectedTime) {
        selectedIdx = selectedIdx + 1;
      }
      currentGroup = {
        startIdx: i,
        endIdx: i,
        startDate: groupStartDate.toDate(),
        endDate: groupStartDate.add(1, 'hour').toDate()
      }
    } else {
      currentGroup.endIdx = i;
    }
  }
  retGroups.push(currentGroup);
  retSummaries.push(currentGroup.endIdx - currentGroup.startIdx + 1);
  return {
    groups: retGroups,
    summaries: retSummaries,
    selected: selectedIdx
  };
}

export const WaveList = ({ waves, sccrollTime, selectedWaves, onChange, onInitialScroll }: Params) => {
  const scrollRef = useRef(null);
  const t = useTrack();

  const waveGroups = useMemo(() => {
    return groupWaves(waves, dayjs(sccrollTime).add(1, 'minute').toDate())
  }, [waves]);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView();
    }
  }, [waves]);


  return (
    <div className={styles.WaveContainer} onScroll={() => onInitialScroll()}>
      {waveGroups.groups.map((wg, groupidx) => {
        const wavesIR = [];
        for (let i = wg.startIdx; i <= wg.endIdx; i++) {
          const item = waves[i];
          const selected = selectedWaves.find((sw) => sw.id == item.id) != undefined;
          // selected waves filter from localstorage
          wavesIR.push(
            <WatchVideo
              onChange={() => {
                if (onChange != undefined) {
                  onChange(item);
                }
              }}
              selected={selected}
              video={`/${item?.gif}`}
              id={item?.id}
              site={item?.site}
              date={item?.createdAt}
              videoLength={item?.videoLength}
            />
            // <WatchItem
            //   onChange={() => {
            //     if (onChange != undefined) {
            //       onChange(item);
            //     }
            //   }}
            //   selected={selected}
            //   img={item?.gif}
            //   id={item?.id}
            //   site={item?.site}
            //   date={item?.createdAt}
            // />
          )
        }
        const sRef = groupidx == waveGroups.selected ? scrollRef : undefined;
        return (
          <>
            <TimeHeader ref={sRef} sRef={sRef}
              style={{
                fontSize: '1.5rem',
                fontWeight: '700',
                fontStyle: 'italic',
                backgroundColor: 'white',
                color: '#2E3848',
                margin: '1rem 0 1rem 0',
                borderRadius: '2px',
                padding: '1rem 0 1rem 0',
                textAlign: 'center'

              }} onVisible={() => {
                t({ variables: { info: `[WaveScroll]${dayjs(wg.startDate).format("HH:mm")} - ${dayjs(wg.endDate).format("HH:mm")}` } });
              }}>{dayjs(wg.startDate).format("HH:mm")} - {dayjs(wg.endDate).format("HH:mm")}</TimeHeader>
            <div className={styles.WaveItemGrid}>
              {wavesIR}
            </div>
          </>
        )
      })}
    </div>
  );
  // <div className="watch__items">
  //   {waves?.map((item) => (
  //     <WatchItem
  //       onChange={() => {
  //         if (onChange != undefined) {
  //           onChange(item);
  //         }
  //       }}
  //       img={item?.gif}
  //       id={item?.id}
  //       site={item?.site}
  //       date={item?.createdAt}
  //     />
  //   )
  //   )}

  // </div>)
}