// 'use client';
import React from 'react';
import { VideoPreviewFinder, WaveVideo } from './preview-finder';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { LiveBadge } from '../../../components/SitePage/badge/live';
import { AllWaveVideos, waves } from './mocks/wave-videos';

import './video.scss';
import { nanoid } from '@reduxjs/toolkit';
import Hls from 'hls.js';
import { STREAM_TEST_URL } from '../../../Api/Api';

export const LiveVideos = ({ source }) => {
  const defaultSelectedWaveType = 'topSurfedWaves';
  const [video, setVideo] = useState<WaveVideo>();
  const [allWaves, setWaves] = useState<AllWaveVideos>();
  const [loaded, setLoaded] = useState<boolean>(false);

  const videoPreviewRef = useRef<HTMLDivElement>(null);
  const handleSetVideo = (video: WaveVideo) => {
    setVideo(video);
    videoPreviewRef?.current?.focus();
    videoPreviewRef?.current?.scrollIntoView({
      behavior: 'smooth',
      inline: 'center',
      block: 'nearest',
    });
  };

  useEffect(() => {
    const mimeTypes = {
      'mp4': 'video/mp4',
      'm3u8': 'application/vnd.apple.mpegurl'
    }
    const trailingOf = (link) => link.slice(0, link.lastIndexOf('/'));
    const extOf = (link) => Object.keys(mimeTypes).find(ext => link.endsWith(ext));
    const mimeOf = (link) => mimeTypes[extOf(link)];

    console.log("source: ", source);
    if (!source.latestWaves) {
      return;
    }

    const latestWaves = source.latestWaves.map(wav => ({
      id: nanoid(),
      name: trailingOf(wav),
      url: wav,
      mime: mimeOf(wav)
    }));
    const topSurfedWaves = source.topWaves.map(wav => ({
      id: nanoid(),
      name: trailingOf(wav),
      url: wav,
      mime: mimeOf(wav)
    }));

    setWaves({
      live: {
        id: nanoid(),
        name: trailingOf(source.liveUrl),
        url: (STREAM_TEST_URL as typeof source.liveUrl) ?? source.liveUrl,
        mime: mimeOf(source.liveUrl),
        preview: source.spotImage
      },
      latestWaves,
      topSurfedWaves
    });

    const mime = mimeOf(source.liveUrl);
    setVideo(Object.create({ id: nanoid(), url: source.liveUrl, mime: mime }));

    setLoaded(true);
  }, [source]);

  return loaded && (
    <div className="xl:px-4 pb-7 md:pb-9">
      <VideoPreview
        ref={videoPreviewRef}
        video={video}
        key={video?.id ?? nanoid()}
        isLive={video?.id === allWaves?.live?.id}
      />
      <VideoPreviewFinder
        defaultSelectedWaveType={defaultSelectedWaveType}
        waves={allWaves}
        selectedVideo={video}
        setSelectedVideo={handleSetVideo}
      />
    </div>
  );
};

function playVideo(video?: HTMLVideoElement) {
  if (!video) {
    return;
  }

  try {
    const isPlaying = video.currentTime > 0 && !video.paused && !video.ended
      && video.readyState > video.HAVE_CURRENT_DATA;

    if (!isPlaying) {
      video.play().catch(console.error);
    }
  } catch (error) {
    console.error('Error::[playVideo]', error);
  }
}

type VideoPreviewProps = {
  video: WaveVideo;
  isLive?: boolean;
};

export const VideoPreview = forwardRef<HTMLDivElement, VideoPreviewProps>(
  ({ video, isLive }, ref) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const hls = new Hls();
    const [isNonStreamingSource, setIsNonStreamingSource] = useState<boolean>(false);

    useEffect(() => {
      console.log('VIDEO', video, 'is player supported:', Hls.isSupported());
      if (!video) {
        return;
      }

      try {
        if (video.url.includes('m3u8') && Hls.isSupported()) {
          const vid = document.getElementById('video') as HTMLVideoElement;
          setIsNonStreamingSource(false);
          hls.loadSource(video.url);
          hls.attachMedia(vid);
          hls.on(Hls.Events.MANIFEST_PARSED, function () {
            playVideo(vid);
          });
        } else {
          const vid = document.getElementById('video') as HTMLVideoElement;
          setIsNonStreamingSource(true);
          playVideo(vid);
        }
      } catch (error) {
        console.error('Error::[VideoPreview]', error);
      }
    }, []);

    return (
      <div
        className="max-w-[60rem] mx-auto w-full aspect-[390/221] lg:aspect-[960/442] xl:rounded-3xl bg-brand-primary relative"
        ref={ref}
      >
        <video
          id="video"
          className="w-full h-full object-cover lg:rounded-2xl"
          muted
          loop
          autoPlay
          playsInline
          controlsList="nodownload nofullscreen noremoteplayback"
          disablePictureInPicture
          disableRemotePlayback
        >
          {isNonStreamingSource && (<source src={video?.url ?? ''} type={video?.mime ?? ''} />)}
        </video>
        {isLive ? (
          <LiveBadge className="top-4 left-4 lg:left-14 lg:top-9 animate-pulse" />
        ) : null}
      </div>
    );
  }
);

VideoPreview.displayName = 'VideoPreview';
