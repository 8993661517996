import React from 'react';
import { Icon, type IconProps } from './core';

export const WindIcon = (props: IconProps) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    {...props}
  >
    <circle
      cx="32.0041"
      cy="31.9855"
      r="29.9177"
      stroke="#01243C"
      strokeWidth="4.15526"
    />
    <path
      d="M52.2805 36.9938H23.8215C18.054 36.9938 13.3389 32.2787 13.3389 26.469C13.3389 20.7014 18.054 15.9863 23.8215 15.9863C27.1053 15.9863 30.1364 17.5019 32.1571 20.112C32.536 20.5751 32.4097 21.2487 31.9467 21.5855C31.4836 21.9644 30.81 21.8381 30.4732 21.375C28.8734 19.3122 26.4317 18.0913 23.8215 18.0913C19.2327 18.1334 15.4859 21.8802 15.4859 26.469C15.4859 31.0999 19.2327 34.8467 23.8636 34.8467H52.2805C52.8699 34.8467 53.333 35.3098 53.333 35.8992C53.333 36.4886 52.8699 36.9938 52.2805 36.9938Z"
      fill="#01243C"
    />
    <path
      d="M29.5892 50.8014C26.3476 50.8014 23.6953 48.1492 23.6953 44.9075C23.6953 41.6659 26.3476 39.0137 29.5892 39.0137H49.6283C50.2177 39.0137 50.6808 39.4768 50.6808 40.0661C50.6808 40.6555 50.2177 41.1186 49.6283 41.1186H29.5892C27.5263 41.1186 25.8424 42.8026 25.8424 44.8654C25.8424 46.9283 27.5263 48.6123 29.5892 48.6123C31.652 48.6123 33.336 46.9283 33.336 44.8654C33.336 44.4865 33.2939 44.1077 33.1676 43.7288C32.9992 43.1815 33.2939 42.55 33.8833 42.3816C34.4306 42.2132 35.0621 42.5079 35.2305 43.0973C35.3988 43.6446 35.483 44.234 35.483 44.8654C35.4409 48.1492 32.7887 50.8014 29.5892 50.8014Z"
      fill="#01243C"
    />
    <path
      d="M49.4179 32.8268H36.8724C33.6308 32.8268 30.9785 30.1746 30.9785 26.9329C30.9785 23.6913 33.6308 21.0391 36.8724 21.0391C40.114 21.0391 42.7663 23.6913 42.7663 26.9329C42.7663 27.5223 42.6821 28.1117 42.5137 28.7011C42.3453 29.2484 41.7559 29.5852 41.1665 29.4168C40.6192 29.2484 40.2824 28.659 40.4508 28.0696C40.5771 27.6907 40.6192 27.3539 40.6192 26.9329C40.6192 24.8701 38.9352 23.1861 36.8724 23.1861C34.8095 23.1861 33.1256 24.8701 33.1256 26.9329C33.1256 28.9958 34.8095 30.6797 36.8724 30.6797H49.46C50.0494 30.6797 50.5125 31.1428 50.5125 31.7322C50.5125 32.3216 50.0073 32.8268 49.4179 32.8268Z"
      fill="#01243C"
    />
  </Icon>
);
