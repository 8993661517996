import React from 'react';
import { Icon, type IconProps } from './core';

export const GoogleMapIcon = (props: IconProps) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 22"
    fill="none"
    {...props}
  >
    <path
      d="M12.5121 2.3361C11.8915 2.14032 11.2266 2.0332 10.5396 2.0332C8.5338 2.0332 6.73859 2.9382 5.53809 4.36403L8.62245 6.95711L12.5121 2.3361Z"
      fill="#1A73E8"
    />
    <path
      d="M5.53756 4.36328C4.54869 5.53779 4.00701 7.02414 4.0083 8.5595C4.0083 9.78586 4.2521 10.7832 4.65473 11.6734L8.62193 6.95637L5.53756 4.36328Z"
      fill="#EA4335"
    />
    <path
      d="M10.5394 6.06694C11.0156 6.06738 11.4817 6.20387 11.8829 6.46034C12.284 6.71681 12.6036 7.08257 12.8039 7.51457C13.0041 7.94656 13.0768 8.42678 13.0133 8.89869C12.9498 9.37059 12.7528 9.81452 12.4454 10.1782L16.3314 5.55719C15.5337 4.02045 14.1586 2.86324 12.5082 2.33984L8.62598 6.96086C8.8599 6.68072 9.15255 6.45544 9.4832 6.30096C9.81386 6.14648 10.1744 6.06659 10.5394 6.06694Z"
      fill="#4285F4"
    />
    <path
      d="M10.5391 11.0611C9.16129 11.0611 8.04205 9.94188 8.04205 8.56407C8.04043 7.97741 8.24728 7.40925 8.62568 6.96094L4.65479 11.678C5.33445 13.1814 6.46108 14.3893 7.62095 15.9111L12.4451 10.1783C12.2107 10.4552 11.9188 10.6777 11.5896 10.8301C11.2604 10.9826 10.9019 11.0614 10.5391 11.0611Z"
      fill="#FBBC04"
    />
    <path
      d="M12.3485 17.4726C14.5278 14.0669 17.0655 12.5191 17.0655 8.55932C17.0655 7.47333 16.7996 6.45013 16.3304 5.54883L7.62402 15.9101C7.99341 16.394 8.36649 16.9074 8.72849 17.4763C10.0509 19.5227 9.68519 20.749 10.5385 20.749C11.3918 20.749 11.0261 19.519 12.3485 17.4726Z"
      fill="#34A853"
    />
  </Icon>
);
