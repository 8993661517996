type Key = KeyboardEvent['key'];

export const handleTabNavigation = (key: Key, tabs: HTMLElement[]) => {
  const selectedTabIndex = tabs.indexOf(document.activeElement as HTMLElement);
  switch (key) {
    case 'ArrowUp':
    case 'ArrowLeft': {
      const previousTab = (selectedTabIndex - 1 + tabs.length) % tabs.length;
      tabs[previousTab]?.focus();

      break;
    }
    case 'ArrowDown':
    case 'ArrowRight': {
      const nextTab = (selectedTabIndex + 1 + tabs.length) % tabs.length;
      tabs[nextTab]?.focus();

      break;
    }
  }
};

export const weightOf = (text): number => {
  return +Number(parseFloat(String(text).match(/-?\d+(?:\.\d+)?/)[0])).toFixed(2);
}

export const valueOf = (text: string): string => {
  return String(text).match(/[-\d]+/)[0];
}

export const unitOf = (text) => {
  return text.match(/[a-zA-Z]+/)[0];
}

export const buildQuery = (key: string, listings: []) => {
  return listings.reduce((acc, cur, idx) => acc + key +
    '=' + cur + (idx === listings.length - 1 ? '' : '&'), '');
}

export const fmt = (rawData: string) => {
  const date = new Date(rawData);
  const h = date.getHours();
  const m = String(date.getMinutes()).padStart(2, '0');
  const d = date.getDate();
  const y = date.getFullYear();
  const formatted = `${h}:${m} ${date.toLocaleString('default', { month: 'long' })} ${d}, ${y}`;

  return formatted;
} 