import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { googleLogout } from "@react-oauth/google";
import { removeDate } from "../Redux/FilterSlice";
import { removeData } from "../Redux/ShowFilterData";
import { useWaveContext } from "../context/CartContext";
import { useActor, useMachine } from "@xstate/react";
import axios from "axios";
import { endpoints } from "../Api/Api";

export const useWavesLogout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleCustomerLogout = () => {
    dispatch(removeDate(undefined));
    dispatch(removeData(undefined));
    localStorage.removeItem("userInfo");
    localStorage.removeItem("token");
    googleLogout();
    navigate("/");
  };

  return handleCustomerLogout;
}

const logoutHandler = () => {
  // update user last active
  const getToken = localStorage.getItem("token");
  const getUserInfo = localStorage.getItem("userInfo");
  if (getToken !== null && getUserInfo !== null) {
    axios
      .get(`${endpoints.UPDATELASTACTIVE}`,
        {
          headers: {
            Authorization: `Bearer ${getToken}`,
          },
        }
      )
      .then((response) => {})
      .catch((error) => {console.log(error)});
        
  } 
}

export const useforLogoutBtnClicked = () => {
  const { waveService } = useWaveContext();
  const navigate = useNavigate();
  const btnLoggedOut = () => {
    logoutHandler()
    // when user logged out successfully then reset cart as well as localstorage waves data
    localStorage.removeItem("userInfo");
    localStorage.removeItem("token");
    localStorage.removeItem("waves")
    localStorage.removeItem("date")
    localStorage.removeItem("location")
    googleLogout();
    waveService.send({
      type: 'reset',
    })
    navigate("/");
  };

  return btnLoggedOut;
}